
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { IResponse } from '../../../interfaces';
import { ConfigService } from '../utility/config.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ChatMessageService {

  // private placesConfig: any = this.configService.get('places_api');
  // private client: any = google.createClient({ key: this.placesConfig.KEY });

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  public get(messageId: number): Observable<IResponse> {

    return this.http
      .get(`${ this.configService.getApiUrl() }/messages/${ messageId }`)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve data.'))
      );
  }

  /**
   * getPreview
   */
  public getAll(pageToken?: number, recordCount?: number, func?: string): Observable<IResponse> {

    let filters = '';

    // if (tag > 0) {
    //   filters = `?t=${tag}`;
    // }

    // if (pageToken > 0) {
    //   filters += filters.length === 0 ? '?' : '&';
    //   filters += `p=${pageToken}`;
    // }

    filters += pageToken ? `p=${ pageToken }&` : '';
    filters += recordCount ? `c=${ recordCount }&` : '';
    filters += func ? `f=${ func }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/messages?${ filters }`)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve data.'))
      );
  }

  public deleteMessages(data: Array<number>): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/messages/deleteSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to delete the messages.'))
      );
  }

  public toggle(data: Array<number>): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/messages/toggleSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to toggle status of the messages.'))
      );
  }

}
