// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { config } from '../config/config';
import { version } from './version';

const cfg: any = config;

(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({
    'gtm.start': new Date().getTime(), event: 'gtm.js'
  });
  const f = d.getElementsByTagName(s)[0];
  const j = <any>d.createElement(s);
  const dl = l !== 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + '&gtm_auth=' + cfg.tracking.token + '&gtm_preview=' + cfg.tracking.environmentId + '&gtm_cookies_win=x';
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-MJZKKNM');

(function (window, conf, ver) {
  const global: any = window;
  global.__env = { ...conf, version: ver };
}(window, config, version));

export class Environment {

  public static get config(): any {
    return config;
  }

  public static get production(): boolean {
    return Environment.config.production;
  }
  public static get staging(): boolean {
    return Environment.config.staging;
  }
}