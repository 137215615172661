import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { JwtOptionsFactory } from './features/auth';

import { SharedModule, ConfigService, TranslationCacheService, StorageService, HttpLoaderFactory, UtilityService } from './shared';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookieService } from 'ng2-cookies';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: JwtOptionsFactory,
        deps: [
          StorageService,
          CookieService,
          // UtilityService,
        ],
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, ConfigService, TranslationCacheService],
      },
    }),
    HttpClientModule,
    SharedModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,   // Should be last module in the list.
  ],
  providers: [
    {
      provide: 'Window',
      useValue: window,
    },
  ],
  bootstrap: [
    AppComponent,
  ],
})

export class AppModule { }
