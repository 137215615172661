import { DataServices } from './data';
import { FunctionalServices } from './functional';
import { UtilityServices } from './utility';
import { CacheServices } from './cache';
import { OverlayDialogService } from './overlay-dialog';

export * from './interfaces';
export * from './data';
export * from './functional';
export * from './utility';
export * from './cache';
export * from './overlay-dialog';

export const Services = [
  ...DataServices,
  ...FunctionalServices,
  ...UtilityServices,
  ...CacheServices,
  OverlayDialogService,
];
