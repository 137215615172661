import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthService } from './services';

import { AuthComponent } from './auth.component';
import { LoginComponent } from './login';
import { LogoutComponent } from './logout';
import { ForgotComponent } from './forgot';

@NgModule({
  imports: [
    SharedModule,
    AuthRoutingModule,
  ],
  providers: [
    AuthService,
  ],
  exports: [
  ],
  declarations: [
    AuthComponent,
    LoginComponent,
    LogoutComponent,
    ForgotComponent,
  ],
})

export class AuthModule { }
