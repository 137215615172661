import { ErrorHandler } from '@angular/core';

import { ConfigService } from './config.service';
import { WindowService } from './window.service';
import { ClientService } from './client.service';
import { LogService } from './log.service';
import { ErrorHandlerService } from './error-handler.service';

export * from './config.service';
export * from './window.service';
export * from './client.service';
export * from './log.service';
export * from './error-handler.service';

export const UtilityServices = [
  // ConfigService,
  // WindowService,
  // ClientService,
  // LogService,
  {
    provide: ErrorHandler,
    useClass: ErrorHandlerService,
  },
];
