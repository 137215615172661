import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { first } from 'rxjs/operators';

import { IThumbsOptions } from './interfaces';
import { FileService } from '../../../dependencies';
import { IThumb } from '../../../interfaces';

@Component({
  selector: 'app-thumb-preview, [appThumbPreview]',
  templateUrl: './thumb-preview.component.html',
  styleUrls: ['./thumb-preview.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class ThumbPreviewComponent implements OnInit {

  @Input() thumbs: Array<IThumb>;
  @Input() options: IThumbsOptions;
  @Input() editable: boolean;

  constructor(
    private fileService: FileService,
  ) { }

  ngOnInit() {
    this.options = {
      ...{
        class: 'thumb-preview-container',
        thumb: {
          width: '75',
          height: '90',
          class: 'thumb',
        },
      },
      ...this.options,
    };
  }

  delete(item: IThumb): void {
    const self = this;
    this.fileService
      .delete(item.id)
      .pipe(first())
      .subscribe((res) => {
        if (res.status.status === 'STA_SUCCESS') {
          const idx = self.thumbs.indexOf(item);
          if (idx > -1) {
            self.thumbs.splice(idx, 1);
          }
        }
      });
  }

  getThumbIcon(thumb: IThumb): string {

    if (thumb.type.startsWith('image')) {
      return thumb.url;
    } else if (thumb.type.endsWith('pdf')) {
      return '/assets/img/pdf-icon.png';
    } else {
      return 'assets/img/doc-icon.png';
    }
  }

}
