import { Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { CachingInterceptor } from './caching.interceptor';
import { DecryptionInterceptor } from './decryption.interceptor';
import { AuthInterceptor } from './auth.interceptor';

export const Interceptors: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: DecryptionInterceptor, multi: true },
];
