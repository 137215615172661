import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, UrlSegment, Router, ActivatedRoute } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { first } from 'rxjs/operators';

import { RouterService, UserService } from '../services';

/**
 * This class implements a guard for routes that require successful authentication.
 */
@Injectable({
  providedIn: 'root',
})
export class RolesGuard implements CanActivate, CanLoad {
  /**
   * Constructor of the class.
   *
   * @param {UserService} userService
   * @param {Router}      router
   */
  constructor(
    private userService: UserService,
    private routerService: RouterService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  /**
   * Purpose of this guard is check that current user has been authenticated to application. If user is not
   * authenticated he/she is redirected to login page.
   *
   * @param {ActivatedRouteSnapshot}  route
   * @param {RouterStateSnapshot}     state
   * @returns {Observable<boolean>}
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.processRoute(route, state);
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.userService.verifyRole(route.data.allowedRoles);
  }

  private processRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // return new Observable((observer: Observer<boolean>) => {

    if (route.data.redirects) {
      const redirects = route.data.redirects;
      const path = state.url; // `${ this.router.url }/${ route.routeConfig.path }`;
      let redirected = false;
      redirects.forEach(redirect => {
        if (this.userService.verifyRole(redirect.roles)
          && (
            redirect.pathMatch === 'full' && redirect.path === path
            || redirect.pathMatch === 'pattern' && path.match(redirect.path)
          )
        ) {
          redirected = true;
          return this.router.navigate([redirect.targetAction === 'replace' ? path.replace(redirect.path, redirect.target) : redirect.target]);
        }
      });
      if (redirected) { return };
    }

    if (this.userService.verifyRole(route.data.allowedRoles)) {
      // observer.next(true);
      // observer.complete();
      // } else if (route.data.skip) {
      //   observer.next(true);
      //   observer.complete();
      return true;
    }

    this.routerService.navigate(); // .pipe(first()).subscribe((result) => {
    return true;
    // observer.next(true);
    // observer.complete();
    // });

    // });
  }
}
