export * from './empty';
export * from './input-container';
export * from './image-editor';
export * from './article-preview';
export * from './thumb-preview';
export * from './post';
export * from './add-post';
export * from './comment';
export * from './user-tags'
export * from './filters';
export * from './opportunity';
export * from './add-opportunity';
export * from './sociate';
export * from './chat';
export * from './divider';
export * from './scroll-top';
export * from './read-more';
export * from './confirm';
export * from './placeholder';
export * from './splashes';

import { EmptyComponent } from './empty';
import { InputContainerComponent } from './input-container';
import { ImageEditorComponent } from './image-editor';
import { ArticlePreviewComponent } from './article-preview';
import { ThumbPreviewComponent } from './thumb-preview';
import { PostComponent, ViewLikesComponent } from './post';
import { AddPostComponent } from './add-post';
import { CommentComponent } from './comment';
import { UserTagsComponent } from './user-tags';
import { FiltersComponent } from './filters';
import { OpportunityComponent } from './opportunity';
import { AddOpportunityComponent } from './add-opportunity';
import { SociateComponent } from './sociate';
import { ChatComponent } from './chat';
import { DividerComponent } from './divider';
import { ScrollTopComponent } from './scroll-top';
import { ReadMoreComponent } from './read-more';
import { ConfirmComponent } from './confirm';
import { PlaceholderComponent } from './placeholder';
import { SplashHiringComponent, SplashNewOpportunityComponent, SplashOpenToWorkComponent } from './splashes';

export const Components = [
  EmptyComponent,
  InputContainerComponent,
  ImageEditorComponent,
  ArticlePreviewComponent,
  ThumbPreviewComponent,
  PostComponent,
  AddPostComponent,
  CommentComponent,
  UserTagsComponent,
  FiltersComponent,
  OpportunityComponent,
  AddOpportunityComponent,
  SociateComponent,
  ChatComponent,
  DividerComponent,
  ScrollTopComponent,
  ReadMoreComponent,
  ConfirmComponent,
  PlaceholderComponent,
  ViewLikesComponent,
  SplashHiringComponent,
  SplashNewOpportunityComponent,
  SplashOpenToWorkComponent,
]
