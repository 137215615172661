<div class="container" [class.simulating]="isSimulating">
  <mat-toolbar color="primary" class="primary">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFill>
      <!-- <button mat-icon-button (click)="toggleSidenav()">
          <mat-icon>more_vert</mat-icon>
        </button> -->

      <div class="logo-container" fxLayout="row" fxLayoutAlign="space-around center" [routerLink]="['/home']">
        <span class="logo" text-center>
          <img src="assets/img/logo.png">
        </span>
        <span class="title">Sociate</span>
      </div>
      <div *ngIf="user" class="top-menu" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="search-container" [formGroup]="searchForm" novalidate *showItDevice="['desktop','tablet']">
          <!-- <input id="txtSearch" placeholder=" Search posts or Sociates..." value="" onkeydown="if (event.keyCode == 13) { window.open('/search-results?p=' + txtSearch.value,'_self'); return false; }"> -->
          <input #searchControl name="search"
            placeholder="{{ (isRecruiter ? 'PLACEHOLDER_SEARCH_SOCIATES' : 'PLACEHOLDER_SEARCH_ALL') | translate }}"
            type="text" matInput [matAutocomplete]="autoResults" formControlName="searchControl"
            [(ngModel)]="searchText" />
          <mat-autocomplete #autoResults="matAutocomplete" [displayWith]="setToNull">
            <div class="search-autocomplete">
              <mat-option *ngFor="let result of filteredResults | async" [routerLink]="getResultLink(result)"
                [queryParams]="{p: result.userId || result.postId || result.opportunityId || result.id}">
                <mat-card>
                  <mat-card-header>
                    <div mat-card-avatar class="header-image">
                      <img appDefault mat-card-avatar src="{{(result.author || result).picture}}">
                    </div>
                    <mat-card-title>
                      <span class="name router-link underline-hover">{{(result.author || result).displayName}}</span>
                      <span *ngIf="(result.author || result).schools" class="university">{{(result.author ||
                        result).schools[0]}}</span>
                      <span *ngIf="(result.author || result).graduating" class="year">'{{(result.author ||
                        result).graduating.toString().substring(2)}}</span>
                      <span *ngIf="!(result.author || result).graduating && (result.author || result).enrollment"
                        class="year">'{{(result.author || result).enrollment.toString().substring(2)}}</span>
                    </mat-card-title>
                    <mat-card-subtitle *ngIf="(result.author || result).profession">
                      <span class="work">{{ (result.author || result).profession.title }} @ {{ (result.author ||
                        result).profession.company }}</span>
                    </mat-card-subtitle>
                    <mat-card-subtitle *ngIf="result.created">
                      <span class="work">{{ getDuration(result.created) }}</span>
                    </mat-card-subtitle>
                  </mat-card-header>
                  <mat-card-content *ngIf="result.content || result.title || result.message">
                    <div fxLayout="column" fxFlex class="post-container">
                      <p>
                        <span class="content" *ngIf="(result.content || result.title || result.message).length > 50"
                          [innerHTML]="(result.content || result.title || result.message).substring(0,47)"></span>
                        <span class="content" *ngIf="(result.content || result.title || result.message).length <= 50"
                          [innerHTML]="result.content || result.title || result.message"></span>
                        <span class="more-less-button"
                          *ngIf="(result.content || result.title || result.message).length > 50">... see more</span>
                      </p>
                    </div>
                  </mat-card-content>
                </mat-card>
              </mat-option>
            </div>
          </mat-autocomplete>
        </span>
        <!-- <span class="spacer"></span> -->
        <span class="user-menu" fxLayout="row" fxLayoutAlign="space-between center" text-center>
          <div class="toolbarMenu">
            <a [routerLink]="['/hub']" [class.active]="isActive('hub')">
              <span fxLayout="row" fxLayoutAlign="center">
                <!-- <i class="material-icons md-24">home</i> -->
                <mat-icon>home</mat-icon>
              </span>
              <span fxLayout="row" fxLayoutAlign="center">Home</span>
            </a>
          </div>
          <div *ngIf="!isSimulating && !isReadOnly" class="toolbarMenu">
            <a [routerLink]="['/messages']" [class.active]="isActive('messages')">
              <span fxLayout="row" fxLayoutAlign="center">
                <!-- <i class="material-icons md-24" matBadge="unread" matBadgeColor="warn">message</i> -->
                <mat-icon [matBadge]="getMessageCount()" matBadgeColor="warn" matBadgeSize="medium"
                  [matBadgeHidden]="!unread || unread === 0">message</mat-icon>
              </span>
              <span fxLayout="row" fxLayoutAlign="center"> Message</span>
            </a>
          </div>
          <div class="toolbarMenu" *ngIf="isAdmin">
            <a [routerLink]="['/admin']" [class.active]="isActive('admin')">
              <span fxLayout="row" fxLayoutAlign="center">
                <!-- <i class="material-icons md-24">settings</i> -->
                <mat-icon>settings</mat-icon>
              </span>
              <span fxLayout="row" fxLayoutAlign="center"> Admin</span>
            </a>
          </div>
          <!-- <div class="toolbarMenu">
            <a [routerLink]="['/notifications']">
              <div fxLayout="row" fxLayoutAlign="center">
                <i class="material-icons md-24">notifications</i>
              </div>
              <div fxLayout="row" fxLayoutAlign="center"> Notifications</div>
            </a>
          </div>

          <div class="toolbarMenu">
            <a [routerLink]="['/referrals']">
              <div fxLayout="row" fxLayoutAlign="center">
                <i class="material-icons md-24">person_add</i>
              </div>
              <div fxLayout="row" fxLayoutAlign="center">Referral</div>
            </a>
          </div> -->
          <div class="toolbarMenuProfile">
            <div class="user-icon" fxLayout="row" fxLayoutAlign="center" matBadge="!" matBadgeColor="warn"
              matBadgeSize="medium" [matBadgeHidden]="!isSociate || user.resume">
              <img [matMenuTriggerFor]="menu" mat-card-avatar src="{{user.picture}}">
            </div>
          </div>
        </span>
        <!-- <span class="buttons" fxLayout="row" fxLayoutAlign="start center"> -->
        <!-- <a
                mat-button
                *ngFor="let locale of locales"
                (click)="changeLocale(locale)"
              >{{ locale.nameShort }}</a> -->

      </div>
    </div>
  </mat-toolbar>

  <div>
    <mat-menu #menu="matMenu" [overlapTrigger]="false" xPosition="before" class="profile-menu">
      <mat-toolbar>
        <mat-toolbar-row class="profile-view-menu" fxLayout="column" fxLayoutAlign="center center">
          <img appDefault mat-card-avatar [routerLink]="['/my']" src="{{user.picture}}">
          <span class="name router-link underline-hover" [routerLink]="['/my']">{{ user.firstname }} {{
            user.lastname }}</span>
          <span class="school">
            <span *ngIf="user.schools" class="university">{{ user.schools[0] }}</span>
            <span *ngIf="user.graduating" class="year">'{{user.graduating.toString().substring(2)}}</span>
            <span *ngIf="!user.graduating && user.enrollment"
              class="year">'{{user.enrollment.toString().substring(2)}}</span>
          </span>
          <span *ngIf="user.profession" class="work">{{ user.profession.title }} @
            {{ user.profession.company }}</span>
        </mat-toolbar-row>
        <mat-toolbar-row class="account-menu" fxLayout="column" fxLayoutAlign="center center">
          <mat-list>
            <mat-divider></mat-divider>
            <mat-list-item [routerLink]="['/my']"><span class="user-profile" matBadge="!" matBadgeColor="warn"
                matBadgeSize="medium" [matBadgeHidden]="!isSociate || user.completeLaterStatus || user.resume">View
                Profile</span></mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item *ngIf="!isSimulating" [routerLink]="['/auth/logout']">
              {{ 'HEADER_BUTTON_LOGOUT' | translate }}</mat-list-item>
            <mat-list-item *ngIf="isSimulating" (click)="dismiss()">End Simulation</mat-list-item>
          </mat-list>
        </mat-toolbar-row>
      </mat-toolbar>
    </mat-menu>
  </div>

  <mat-toolbar *ngIf="isSimulating" color="warn" class="secondary">
    <div class="simulation-container" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="message-panel">
        Simulating User: {{ userService.displayName }} | {{ userService.profile.email }} | {{ userService.username }}
      </span>
      <div class="action-panel">
        <button mat-raised-button color="accent" [disabled]="loading" (click)="dismiss()">{{ 'BUTTON_END_SIMULATION'
          | translate }}</button>
      </div>
    </div>
  </mat-toolbar>


</div>