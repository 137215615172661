import { Injectable, Injector, ComponentRef, InjectionToken } from '@angular/core';
import { ComponentPortal, PortalInjector, ComponentType } from '@angular/cdk/portal';
import { Overlay, OverlayRef, OverlayConfig, ConnectedPosition } from '@angular/cdk/overlay';
import { first } from 'rxjs/operators';

import { OverlayDialogConfig } from './interfaces';
import { OverlayDialogRef } from './overlay-dialog-ref';

const DEFAULT_CONFIG: OverlayConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel',
};

export const DIALOG_DATA = new InjectionToken<any>('DIALOG_DATA');

@Injectable()
export class OverlayDialogService {

  constructor(
    private injector: Injector,
    private overlay: Overlay,
  ) { }

  public open<T>(component: ComponentType<T>, dialogConfig: OverlayDialogConfig = {}): OverlayDialogRef {
    const overlayConfig: OverlayConfig = this.getOverlayConfig(dialogConfig);

    // Returns an OverlayRef which is a PortalHost
    const overlayRef: OverlayRef = this.overlay.create(overlayConfig);

    // Instantiate remote control
    const dialogRef: OverlayDialogRef = new OverlayDialogRef(overlayRef);

    const overlayComponent: T = this.attachDialogContainer<T>(component, overlayRef, dialogConfig, dialogRef);
    overlayRef.overlayElement.onmouseenter = (_ => dialogRef.setMouseOver(true));
    overlayRef.overlayElement.onmouseleave = (_ => dialogRef.close(true));
    overlayRef.backdropClick().pipe(first()).subscribe(_ => dialogRef.close(true));

    return dialogRef;
  }

  // private createDialog(config: OverlayDialogConfig) {
  //   const dialogConfig = this.getDialogConfig(config);
  //   return this.overlay.create(dialogConfig);
  // }

  private getOverlayConfig(dialogConfig: OverlayDialogConfig): OverlayConfig {

    // Override default configuration
    return new OverlayConfig({
      ...DEFAULT_CONFIG,
      // positionStrategy: this.overlay.position()
      //   .global()
      //   .centerHorizontally()
      //   .centerVertically(),
      positionStrategy: dialogConfig.parent ?
        this.overlay.position()
          .flexibleConnectedTo(
            dialogConfig.parent,
          )
          .withPositions([
            // here, top-left of the overlay is connected to bottom-left of the origin; 
            // of course, you can change this object or generate it dynamically;
            // moreover, you can specify multiple objects in this array for CDK to find the most suitable option
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
              offsetY: -10,
            } as ConnectedPosition,
            {
              originX: 'start',
              originY: 'top',
              overlayX: 'start',
              overlayY: 'bottom',
              // offsetY: -10,
            } as ConnectedPosition,
          ])
          .withPush(true) // or true, if you want to push the overlay into the screen when it doesn't fit
        :
        this.overlay.position()
          .global()
          .centerHorizontally()
          .centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
      ...dialogConfig.config,
    });
  }

  private attachDialogContainer<T>(component: ComponentType<T>, overlayRef: OverlayRef, config: OverlayDialogConfig, dialogRef: OverlayDialogRef) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal: ComponentPortal<T> = new ComponentPortal<T>(component, null, injector);
    const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(config: OverlayDialogConfig, dialogRef: OverlayDialogRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(OverlayDialogRef, dialogRef);
    injectionTokens.set(DIALOG_DATA, config.data);

    return new PortalInjector(this.injector, injectionTokens);
  }

}
