import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PostService } from '../../../../dependencies';
import { ILike } from '../../../../interfaces';

@Component({
  selector: 'app-view-likes',
  templateUrl: './view-likes.component.html',
  styleUrls: ['./view-likes.component.scss']
})
export class ViewLikesComponent implements OnInit {
  postId: number;
  likes: ILike[];
  count: number;
  pageToken: number;
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<ViewLikesComponent>,
    @Inject(MAT_DIALOG_DATA) data: { postId: number, likes: ILike[], count: number },
    private postService: PostService,
  ) {
    if (data) {
      this.likes = data.likes;
      this.postId = data.postId;
      this.count = data.count;
    }
    this.pageToken = 0;
  }

  ngOnInit() {
    if (this.postId) {
      this.getLikes(this.postId);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getLikes(postId?: number): void {
    const self = this;

    this.postService
      .getLikes(postId || this.postId)
      .subscribe(
        resp => {
          if (resp?.status.status === 'STA_SUCCESS') {
            const likes = resp.data.likes;
            self.likes = (self.pageToken === 0) ? likes : self.likes.concat(resp.data.likes);
            self.pageToken = resp.data.pageToken || 0;
          }
        }
      );
  }

}
