import { Observable } from 'rxjs';
import { User } from './user';
import { EventEmitter } from '@angular/core';

import { IDataItems } from '../../../../dependencies';
import { Message, ConnectionStatus, UserStatus } from './interfaces';

export abstract class ChatAdapter {
    // ### Abstract adapter methods ###

    friendsListChangedHandler: EventEmitter<User[]> = new EventEmitter();
    messageReceivedHandler: EventEmitter<{ sender: User, message: Message }> = new EventEmitter();
    cmdResultHandler: EventEmitter<any> = new EventEmitter();
    notificationHandler: EventEmitter<string> = new EventEmitter();
    connectionStatusChangeHandler: EventEmitter<ConnectionStatus> = new EventEmitter();

    public abstract floating: boolean;

    public abstract hideFloating(status?: boolean): void;

    public abstract listFriends(): Observable<User[]>;

    public abstract getRecentChats(): Observable<User[]>;

    public abstract getMessageHistory(userId: any, pageToken?: number): Observable<IDataItems<Message>>;

    public abstract updateStatus(status: UserStatus): void;

    public abstract sendMessage(message: Message, to?: User): void;

    // ### Adapter/Chat income/ingress events ###

    public onConnectionStatusChange(status: ConnectionStatus): void {
        this.connectionStatusChangeHandler.emit(status);
    }

    public onFriendsListChanged(users: User[]): void {
        this.friendsListChangedHandler.emit(users);
    }

    public onMessageReceived(user: User, message: Message): void {
        this.messageReceivedHandler.emit({
            sender: user,
            message: message,
        });
    }

    public onResultReceived(data: any): void {
        this.cmdResultHandler.emit(data);
    }

    public onNotificationReceived(message: string): void {
        this.notificationHandler.emit(message);
    }

    // Event handlers
    // friendsListChangedHandler: (users: User[]) => void = (users: User[]) => { };
    // messageReceivedHandler: (user: User, message: Message) => void = (user: User, message: Message) => { };
}
