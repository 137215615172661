<span *ngFor="let item of thumbs" [ngClass]="options.class">
  <span *ngIf="editable">
    <img appDefault="assets/img/file-icon.png" [src]="item.url" [width]="options.thumb.width" [height]="options.thumb.height" [ngClass]="options.thumb.class" />
    <mat-icon (click)="delete(item)">add_circle</mat-icon>
  </span>
  <span *ngIf="!editable">
    <a target="_blank" [href]="item.url">
      <img appDefault="assets/img/file-icon.png" [src]="item.url" [width]="options.thumb.width" [height]="options.thumb.height" [ngClass]="options.thumb.class" />
    </a>
  </span>
</span>
