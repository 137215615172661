import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

import { AccountService } from '../../../../dependencies';

@Component({
  selector: 'app-splash-new-opportunity',
  templateUrl: './splash-new-opportunity.component.html',
  styleUrls: ['./splash-new-opportunity.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class SplashNewOpportunityComponent implements OnInit {

  public static readonly code: string = 'splash-new-opportunity';

  data: {
    title: string;
    skipUpdate: boolean;
  }

  constructor(
    private dialogRef: MatDialogRef<SplashNewOpportunityComponent>,
    private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) input?: { title: string, skipUpdate: boolean },
  ) { }

  ngOnInit(): void {
  }

  dismiss(event: Event, redirect?: string): void {
    event.stopPropagation();
    event.preventDefault();

    const self = this;

    this.accountService.saveUserPreferences({
      pref: SplashNewOpportunityComponent.code,
      value: null,
    }).pipe(first())
      .subscribe(
        res => {
          self.dialogRef.close({ redirect });
        }
      );
  }

}
