import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutFooterRoutes } from './footer';
import { LayoutHeaderRoutes } from './header';

export const AnonymousLayoutRoutes: Routes = [
  ...LayoutFooterRoutes,
  ...LayoutHeaderRoutes,
];

// @NgModule({
//   imports: [
//     RouterModule.forChild([
//       {
//         path: '',
//         children: [
//           ...LayoutFooterRoutes,
//           ...LayoutHeaderRoutes,
//         ]
//       }
//     ]),
//   ],
//   exports: [
//     // RouterModule,
//   ],
// })

// export class AnonymousLayoutRoutingModule { }
