
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { IResponse } from '../../../interfaces';
import { ConfigService } from '../utility/config.service';
import { INotificationsResponse, INotificationResultsResponse } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class NotificationService {

  // private placesConfig: any = this.configService.get('places_api');
  // private client: any = google.createClient({ key: this.placesConfig.KEY });

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  public get(notificationId: number): Observable<INotificationsResponse> {

    return this.http
      .get(`${ this.configService.getApiUrl() }/notifications/${ notificationId }`)
      .pipe(
        map((response: INotificationsResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Notification data.'))
      );
  }

  /**
   * getPreview
   */
  public getAll(pageToken?: number, recordCount?: number): Observable<INotificationsResponse> {

    let filters = '';
    filters += pageToken ? `p=${ pageToken }&` : '';
    filters += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/notifications?${ filters }`)
      .pipe(
        map((response: INotificationsResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Notifications data.'))
      );
  }

  public getResults(notificationId: number, pageToken?: number, recordCount?: number): Observable<INotificationResultsResponse> {

    let filters = '';
    filters += pageToken ? `p=${ pageToken }&` : '';
    filters += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/notifications/${ notificationId }/results?${ filters }`)
      .pipe(
        map((response: INotificationResultsResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Notification Results data.'))
      );
  }

  public delete(notificationId: number): Observable<IResponse> {
    return this.http
      .delete(`${ this.configService.getApiUrl() }/notifications/${ notificationId }`)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to delete the Notification.'))
      );
  }

  public deleteNotifications(data: number[]): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/notifications/deleteSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to delete the notifications.'))
      );
  }

}
