import { JwtHelperService, JwtConfig } from '@auth0/angular-jwt';
import { CookieService } from 'ng2-cookies';
import { StrategyIndex, StorageStrategies, LocalStorageService } from 'ngx-webstorage';

import { StorageService, UserService } from '../../../shared';

export function JwtOptionsFactory(
    localStorage: StorageService,
    cookie: CookieService,
): any {
    return {
        tokenGetter: () => {
            const jwtHelper = new JwtHelperService();
            if (StrategyIndex.isStrategyRegistered(StorageStrategies.Local)) {
                localStorage = new StorageService(new LocalStorageService(StrategyIndex.get(StorageStrategies.Local)));
                const token = localStorage.get(UserService.TOKEN);
                if (token && jwtHelper.isTokenExpired(token)) {
                    localStorage.clear();
                    cookie.deleteAll('/', '.sociate.io');
                    window.location.reload();
                    return null;
                }
                return token;
            }
            return null;
        },
        allowedDomains: new Array(
            new RegExp('^api(\\.\\w+)*.sociate\\.io(:\\d{2,5})?$'),
        ),
        disallowedRoutes: [
            '/api/auth/login',
            '/api/account/register',
        ]
    } as JwtConfig;
}
