
import { throwError as observableThrowError, Observable, Observer, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as google from '@google/maps';
import { map, catchError } from 'rxjs/operators';

import { BusinessNames, ISetting, IResponse } from '../../../interfaces';
import { ConfigService } from '../utility/config.service';
import { StorageService } from '../functional/storage.service';
import { has } from 'lodash-es';
import { UtilityService } from '../../utilities';

@Injectable({
  providedIn: 'root',
})
export class SettingService {

  // private placesConfig: any = this.configService.get('places_api');
  // private client: any = google.createClient({ key: this.placesConfig.KEY });

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private localStorage: StorageService,
    private utils: UtilityService,
  ) { }

  /**
   * getSchools
   */
  public get(item?: string): Observable<IResponse> {

    let subRes = '';
    if (item) {
      subRes = `/${ item }`;
    }

    return this.http
      .get(`${ this.configService.getApiUrl() }/admin/settings${ subRes }`)
      .pipe(
        map((res: IResponse) => res),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve data.'))
      );
  }

  public save(data: ISetting): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/admin/settings`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to save setting.'))
      );
  }

}
