import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

import { IPreview } from '../../../interfaces';
import { IPreviewResponse, MetadataService } from '../../../dependencies';

@Component({
  selector: 'app-article-preview',
  templateUrl: './article-preview.component.html',
  styleUrls: ['./article-preview.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class ArticlePreviewComponent implements OnInit {

  @Input() hideTitle: boolean;
  @Input() hideDescription: boolean;
  @Input() hideImage: boolean;
  @Input() hidePublisher: boolean;
  @Input() showActions: boolean;

  @Input() url: string;
  @Input() data: IPreview;
  @Input() size: 'small' | 'medium' | 'large';
  close: boolean;

  constructor(
    private metadataService: MetadataService,
  ) { }

  ngOnInit() {

    if (this.url && !this.data) {
      const self = this;
      this.metadataService
        .generatePreview(this.url)
        .pipe(first())
        .subscribe((res: IPreviewResponse) => {
          self.data = res.data;
        });
    }

    this.data = this.data || <IPreview>{};
    this.size = this.size || 'large';

  }

  openWindow() {

    window.open(this.data.url, '_blank');

  }

}
