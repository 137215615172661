
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';

import { IPersonalData, ISpecializationData, IProfessionalData } from '../../../interfaces/account';
import { MessageService, ConfigService, IResponse, IPreference, IDevice } from '../../..';

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  private options = { withCredentials: true };

  /**
   * Constructor of the class.
   *
   * @param {AuthHttp}        http
   * @param {Router}          router
   * @param {MessageService}  messageService
   * @param {ConfigService}   configService
   */
  public constructor(
    private http: HttpClient,
    private router: Router,
    private messageService: MessageService,
    private configService: ConfigService,
  ) { }

  /**
 * Method to create a new user
 *
 * @returns boolean
 */
  public isregistered(email): Observable<IResponse> {
    return this.http
      .get(`${ this.configService.getApiUrl() }/account/register/${ email }`)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Signup failed'))
      );
  }

  /**
   * Method to create a new user
   *
   * @returns null
   */
  public register(userData): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/account/register`, userData)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Signup failed'))
      );
  }

  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<IResponse>}
   */
  public uploadPicture(data): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/account/picture`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Account save failed'))
      );
  }

  /**
   * getNameAndEmail
   */
  public saveBasicDetails(data): Observable<IResponse> {
    return this.http
      .put(`${ this.configService.getApiUrl() }/account/basic`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Account save failed'))
      );
  }

  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<IPersonalData>}
   */
  public getPersonalDetails(): Observable<IPersonalData> {
    return this.http
      .get(`${ this.configService.getApiUrl() }/account/personal`)
      .pipe(
        map((response: IPersonalData) => response),
        catchError((error: any) => observableThrowError(error || 'Account retrieve failed'))
      );
  }

  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<IResponse>}
   */
  public savePersonalDetails(data): Observable<IResponse> {
    return this.http
      .put(`${ this.configService.getApiUrl() }/account/personal`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Account save failed'))
      );
  }

  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<IProfessionalData>}
   */
  public getProfessionalDetails(): Observable<IProfessionalData> {
    return this.http
      .get(`${ this.configService.getApiUrl() }/account/professional`)
      .pipe(
        map((response: IProfessionalData) => response),
        catchError((error: any) => observableThrowError(error || 'Account retrieve failed'))
      );
  }

  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<IResponse>}
   */
  public saveProfessionalDetails(data): Observable<IResponse> {
    return this.http
      .put(`${ this.configService.getApiUrl() }/account/professional`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Account save failed'))
      );
  }


  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<IResponse>}
   */
  public saveResume(data): Observable<IResponse> {
    return this.http
      .put(`${ this.configService.getApiUrl() }/account/resume`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Account save failed'))
      );
  }

  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<ISpecializationData>}
   */
  public getSpecializationDetails(): Observable<ISpecializationData> {
    return this.http
      .get(`${ this.configService.getApiUrl() }/account/specialization`)
      .pipe(
        map((response: ISpecializationData) => response),
        catchError((error: any) => observableThrowError(error || 'Account retrieve failed'))
      );
  }

  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<IResponse>}
   */
  public saveSpecializationDetails(data): Observable<IResponse> {
    return this.http
      .put(`${ this.configService.getApiUrl() }/account/specialization`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Account save failed'))
      );
  }

  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<Array<IPreference>>}
   */
  public getUserPreferences(): Observable<Array<IPreference>> {
    return this.http
      .get(`${ this.configService.getApiUrl() }/account/preferences`)
      .pipe(
        map((response: IResponse) => response.data),
        catchError((error: any) => observableThrowError(error || 'Account retrieve failed'))
      );
  }

  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<IResponse>}
   */
  public saveUserPreferences(data): Observable<IResponse> {
    return this.http
      .put(`${ this.configService.getApiUrl() }/account/preferences`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Account save failed'))
      );
  }

  /**
 * Method to fetch user profile data from backend.
 *
 * @returns {Observable<Array<IDevice>>}
 */
  public getUserDevices(): Observable<Array<IDevice>> {
    return this.http
      .get(`${ this.configService.getApiUrl() }/account/devices`)
      .pipe(
        map((response: IResponse) => response.data),
        catchError((error: any) => observableThrowError(error || 'Account devices retrieve failed'))
      );
  }

  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<IResponse>}
   */
  public deleteUserDevices(data: string[]): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/account/devices/deleteSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Account devices deletion failed'))
      );
  }


  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<IResponse>}
   */
  public activateAccount(data): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/account/activation`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Account save failed'))
      );
  }

  /**
 * Method to fetch user profile data from backend.
 *
 * @returns {Observable<IResponse>}
 */
  public activateAccountById(userId: string): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/account/activation/${ userId }`, null)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Account save failed'))
      );
  }

  /**
   * Method to create a new user
   *
   * @returns null
   */
  public resendActivation(data: any): Observable<IResponse> {

    return this.http
      .put(`${ this.configService.getApiUrl() }/account/activation/resend`, data, this.options)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to send activation mail'))
      );
  }

  /**
   * Method to create a new user
   *
   * @returns null
   */
  public resendActivationById(data: string[]): Observable<IResponse> {

    return this.http
      .put(`${ this.configService.getApiUrl() }/account/activation/resendSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to send activation mail'))
      );
  }

  /**
   * Method to activate a recruiter
   *
   * @returns null
   */
  public recruiterActivation(): Observable<IResponse> {

    return this.http
      .get(`${ this.configService.getApiUrl() }/account/activation/recruiterActivation`)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Activation failed'))
      );
  }

  /**
  * Method to activate a recruiter
  *
  * @returns null
  */
  public checkActivationEligibility(): Observable<any> {

    return this.http
      .get(`${ this.configService.getApiUrl() }/account/activation/checkActivationEligibility`)
      .pipe(
        map((response: boolean) => response),
        catchError((error: any) => observableThrowError(error || 'Activation eligibility failed.'))
      );
  }

  /**
  * Method to fetch user profile data from backend.
  *
  * @returns {Observable<IResponse>}
  */
  public deactivate(): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/account/activation/disable`, null)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Account cancellation failed'))
      );
  }

}
