import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../../dependencies';

import { IFooterItem } from './interfaces';

@Component({
  selector: 'app-auth-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})

export class FooterComponent {
  public version: string; // = require('../../../../../../../package.json').version;
  public year = (new Date()).getFullYear();

  public links: IFooterItem[] = [
    // {
    //   name: 'GitHub',
    //   url: 'https://github.com/tarlepp/angular2-frontend',
    //   icon: 'web',
    // },
    // {
    //   name: 'FOOTER_LINK_ISSUES',
    //   url: 'https://github.com/tarlepp/angular2-frontend/issues',
    //   icon: 'bug_report',
    //   translate: true,
    // },
    // {
    //   name: 'Tarmo Leppänen',
    //   url: 'https://github.com/tarlepp',
    //   icon: 'person',
    // },
    {
      name: 'FOOTER_LINK_CONTACT_US',
      url: 'mailto:contact@sociate.io',
      icon: 'email',
      translate: true,
    }
  ];

  public constructor(
    private translateService: TranslateService,
    private configService: ConfigService,
  ) {
    this.version = this.configService.version;
  }
}
