
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map, first } from 'rxjs/operators';

import { UserService, MessageService, ConfigService, IResponse, ChatService, RequestCache, ProfileService, ITokenData } from '../../../shared';

@Injectable()
export class AuthService {

  // public static readonly whitelistedDomains = [new RegExp('^api(\\.\\w+)*.sociate\\.io(:\\d{2,5})?$')];
  private readonly options = { withCredentials: true };

  /**
   * Constructor of the class.
   *
   * @param {Http}            http
   * @param {AuthHttp}        authHttp
   * @param {Router}          router
   * @param {UserService}     userService
   * @param {MessageService}  messageService
   * @param {ConfigService}   configService
   */
  public constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private profileService: ProfileService,
    private messageService: MessageService,
    private configService: ConfigService,
    private chatService: ChatService,
    private cache: RequestCache,
  ) {
    this.profileService.refreshed.subscribe(() => { this.refresh().pipe(first()).subscribe(); });
  }

  public get passwordPattern(): RegExp {
    // Upper case, lower case and numbers. Not starting with number.
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d][a-zA-Z\d$@!#%*^&]+$/;
  }

  /**
   * Method to make login request to backend with given credentials.
   *
   * @param credentials
   * @returns {Observable<IResponse>}
   */
  public login(credentials): Observable<IResponse> {

    const self = this;
    const hash = window.btoa(`${ credentials.email }:${ credentials.passwd }`);
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        'Authorization': `Basic ${ hash }`,
      }),
      ...this.options,
    };

    return this.http
      .post(`${ this.configService.getApiUrl() }/auth/login`, null, { observe: 'response', ...httpOptions })
      .pipe(
        map((res: HttpResponse<IResponse>) => {
          if (res.body.status.status === 'STA_SUCCESS') {
            // Store tokens for current user if returned
            this.userService.storeTokens(self.extractToken(res.headers));
            // if (this.userService.isActive) {
              this.chatService.connect();
            // }
          }
          return <IResponse>{ status: res.body.status };
        }),
        catchError((error: any) => observableThrowError(error.json() || 'Invalid credentials'))
      );
  }

  /**
 * Method to make login request to backend with given credentials.
 *
 * @param credentials
 * @returns {Observable<IResponse>}
 */
  public refresh(): Observable<IResponse> {
    const self = this;

    this.cache.clear();
    return this.http
      .get(`${ this.configService.getApiUrl() }/auth/refresh`, { observe: 'response', ...this.options })
      .pipe(
        map((res: HttpResponse<IResponse>) => {
          if (res.body.status.status === 'STA_SUCCESS') {
            // Store tokens for current user
            this.userService.storeTokens(self.extractToken(res.headers));
          }
          return <IResponse>{ status: res.body.status };
        }),
        catchError((error: any) => observableThrowError(error.json() || 'Invalid credentials'))
      );
  }

  /**
   * Method to logout current user
   *
   * @returns {Promise<boolean>}
   */
  public logout(): void {

    this.cache.clear();
    this.chatService.disconnect();
    const self = this;

    this.http
      .get(`${ this.configService.getApiUrl() }/auth/logout`, this.options).pipe(first())
      .subscribe(() => {
        return self.logoutLocal();
      }, (error) => {
        return self.logoutLocal();
      });

  }

  private logoutLocal(): void {
    this.userService.erase();
    this.messageService.simple('Logged out successfully');
    this.router.navigate(['/home']);
  }

  /**
   * Method to make login request to backend with given credentials.
   *
   * @param credentials
   * @returns {Observable<IForgotData>}
   */
  public forgot(email: any): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/auth/forgot`, email)
      .pipe(
        map((res: Response) => { return <any>{ status: res.status, headers: res.headers } }),
        catchError((error: any) => observableThrowError(error.json() || 'Invalid user name.'))
      );
  }

  public reset(password: any): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/auth/reset`, password)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Reset failed'))
      );
  }

  /**
   * Method to make login request to backend with given credentials.
   *
   * @param credentials
   * @returns {Observable<IResponse>}
   */
  public simulate(sociateId: string): Observable<IResponse> {

    const self = this;
    return this.http
      .post(`${ this.configService.getApiUrl() }/auth/simulate`, { userId: sociateId }, { observe: 'response', ...this.options })
      .pipe(
        map((res: HttpResponse<IResponse>) => {
          // Store tokens for current user if returned
          if (res.body.status.status === 'STA_SUCCESS') {
            this.userService.simulate(self.extractToken(res.headers));
            this.cache.clear();
            // this.chatService.connect();
          }
          return <IResponse>{ status: res.body.status };
        }),
        catchError((error: any) => observableThrowError(error.json() || 'Invalid request to simulate'))
      );
  }

  private extractToken(headers: HttpHeaders): ITokenData {
    return {
      token: headers.get('sociate-api-token'),
      refresh_token: headers.get('sociate-refreshToken-token'),
    };
  }

  /**
   * Method to fetch user profile data from backend.
   *
   * @returns {Observable<IProfileData>}
   */
  // public profile(): Observable<IProfileData> {
  //   return this.authHttp
  //     .get(`${this.configService.getApiUrl()}/auth/profile`)
  //     .map((response: Response) => response)
  //     .catch((error: any) => {
  //       this.logout();

  //       return Observable.throw(error.json().message || 'Invalid credentials');
  //     })
  //     ;
  // }
}
