import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

import { HttpLoaderFactory } from './http-loader-factory';
import { ConfigService } from '../dependencies';
import { TranslationGuard } from './guards/translation.guard';
import { TranslationService } from './services/translation.service';
import { TranslationCacheService } from './services/translation-cache.service';
import { LocaleResolver } from './resolves';

@NgModule({
  imports: [
    CommonModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient, ConfigService, TranslationCacheService],
    //   },
    // }),
  ],
  providers: [
    TranslationGuard,
    TranslateService,
    TranslationService,
    TranslationCacheService,
    LocaleResolver,
  ],
  exports: [
    TranslateModule,
  ]
})

export class TranslationModule { }
