import { Pipe, PipeTransform } from '@angular/core';
import { sortedUniq, map } from 'lodash-es';

@Pipe({
  name: 'distinct'
})
export class DistinctPipe implements PipeTransform {

  transform(values: any[], prop?: string): any[] {
    if (prop) {
      const distinct = [];
      let keys = sortedUniq(map(values, prop));
      if (keys.length === 0) {
        return distinct;
      }
      for (let i = 0; i < values.length; i++) {
        const item = values[i];
        if (keys.includes(item[prop])) {
          distinct.push(item);
          keys = keys.splice(item[prop], 1);
          if (keys.length === 0) {
            break;
          }
        }
      }
      return distinct;
    } else {
      return values ? sortedUniq(values) : values;
    }
  }

}
