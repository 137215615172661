<ng-template #mentionListTemplate let-item="item">
  <span class="name">{{item.firstname}}&nbsp;{{item.lastname}}</span>
  <span *ngIf="item.schools">
    (
    <span class="school">{{item.schools[0]}}</span>
    <span class="year">'{{item.enrollment.toString().substring(2)}}</span>
    )
  </span>
</ng-template>

<div class="curtain" *ngIf="posting && !post" (click)="posting=!posting"></div>

<form [formGroup]="postForm" #formDirective="ngForm" (submit)="savePost($event, formDirective)" novalidate
  autocomplete="off">

  <div ng2FileDrop class="drop-zone" [ngClass]="{'drop-zone-over': hasDropZoneOver, 'posting': posting}"
    [uploader]="uploader" (fileOver)="fileOverHandler($event)" fxLayout="column" fxLayoutAlign="space-around center">

    <div class="post-content" fxFill>
      <div fxLayout="column" fxFlex>
        <mat-card-header>
          <div mat-card-avatar class="profile">
            <img appDefault mat-card-avatar src="{{currentUser.picture}}">
          </div>
          <mat-card-title>
            <span class="name">{{currentUser.firstname}}&nbsp;{{currentUser.lastname}}</span>
            <!-- <span *ngIf="currentUser.schools" class="university">{{currentUser.schools[0]}}</span>
            <span *ngIf="currentUser.enrollment" class="year">'{{currentUser.enrollment.toString().substring(2)}}</span> -->
          </mat-card-title>
          <mat-card-subtitle *ngIf="currentUser.profession">
            <span class="work">{{ currentUser.profession.title }} @ {{ currentUser.profession.company }}</span>
          </mat-card-subtitle>
          <div class="close" *ngIf="posting">
            <div>
              <mat-icon class="icon-button" [class.disabled]="loading" (click)="closeDialog()"
                matTooltip="{{ 'TOOLTIP_CLOSE' | translate }}">close</mat-icon>
            </div>
          </div>
        </mat-card-header>
        <div fxLayout="row">
          <div fxFlex [hidden]="posting">
            <mat-form-field hideRequiredMarker>
              <!-- <textarea name="post" minRows="2" placeholder="{{ 'PLACEHOLDER_NEW_POST' | translate }}" [mention]="sociates | async" [mentionConfig]="{disableSearch: true, labelKey: 'firstname', mentionSelect: formatSociate}"
                [mentionListTemplate]="mentionListTemplate" (searchTerm)="findSociates($event)" required matInput matTextareaAutosize
                #autosize="matTextareaAutosize" matAutosizeMinRows="2" matAutosizeMaxRows="10" [(ngModel)]="content" formControlName="postControl"
                (focus)="posting=true" (keydown)="onkeydown($event)"></textarea> -->
              <!-- <textarea name="post" minRows="2" placeholder="{{ 'PLACEHOLDER_NEW_POST' | translate }}" required matInput
                matTextareaAutosize #autosize="matTextareaAutosize" matAutosizeMinRows="2" matAutosizeMaxRows="10"
                [(ngModel)]="content" formControlName="postControl" (focus)="posting=true"
                (keydown)="onkeydown($event)"></textarea> -->

              <textarea name="post" placeholder="{{ 'PLACEHOLDER_NEW_POST' | translate }}" matInput cdkTextareaAutosize
                #autosize="cdkTextareaAutosize" matAutosizeMinRows="2" (focus)="posting=true" tabindex="-1"></textarea>
            </mat-form-field>
          </div>
          <div fxFlex [hidden]="!posting">
            <textarea appHtmlEditor name="post" id="postControl" minRows="2"
              placeholder="{{ 'PLACEHOLDER_NEW_POST' | translate }}" required matInput cdkTextareaAutosize
              #autosize="cdkTextareaAutosize" matAutosizeMinRows="2" matAutosizeMaxRows="10" [(ngModel)]="content"
              formControlName="postControl" #postControl [setFocus]="posting" (keydown)="onkeydown($event)"
              tabindex="-1"></textarea>
          </div>
        </div>
        <ng-container *ngIf="posting">
          <div fxFlex>
            <mat-form-field hideRequiredMarker>
              <!-- <mat-icon matPrefix>local_offer</mat-icon> -->
              <span matPrefix class="hash-tag">#</span>
              <mat-select name="selectedTags" multiple placeholder="{{ 'PLACEHOLDER_NEW_POST_TAGS' | translate }}"
                required [(ngModel)]="selectedTags" formControlName="selectedTagsControl" #selectedTagsControl>
                <mat-optgroup label="Tags">
                  <mat-option *ngFor="let s of specializations" [value]="s.id">{{s.name}}</mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- School Starts -->
          <div fxFlex class="schools">
            <mat-form-field hideRequiredMarker>
              <mat-icon matPrefix>school</mat-icon>
              <mat-select #selectedSchoolsControl name="selectedSchools" multiple
                placeholder="{{ 'PLACEHOLDER_SCHOOL' | translate }}" required [disabled]="loading"
                [(ngModel)]="selectedSchools" formControlName="selectedSchoolsControl">
                <mat-optgroup label="Schools">
                  <mat-option *ngFor="let school of schools" [value]="school.id" (click)="selectSchool(school)">
                    {{school.name}}</mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- School Ends -->
          <div fxFlex>
            <app-thumb-preview [thumbs]="attachments" [editable]="true"></app-thumb-preview>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="post-footer" fxLayout="row" fxFill *ngIf="posting">
      <div fxLayout="row" class="add-ons" fxFlex>
        <button mat-raised-button color="accent" type="button" (click)="fileInput.click()" [disabled]="loading">
          <mat-icon>attach_file</mat-icon><span  *showItDevice="['desktop']">{{ 'BUTTON_UPLOAD_FILE' | translate }}</span>
        </button>
        <!-- <button mat-raised-button color="accent" type="button" (click)="uploadFile()" >{{ 'BUTTON_UPLOAD_PDF' | translate }}</button>                                 -->
      </div>
      <div fxLayout="row" fxLayoutAlign="end" class="buttons">
        <mat-checkbox class="anonymous-container" name="isAnonymous" [(ngModel)]="isAnonymous"
          formControlName="isAnonymousControl" #isAnonymousControl>{{
          'PLACEHOLDER_NEW_POST_ANONYMOUS' | translate }}</mat-checkbox>
        <!-- <mat-slide-toggle class="anonymous-container" name="isAnonymous" [(ngModel)]="isAnonymous" formControlName="isAnonymousControl">{{ 'PLACEHOLDER_NEW_POST_ANONYMOUS' | translate }}</mat-slide-toggle> -->
        <button mat-raised-button color="primary" [disabled]="postForm.invalid || loading">{{ (post? 'BUTTON_SAVE' : 'BUTTON_POST') | translate
          }}</button>
      </div>
    </div>

    <div *ngIf="hasDropZoneOver" class="file-drop-curtain" fxLayout="column" fxLayoutAlign="center center">
      <span>Drag and drop your picture here</span>
    </div>
    <input #fileInput ng2FileSelect multiple [uploader]="uploader" type="file" [accept]="supportedFormats"
      change="fileChangeListener($event)">
  </div>

</form>