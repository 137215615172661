import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { YouTubePlayerModule } from '@angular/youtube-player';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        OverlayModule,
        DragDropModule,
        YouTubePlayerModule,
    ],
    providers: [
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        YouTubePlayerModule,
        DragDropModule,
    ]
})

export class AngularModule { }
