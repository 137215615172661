import { NgModule } from '@angular/core';

import { ExternalModules } from '../../../external';
import { TranslationModule } from '../../../translation';

// import { AnonymousLayoutRoutingModule } from './anonymous-layout-routing.module';

import { HeaderComponent } from './header';
import { FooterComponent } from './footer';

@NgModule({
  imports: [
    ...ExternalModules,
    TranslationModule,
    // AnonymousLayoutRoutingModule,
  ],
  exports: [
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
  ],
})

export class AnonymousLayoutModule { }
