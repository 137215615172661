import { Directive, HostListener, Inject, OnInit, NgZone, ElementRef, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appScrollTracker]',
})
export class ScrollTrackerDirective implements OnInit {

  @Input() appScrollTracker: number;

  scrolled = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
    private element: ElementRef,
  ) { }

  ngOnInit() {
    this.appScrollTracker = this.appScrollTracker || 0;
  }

  /**
   * onWindowScroll
   */
  @HostListener('window:scroll', ['$event'])
  public onWindowScroll(event) {
    if (this.appScrollTracker === -1) { return; }
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || event.srcElement.scrollTop || 0;
    const el = this.element.nativeElement;
    if (offset > this.appScrollTracker && !el.classList.contains('scrolled')) {
      el.classList.add('scrolled');
      this.scrolled = true;
    } else if (this.scrolled && offset < this.appScrollTracker && el.classList.contains('scrolled')) {
      el.classList.remove('scrolled');
      this.scrolled = false;
    }
  }

}
