<div fxLayout="column" fxLayoutAlign="center center" fxFill>
  <mat-spinner *ngIf="loading" color="accent"></mat-spinner>

  <mat-card class="forgot">
    <mat-toolbar color="primary">{{ 'TITLE' | translate }}</mat-toolbar>

    <mat-card-content [class.mat-card-content]="device!='mobile'">
      <form [formGroup]="forgotForm" (submit)="forgot($event)" novalidate autocomplete="off"
        [class.opacity-50]="loading">
        <mat-form-field>
          <mat-icon matPrefix>person</mat-icon>

          <input name="username" placeholder="{{ 'PLACEHOLDER_USERNAME' | translate }}" type="email" email required
            appAutoFocus matInput [value]="username | lowercase" [readonly]="loading" #usernameControl
            formControlName="usernameControl" [pattern]="emailPattern" />
          <!-- <span *ngIf="!configService.isDebugMode" matSuffix>.edu</span> -->
          <mat-error *ngIf="forgotForm.controls['usernameControl'].hasError('pattern')">
            {{ 'EMAIL_INVALID' | translate }}
          </mat-error>
        </mat-form-field>

        <div>
          <button type="submit" mat-raised-button color="primary" [disabled]="forgotForm.invalid || loading">{{
            'BUTTON_SUBMIT' | translate }}</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <div class="quick-links">
    <p>
      <a mat-button class="highlight-link" [routerLink]="['/auth/login']">{{ 'LOGIN' | translate }}</a>
    </p>
  </div>

</div>