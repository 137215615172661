import { NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ExternalModules } from '../external';
import { TranslationModule } from '../translation';
import { DirectivesModule, Directives } from './directives';

import { LayoutModules } from './layouts';
import { Components } from './components';
// import { Directives } from './directives';
import { Pipes } from './pipes';

@NgModule({
  imports: [
    ...ExternalModules,
    ...LayoutModules,
    TranslationModule,
    DirectivesModule,
  ],
  // entryComponents: [
  //   ...Components,
  // ],
  exports: [
    ...LayoutModules,
    ...Components,
    ...Directives,
    ...Pipes,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DATA,
      useValue: null,
    },
    {
      provide: MatDialogRef,
      useValue: null,
    }
  ],
  declarations: [
    ...Components,
    // ...Directives,
    ...Pipes,
  ],
})
export class WidgetsModule { }
