<ng-content *ngIf="position==='bottom'"></ng-content>

<ng-container *ngIf="mode === 'formatted'; else default">
    <div #contentControl class="formatted" [class.shrinked]="!isExpanded"
        [style.max-height]="isExpanded? 'unset': maxHeight+'px'">
        <span class="content" [class.html-content]="isHtmlContent" [innerHTML]="htmlContent"></span>
        <div class="curtain" *ngIf="getContentHeight() > maxHeight && !isExpanded" fxFlex fxLayout="row"
            fxLayoutAlign="space-around center">
            <button mat-raised-button color="primary"
                (click)="isExpanded=!isExpanded">{{ readMoreLabel | titlecase }}</button>
        </div>
    </div>
</ng-container>

<ng-template #default>
    <span class="default">
        <span class="content" [class.html-content]="isHtmlContent"
            [innerHTML]="(appReadMore.length > maxLength && !isExpanded) ? sanitizedContent : htmlContent"></span>
        <span *ngIf="appReadMore.length > maxLength && !isExpanded">...<span class="more-less-button"
                *ngIf="readMoreLabel.length>0" (click)="isExpanded=!isExpanded">{{ readMoreLabel }}</span></span>
    </span>
</ng-template>

<ng-content *ngIf="position==='top'"></ng-content>