<div class="likes-container" infiniteScroll [infiniteScrollThrottle]="50"
    (scrolled)="!loading && pageToken > 0? getLikes(): null">

    <div class="likes-header">
        <h1 mat-dialog-title>Reactions</h1>
        <div class="close" [mat-dialog-close]="likes">
            <div>
                <mat-icon matTooltip="{{ 'TOOLTIP_CLOSE' | translate }}">close</mat-icon>
            </div>
        </div>
    </div>

    <h1 mat-dialog-title>
        <mat-icon class="thumb-icon" matPrefix>thumb_up_alt</mat-icon><span>{{count}}</span>
    </h1>
    <div mat-dialog-content>
        <div class="like" *ngFor="let item of likes">
            <mat-card-header>
                <div mat-card-avatar class="header-image">
                    <img appDefault mat-card-avatar src="{{item.picture}}">
                </div>
                <mat-icon class="thumb-icon">thumb_up_alt</mat-icon>
                <mat-card-title>
                    <span class="name">{{item.firstname}}&nbsp;{{item.lastname}}</span>
                </mat-card-title>
                <mat-card-subtitle>
                    <span class="work">{{ item.title }} @ {{ item.company }}</span>
                </mat-card-subtitle>
            </mat-card-header>
        </div>
    </div>

    <!-- Load more -->
    <div class="load-more-container" *ngIf="!loading && pageToken > 0">
        <button mat-raised-button color="primary" (click)="getPosts()">Load More</button>
    </div>

</div>