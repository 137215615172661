
export enum BusinessNames {
    Schools,
    Specializations,
    ParentTags,
    Companies,
    Titles,
    FunctionalAreas,
    Industries,
}
