<div class="comment-row flex-container">
  <div mat-card-avatar class="header-image">
    <img appDefault mat-card-avatar [routerLink]="[data.author.profileUrl]" src="{{data.author.picture}}">
  </div>
  <div class="flex-1 comment-container">
    <div class="comment">
      <span class="user-name router-link underline-hover" [routerLink]="[data.author.profileUrl]">{{data.author.firstname}}&nbsp;{{data.author.lastname}}</span>
      <span *ngIf="(data.content.length > 300 && data.isCommentTextExpanded === false)" [innerHTML]="data.content.substring(0,297)"></span>
      <span *ngIf="(data.content.length <= 300 || data.isCommentTextExpanded !== false)" [innerHTML]="data.content"></span>
      <span class="more-less-button" *ngIf="(data.content.length > 300 && data.isCommentTextExpanded === false)"
        (click)="data.isCommentTextExpanded = true">... see more</span>
    </div>
    <div class="comment-date">
      {{createdDate}}
    </div>
    <div class="attachments" *ngIf="data.attachments">
      <app-thumb-preview [thumbs]="data.attachments"></app-thumb-preview>
    </div>
  </div>
</div>
