import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ISociate } from '../../../interfaces';
import { ClientService, MessageService } from '../../../dependencies';


@Component({
  selector: 'app-admin-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  device: string;
  loading = false;
  dataType: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public input: { data: any, title: string, confirmButtonLabel: string },
    private dialogRef: MatDialogRef<ConfirmComponent>,
    private clientService: ClientService,
    private messageService: MessageService,
    ) { }

  ngOnInit() {
    const self = this;
    this.device = this.clientService.device;

  }

}
