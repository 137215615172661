<!-- <mat-sidenav-container fxFlex fxFill> -->
<!-- <mat-sidenav #sidenav>
    <router-outlet name="sidenav"></router-outlet>
  </mat-sidenav> -->

  <div fxLayout="column" fxLayoutAlign="space-between" fxFill>
    <header>
      <router-outlet name="header"></router-outlet>
    </header>
  
    <article>
      <router-outlet></router-outlet>
    </article>
  
    <footer>
      <router-outlet name="footer"></router-outlet>
    </footer>
  
  </div>
  <!-- </mat-sidenav-container> -->
  