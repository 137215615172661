import { Injectable, ErrorHandler, isDevMode } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as StackTrace from 'stacktrace-js';

import { LogService } from './log.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  constructor(
    // private injector: Injector,
    private logger: LogService,
    private location: LocationStrategy,
  ) {
    super();
  }

  handleError(error: any): void {
    if (isDevMode()) {
      if (!error.message.startsWith('ExpressionChangedAfterItHasBeenCheckedError')) {
        super.handleError(error);
      }
    }

    // const logger = this.injector.get(LogService);
    // const location = this.injector.get(LocationStrategy);
    const message = error.message ? error.message : error.toString();
    const url = location instanceof PathLocationStrategy ? location.path() : location.pathname;

    // get the stack trace, lets grab the last 10 stacks only
    StackTrace.fromError(error)
      .then(stackframes => {
        const stackString = stackframes
          .splice(0, 20)
          .map(function (sf) {
            return sf.toString();
          }).join('\n');
        // log on the server
        this.logger.error(message, { url: url, stack: stackString });
      })
      .catch(err => {
        this.logger.error(message, { url: url, error: err });
        // console.log(err);
      });

    // super.handleError(error);
  }

}
