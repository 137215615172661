import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FixedComponent } from '../../shared';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends FixedComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) document: Document,
  ) {
    super(document);
  }

  ngOnInit() {

  }

}
