<div fxLayout="column" fxLayoutAlign="baseline center">
  <mat-spinner *ngIf="loading" color="accent"></mat-spinner>

  <mat-card class="login">
    <mat-toolbar color="primary">{{ 'TITLE' | translate }}</mat-toolbar>

    <mat-card-content [class.mat-card-content]="device!='mobile'">
      <form [formGroup]="loginForm" (submit)="login($event)" novalidate autocomplete="off" [class.opacity-50]="loading">
        <mat-form-field>
          <mat-icon matPrefix>person</mat-icon>

          <input #usernameControl appAutoFocus name="username" placeholder="{{ 'PLACEHOLDER_USERNAME' | translate }}" type="email" email required
            matInput [value]="username | lowercase" [readonly]="loading"
            formControlName="usernameControl" [pattern]="emailPattern" />
          <!-- <span *ngIf="!configService.isDebugMode" matSuffix>.edu</span> -->
          <mat-error *ngIf="loginForm.controls['usernameControl'].hasError('pattern')">{{ 'EMAIL_INVALID' | translate }}
            </mat-error>          
        </mat-form-field>

        <mat-form-field>
          <mat-icon matPrefix>vpn_key</mat-icon>

          <input name="password" placeholder="{{ 'PLACEHOLDER_PASSWORD' | translate }}"
            [type]="hidePassword ? 'password' : 'text'" required matInput [value]="password" [readonly]="loading"
            formControlName="passwordControl" />
          <mat-icon class="app-clickable" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ?
            'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>

        <div>
          <button type="submit" mat-raised-button color="primary" [disabled]="loginForm.invalid || loading">{{
            'BUTTON_SUBMIT' | translate }}</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <div class="quick-links">
    <p>
      <a mat-button class="highlight-link" [routerLink]="['/account/register']">{{ 'SIGNUP' | translate }}</a>
    </p>
    <p>
      <a mat-button class="highlight-link forgot" [routerLink]="['/auth/forgot']">{{ 'FORGOT' | translate }}</a>
    </p>
  </div>

</div>
