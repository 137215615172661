import { Component, OnInit, Input, Output, EventEmitter, HostListener, Inject } from '@angular/core';
import { FormGroup, FormControl, FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, first } from 'rxjs/operators';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { map as _map, difference } from 'lodash-es';

import { IPost, ISociate, BusinessNames, IMetaData, IResponse, IThumb, ISchool } from '../../../interfaces';
import { PostService, MetadataService, UserService, IUser, IPostsResponse, ConfigService, StorageService, SociateService, ISociatesResponse, MessageService } from '../../../dependencies';

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class AddPostComponent implements OnInit {

  @Input() hideUpload: boolean;
  @Input() hideAnonymous: boolean;
  @Input() size: 'small' | 'medium' | 'large';
  @Input() visible: boolean;
  @Input() posting: boolean = false;

  @Output() added: EventEmitter<IPost>;

  supportedFormats = [
    'image/*',
    '.pdf',
    '.doc',
    '.docx',
    'application/msword',
  ].join(',');
  loading = false;

  postForm: FormGroup;
  content: string;
  selectedTags: Array<any>;
  selectedSchools: Array<any>;
  isAnonymous: boolean;

  specializations: Array<IMetaData>;
  public schools: Array<IMetaData> = [{
    id: 0,
    code: '',
    name: 'All',
  }];
  currentUser: IUser;

  uploader: FileUploader;
  hasDropZoneOver: boolean;

  attachments: Array<IThumb>;
  sociates: Observable<Array<ISociate>>;
  // sociates: Observable<Array<any>>;
  sociateStream: Subject<string>;

  constructor(
    private postService: PostService,
    private metadataService: MetadataService,
    private messageService: MessageService,
    private userService: UserService,
    private sociateService: SociateService,
    private configService: ConfigService,
    private localStorage: StorageService,
    @Inject(MAT_DIALOG_DATA) public post: IPost,
    private dialogRef: MatDialogRef<AddPostComponent>,
  ) {

    this.postForm = new FormGroup({
      postControl: new FormControl(),
      selectedTagsControl: new FormControl(),
      selectedSchoolsControl: new FormControl(),
      isAnonymousControl: new FormControl(),
    });

    this.added = new EventEmitter<IPost>();
    // this.uploader = this.fileService.getUploader();
    this.uploader = new FileUploader({
      url: `${ this.configService.getApiUrl() }/file/stream`,
      authTokenHeader: 'Authorization',
      authToken: (() => {
        return `Bearer ${ this.localStorage.get(UserService.TOKEN) }`;
      })(),
    });
    this.sociateStream = new Subject<string>();
  }

  ngOnInit() {
    const self = this;
    this.currentUser = this.userService.profile;
    this.visible = true;
    this.size = this.size || 'large';

    this.hasDropZoneOver = false;
    this.posting = !!this.post;
    this.content = this.post?.content || '';
    this.selectedTags = this.post?.tags || [];
    this.selectedSchools = this.post?.global ? [0] : this.post?.schools || [0];
    this.attachments = this.post?.attachments || [];
    this.isAnonymous = this.post?.anonymous || false;

    this.metadataService
      .get(BusinessNames.Specializations).pipe(first())
      .subscribe(
        (metaData) => {
          self.specializations = metaData;
        }
      );

    this.metadataService
      .get(BusinessNames.Schools).pipe(first())
      .subscribe(
        (metaData) => {
          self.schools.push(...metaData);
          // const u_schools = self.currentUser.schools;
          // self.schools.forEach(s => {
          //   if (u_schools && u_schools.includes(s.name)) {
          //     self.selectedSchools.push(s.id);
          //   }
          // });
        }
      );

    this.uploader.onAfterAddingFile = this.fileDropListener;
    this.uploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      self.fileUploaded.apply(self, [item, response, status, headers]);
    };

    // this.sociates = this.sociateStream
    //   .pipe(
    //     debounceTime(300),
    //     distinctUntilChanged(),
    //     switchMap((term: string) =>
    //       self.sociateService
    //         .find(term)
    //         .pipe(
    //           map((response: ISociatesResponse) => {
    //             if (response.status.status === 'STA_SUCCESS') {
    //               return response.data.sociates;
    //               // return ['randip', 'pallav', 'vikas'];
    //             }
    //           })
    //         )
    //       // .toPromise()
    //       // .then((response: ISociatesResponse) => ['randip', 'pallav', 'vikas'])
    //     )
    //   );

    this.loading = false;

  }

  // onfocus($event): void {
  //   this.posting = true;
  // }

  // onblur($event): void {
  //   this.posting = this.content.length > 0
  //     || this.selectedTags.length > 0
  //     || this.attachments.length > 0
  //     || this.isAnonymous
  //     ;
  // }

  @HostListener('window:keydown', ['$event'])
  onkeydown($event): void {

    // console.log($event.srcElement);
    if ($event.key === 'Escape' && $event.path.findIndex((el) => {
      return el.localName === 'mat-optgroup' || el.localName === 'mat-select';
    }) === -1) {
      this.posting = false;
      $event.srcElement.blur();
    }
  }

  fileOverHandler(e: any): void {
    this.hasDropZoneOver = e;
  }

  fileChangeListener($event): void {
    // this.loadFile($event.target.files[0]);
  }

  fileDropListener(droppedFile: FileItem): void {
    droppedFile.upload();
  }

  fileUploaded(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): void {
    const resp: IResponse = JSON.parse(response);
    this.attachments.push({
      id: resp.data.id,
      url: resp.data.file,
      type: item.file.type,
      file: item,
    });
  }

  findSociates(criteria: string): void {
    this.sociateStream.next(criteria);
  }

  formatSociate(sociate: ISociate): string {
    return `<span data-uid="${ sociate.userId }">${ sociate.firstname } ${ sociate.lastname }</span>`;
  }

  selectSchool(school: ISchool): void {
    if (this.selectedSchools.length > 0 && (
      school.id === 0 || this.selectedSchools.includes(0)
    )) {
      this.selectedSchools = [school.id];
    }

    const diff = difference(_map(this.schools, 'id'), this.selectedSchools);
    if (diff.length === 1 && diff[0] === 0) {
      this.selectedSchools = diff;
    }
  }

  savePost(event: Event, formDirective: FormGroupDirective): void {
    const self = this;
    this.loading = true;
    this.postForm.disable();

    const isGlobal = this.selectedSchools.includes(0);
    this.selectedSchools = isGlobal ? [] : this.selectedSchools;
    const data = {
      content: this.content,
      tags: this.selectedTags,
      global: isGlobal,
      schools: this.selectedSchools,
      attachments: this.attachments.map(item => item.id),
      anonymous: this.isAnonymous,
    };

    if (this.post) {
      this.postService
        .edit(this.post.postId, data).pipe(first())
        .subscribe(
          (res: IPostsResponse) => {
            if (res?.status.status === 'STA_SUCCESS') {
              this.dialogRef.close(data);
            } else {
              self.messageService.simple(res.status.message);
            }
            self.loading = false;
          }
        );
    } else {
      this.postService
        .add(data).pipe(first())
        .subscribe(
          (res: IPostsResponse) => {
            if (res?.status.status === 'STA_SUCCESS') {
              formDirective.resetForm({
                selectedSchoolsControl: [0],
              });
              // self.postForm.reset();
              self.postForm.enable();
              self.attachments = new Array<IThumb>();
              self.posting = false;
              self.added.emit(res.data.posts[0]);
            } else {
              self.messageService.simple(res.status.message);
            }
            self.loading = false;
          }
        );
    }
  }

  closeDialog(): void {
    if (this.post) {
      this.dialogRef.close(false);
    } else {
      this.posting = !this.posting;
    }
  }

}
