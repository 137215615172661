import { Injectable, OnInit } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { serialize, deserialize } from 'bson';

@Injectable({
  providedIn: 'root',
})
export class ClientService {

  private static _contextKey = '__cs';
  private _client: any;

  constructor(
    private sessionStorage: SessionStorageService,
  ) {
    const t = this.sessionStorage.retrieve(ClientService._contextKey);
    this._client = t && typeof t === 'string' ? deserialize(new Uint8Array(t.split('|').map(Number)))[0] : {};
  }

  public init(client: any): void {
    this._client = client;
    this.sessionStorage.store(ClientService._contextKey, serialize([this._client]).join('|'));
  }

  public get device(): string {
    return this._client.device;
  }

}
