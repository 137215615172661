import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

import { LogService, ConfigService, ClientService } from '../utility';

export interface RequestCacheEntry {
  url: string;
  response: HttpResponse<any>;
  lastRead: number;
}

export abstract class RequestCache {
  abstract get(req: HttpRequest<any>): HttpResponse<any> | undefined;
  abstract put(req: HttpRequest<any>, response: HttpResponse<any>): void;
  abstract clear(): void;
  abstract resetDefaultMaxAge(maxAge?: number): void;
}

@Injectable({
  providedIn: 'root'
})
export class RequestCacheWithMap implements RequestCache {

  _maxAge = 15000; // maximum cache age (ms)
  cache = new Map<string, RequestCacheEntry>();

  constructor(
    private clientService: ClientService,
    private configService: ConfigService,
    private logService: LogService,
  ) {

    if (this.clientService.device === 'mobile') {
      this._maxAge += 10000;
    }

  }

  private get maxAge(): number {
    const config = this.configService.get ? this.configService.get() : undefined;
    if (config?.cache?.maxAge) {
      return this.clientService.device === 'mobile' ? config?.cache?.maxAge?.mobile : config?.cache?.maxAge?.default;
    }
    return this._maxAge;
  }

  resetDefaultMaxAge(maxAge?: number): void {
    this._maxAge = maxAge > 0 ? maxAge : this.maxAge;
  }

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    // console.log(`maxAge: ${ this.maxAge }`);

    const isExpired = cached.lastRead < (Date.now() - this.maxAge);
    const expired = isExpired ? 'expired ' : '';
    this.logService.debug(`Found ${ expired }response in cache for [${ url }].`);
    return isExpired ? undefined : cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.urlWithParams;
    this.logService.debug(`Caching response from [${ url }].`);

    const newEntry = { url, response, lastRead: Date.now() };
    this.cache.set(url, newEntry);

    // remove expired cache entries
    const expired = Date.now() - this.maxAge;
    this.cache.forEach(entry => {
      if (entry.lastRead < expired) {
        this.cache.delete(entry.url);
      }
    });

    this.logService.debug(`Request cache size: ${ this.cache.size }.`);
  }

  clear(): void {
    this.cache.clear();
  }
}
