import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { filter, find, capitalize } from 'lodash-es';

import { BusinessNames, IMetaData } from '../../../interfaces';
import { MetadataService, UserService } from '../../../dependencies';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime, startWith, tap, delay, map, first } from 'rxjs/operators';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class FiltersComponent implements OnInit {

  filterForm: FormGroup;

  // Needed for blur functionality
  @ViewChild('industryControl') industryControl: ElementRef;
  @ViewChild('companyControl') companyControl: ElementRef;
  @ViewChild('functionControl') functionControl: ElementRef;
  @ViewChild('locationControl') locationControl: ElementRef;
  @ViewChild('schoolControl') schoolControl: ElementRef;
  @ViewChild('memberTypeControl') memberTypeControl: ElementRef;
  @ViewChild('openToWorkControl') openToWorkControl: ElementRef;

  @Input() layoutDirection: 'row' | 'column' = 'column';
  @Input() multiple: boolean;
  @Input() filters: string[];
  @Input() companyType: 'list' | 'suggestions';
  @Input() data: Array<any>;
  @Output() changed: EventEmitter<Array<any>>;

  industryList: Array<IMetaData>;
  companyList: Array<IMetaData | any>;
  functionList: Array<IMetaData>;
  locationList: Array<string>;
  schoolList: IMetaData[];
  memberTypeList: { id: number, name: string }[] = [
    {
      id: 0,
      name: 'Current Students',
    },
    {
      id: -10,
      name: 'Recent Graduates',
    },
    {
      id: -100,
      name: 'Senior Alums',
    },
  ];

  filteredIndustries: any;
  filteredCompanies: any;
  filteredFunctions: any;
  filteredLocations: any;
  filteredSchools: any;
  filteredMemberTypes: any;

  industry: string;
  company: any;
  function: string;
  location: string;
  school: any;
  memberType: any;
  openToWork: boolean = false;
  recruiter: boolean;

  constructor(
    private metadataService: MetadataService,
    private userService: UserService,
  ) {
    this.changed = new EventEmitter();
    // this.filters = this.filters || [
    //   'industry',
    //   'company',
    //   'function',
    //   'location',
    //   'school',
    // ];

    this.filterForm = new FormGroup({
      industryControl: new FormControl(),
      companyControl: new FormControl(),
      functionControl: new FormControl(),
      locationControl: new FormControl(),
      schoolControl: new FormControl(),
      memberTypeControl: new FormControl(),
      openToWorkControl: new FormControl({
        value: false,
      }),
    });
  }

  ngOnInit() {

    this.data = this.data || [];

    const self = this;

    this.recruiter = this.userService.verifyRole('recruiter');

    if (this.isVisible('industry')) {
      this.metadataService.get(BusinessNames.Industries).pipe(first()).subscribe(
        (metaData) => {
          self.industryList = metaData;
          self.filteredIndustries = self.getObservableList('industryControl', self.industryList);
        }
      );
    }

    if (this.isVisible('company')) {

      if (this.companyType === 'list') {
        this.metadataService.get(BusinessNames.Companies).pipe(first()).subscribe(
          (metaData) => {
            self.companyList = metaData;
            self.filteredCompanies = self.getObservableList('companyControl', self.companyList);
          });
      } else {
        this.filteredCompanies = this.filterForm.controls['companyControl'].valueChanges
          .pipe(
            debounceTime(200),
            startWith(null),
            tap(item => item && this.metadataService.findCompanies(item?.name || item)?.pipe(first()).subscribe(
              (metaData) => {
                self.companyList = metaData.data;
                self.companyList.push(<any>{
                  name: item.name || capitalize(item),
                });
              })
            ),
            delay(300),
            map(item => {
              if (!item) {
                self.companyList = [];
              }
              return self.companyList;
            })
          );
      }

    }

    if (this.isVisible('school')) {
      this.metadataService.get(BusinessNames.Schools).pipe(first()).subscribe(
        (metaData) => {
          self.schoolList = metaData;
          self.filteredSchools = self.getObservableList('schoolControl', self.schoolList);
        });
    }

    if (this.isVisible('function')) {
      this.metadataService.get(BusinessNames.FunctionalAreas).pipe(first()).subscribe(
        (metaData) => {
          self.functionList = metaData;
          self.filteredFunctions = self.getObservableList('functionControl', self.functionList);
        }
      );
    }

    if (this.isVisible('location')) {
      this.filteredLocations = this.filterForm.controls['locationControl'].valueChanges
        .pipe(
          debounceTime(200),
          startWith(null),
          tap(item => item && this.metadataService.findLocations(item).pipe(first()).subscribe(
            (metaData) => {
              self.locationList = metaData.data;
            })
          ),
          delay(300),
          map(item => item ? this.locationList : [])
        );
    }

    if (this.isVisible('memberType')) {
      self.filteredMemberTypes = self.getObservableList('memberTypeControl', self.memberTypeList);
    }

  }

  getObservableList(controlName: string, list: Array<any>) {
    // const items = list[0].name ? map(list, 'name') : list;
    const control = this.filterForm.controls[controlName];
    return control ?
      control.valueChanges
        .pipe(
          startWith(null),
          map(item => this.filterList(list, item))
        ) : null;

  }

  filterList(list: Array<any>, val: string): Array<any> {
    return val ? filter(list, item => item && (item?.name || item)?.toLowerCase().includes(val.toString().toLowerCase()))
      : list;
  }

  setToNull(): undefined {
    return undefined;
  }

  isVisible(filterBox: string): boolean {
    return !this.filters || this.filters.includes(filterBox);
  }

  public select(list: Array<any>, item: any, prop?: string): void {

    const itemProp = item.type || prop;
    if (!list.find(_item => _item.type === itemProp && _item.name === item.name)) {
      item.type = itemProp;
      if (!this.multiple && list?.length > 0) {
        const current = find(list, ['type', itemProp]);
        if (current) {
          list.splice(list.indexOf(current), 1);
        }
      }
      list.push(item);
    }

    if (prop) this[itemProp] = null;
    this[`${ itemProp }Control`].nativeElement?.blur();
    this.changed.emit(list);
  }

  public removeSelected(list: Array<any>, item: any, prop?: string): void {
    const idx = list.indexOf(item);
    if (idx > -1) {
      list.splice(idx, 1);
    }

    this[item.type] = null;
    // this.filterForm.controls[`${prop}Control`].markAsTouched();
    this.changed.emit(list);
  }

}
