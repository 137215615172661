import { NgModule } from '@angular/core';

import { ExternalModules } from '../../../external';
import { TranslationModule } from '../../../translation';
import { DirectivesModule } from '../../directives';

// import { AuthenticatedLayoutRoutingModule } from './authenticated-layout-routing.module';

import { HeaderComponent } from './header';
import { FooterComponent } from './footer';
import { SociateComponent } from './sidenav';

@NgModule({
  imports: [
    ...ExternalModules,
    TranslationModule,
    DirectivesModule,
    // AuthenticatedLayoutRoutingModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    SociateComponent,
  ],
  exports: [
  ],
})

export class AuthenticatedLayoutModule { }
