<mat-card *ngIf="visible" [ngClass]="'post-'+data.postId">
  <mat-card-header *ngIf="data.lastActivityType" class="breadcrum">
    <mat-card-title>
      <span class="name">{{data.lastActivityByUser.firstname}}&nbsp;{{data.lastActivityByUser.lastname}}</span>
      <span *ngIf="data.lastActivityType === 'comment'"> commented</span>
      <!-- <span *ngIf="data.commented && data.liked"> &amp;</span> -->
      <span *ngIf="data.lastActivityType === 'flag'"> liked</span>
      <span> this</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-header *ngIf="data.author">
    <div mat-card-avatar class="header-image">
      <img appDefault mat-card-avatar [routerLink]="[data.author.profileUrl]" (mouseenter)="openUserDetails($event)"
        (mouseleave)="closeUserDetails($event)" src="{{data.author.picture}}">
    </div>
    <mat-card-title>
      <span class="name router-link underline-hover" (mouseenter)="openUserDetails($event)"
        (mouseleave)="closeUserDetails($event)"
        [routerLink]="[data.author.profileUrl]">{{data.author.firstname}}&nbsp;{{data.author.lastname}}</span>
      <span *ngIf="data.author.schools" class="university">{{data.author.schools[0]}}</span>
      <span *ngIf="data.author.graduating" class="year">'{{data.author.graduating.toString().substring(2)}}</span>
      <span *ngIf="!data.author.graduating && data.author.enrollment"
        class="year">'{{data.author.enrollment.toString().substring(2)}}</span>
    </mat-card-title>
    <mat-card-subtitle *ngIf="data.author.profession">
      <span class="work">{{ data.author.profession.title }} @ {{ data.author.profession.company }}</span>
    </mat-card-subtitle>
    <mat-card-subtitle>
      <span class="work">{{ createdDate }}</span>
    </mat-card-subtitle>
    <div class="side-menu">
      <!-- <div class="time-ago">{{createdDate}}</div> -->
      <div class="sub-menu" *ngIf="data.editable">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon matTooltip="{{ 'TOOLTIP_MORE' | translate }}">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="context-menu">
          <button mat-menu-item (click)="editPost()">
            <mat-icon color="primary">edit</mat-icon>
            <span>Edit Post</span>
          </button>
          <button mat-menu-item (click)="deletePost()">
            <mat-icon color="warn">delete</mat-icon>
            <span>Delete Post</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="column" fxFlex class="post-container">
      <div [appReadMore]="data.content" maxHeight="150" mode="formatted"></div>
      <div class="tags" *ngIf="tags?.length > 0">
        <span *ngFor="let tag of tags" (click)="selectTag(tag)">#{{tag.code | titlecase}}</span>
      </div>
      <div class="link-container" *ngIf="data.preview?.image && data.preview?.title">
        <a href="{{data.preview.url}}" target="_blank">
          <div class="image">
            <img src="{{data.preview.image}}" />
          </div>
          <div class="title">
            <!-- {{data.preview.title}} -->
            <span *ngIf="data.preview.title.length > 100; else title">{{data.preview.title.substring(0,97)}}...</span>
            <ng-template #title>
              <span [innerHTML]="data.preview.title"></span>
            </ng-template>
          </div>
          <div class="description" [innerHTML]="data.preview.description"> </div>
        </a>
      </div>
      <div *ngIf="data.attachments">
        <app-thumb-preview [thumbs]="data.attachments"></app-thumb-preview>
      </div>
      <div class="footer" *ngIf="data.likes?.length > 0 || data.commentCount > 0">
        <!-- <span class="likes" *ngIf="data.likes?.length == 1">{{ data.likes?.length }} Likes</span> -->
        <span class="likes underline-hover" *ngIf="data.likes?.length > 0" (click)="openView()">{{ data.likes.length }}
          Likes</span>
        <span class="comments" *ngIf="data.commentCount" (click)="isCommentSectionExpanded = !isCommentSectionExpanded">{{
          data.commentCount }} Comments</span>
      </div>
    </div>

  </mat-card-content>
  <!-- <mat-card-content class="mar-bot-0">
    <mat-chip-list class="tags">
      <mat-chip class="tag" (click)="tagSelected.emit(tag)" *ngFor="let tag of tags">{{tag.name}}</mat-chip>
    </mat-chip-list>
  </mat-card-content> -->

  <div class="actions">
    <mat-card-actions>
      <button mat-button (click)="likePost(!data.liked)">
        <mat-icon matPrefix [color]="data.liked ? 'primary': ''">thumb_up</mat-icon>
        <!-- <mat-icon matPrefix>thumb_up</mat-icon> -->
        <span>Like&nbsp;</span>
        <!-- <span class="action-count" *ngIf="data.likes?.length > 0">({{ data.likes?.length }})</span> -->
      </button>
      <button mat-button (click)="isCommentSectionExpanded = !isCommentSectionExpanded">
        <!-- <mat-icon matPrefix [color]="data.commented ? 'warn': ''">comment</mat-icon> -->
        <mat-icon matPrefix>comment</mat-icon>
        <span>Comment&nbsp;</span>
        <!-- <span class="action-count" *ngIf="data.commentCount > 0">({{ data.commentCount }})</span> -->
      </button>
      <button mat-button class="comment-show-hide" (click)="isCommentSectionExpanded = !isCommentSectionExpanded"
        *ngIf="data.commentCount > 0">
        <mat-icon [class.expanded]="isCommentSectionExpanded">arrow_forward_ios</mat-icon>
        <!-- <i [ngClass]="isCommentSectionExpanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'" aria-hidden="true"></i> -->
      </button>
    </mat-card-actions>
  </div>

  <mat-card-content *ngIf="isCommentSectionExpanded && data.commentCount > 0" class="comment-container">

    <div class="flex-container previous-comment-container">
      <div *ngIf="data.commentCount > data.comments.length" class="flex-2 link" (click)="getComments()">View Previous
        Comments</div>
      <div class="flex-1 text-right">{{data.comments.length}} of {{data.commentCount}}</div>
    </div>

    <!-- Comments for this post -->
    <div *ngIf="!hideComments">
      <div *ngFor="let comment of data.comments">
        <app-comment [data]="comment"></app-comment>
      </div>
    </div>

  </mat-card-content>

  <div class="curtain" *ngIf="posting" (click)="posting=!posting"></div>

  <mat-card-content *ngIf="isCommentSectionExpanded" class="add-comment-container">

    <!-- Add new comment -->
    <div *ngIf="!hideAddComments" ng2FileDrop class="add-comment-row flex-container drop-zone"
      [class.drop-zone-over]="hasDropZoneOver" [uploader]="uploader" (fileOver)="fileOverHandler($event)">

      <div mat-card-avatar class="header-image">
        <img appDefault mat-card-avatar src="{{currentUser.picture}}">
      </div>
      <div fxLayout="column" class="comment-section">

        <div class="flex-1">
          <form [formGroup]="commentForm" #formDirective="ngForm" (submit)="addComment($event, formDirective)"
            novalidate autocomplete="off" [class.opacity-50]="loading">
            <div fxLayout="row" fxFill>
              <mat-form-field hideRequiredMarker>
                <input name="comment" placeholder="{{ 'PLACEHOLDER_COMMENT' | translate }}" type="text" required
                  matInput [readonly]="loading" [(ngModel)]="comment.content" formControlName="commentControl" />
              </mat-form-field>

              <div class="buttons" fxLayout="row" fxLayoutAlign="space-around center">
                <button mat-icon-button class="file-upload" type="button" (click)="commentFileInput.click()">
                  <mat-icon for="comment-file-input">attach_file</mat-icon>
                </button>
                <button type="submit" mat-icon-button color="primary" [disabled]="commentForm.invalid || loading">
                  <mat-icon>send</mat-icon>
                </button>
              </div>

              <div *ngIf="hasDropZoneOver" class="file-drop-curtain" fxLayout="column" fxLayoutAlign="center center">
                <span>Drag and drop your picture here</span>
              </div>
            </div>

          </form>
        </div>

        <div fxFlex>
          <app-thumb-preview [thumbs]="comment.attachments" [editable]="true"></app-thumb-preview>
        </div>
        <input #commentFileInput ng2FileSelect multiple [uploader]="uploader" type="file" [accept]="supportedFormats"
          change="fileChangeListener($event)">

      </div>

    </div>
  </mat-card-content>

</mat-card>