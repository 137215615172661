import { IMessage } from '../../../../../interfaces';

export class Message implements IMessage {
    public fromId: any;
    public toId: any;
    public id: number;
    public message: string;
    public created: number;
    public seenOn?: number;
}
