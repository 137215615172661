<mat-card [ngClass]="'opp-'+data.opportunityId">
  <mat-card-header class="company" *ngIf="data.company">
    <div mat-card-avatar class="header-image">
      <a target="_blank" href="http://{{data.company.domain || data.company}}"><img mat-card-avatar
          src="{{ data.company.logo || '/assets/img/company-logo.png' }}"></a>
    </div>
    <mat-card-title>
      <span class="name" *ngIf="data.title?.length <= 23">{{data.title}}</span>
      <span class="name" *ngIf="data.title?.length > 23" matTooltip="{{ data.title }}">{{ data.title.substring(0, 20)
        }}...</span>
    </mat-card-title>
    <mat-card-subtitle>
      <a target="_blank" href="http://{{data.company.domain || data.company}}"><span class="company-name">{{
          data.company.name || data.company }}</span></a>
    </mat-card-subtitle>
    <mat-card-subtitle>
      <span class="work">{{ data.location }}</span>
    </mat-card-subtitle>
    <mat-card-subtitle>
      <span class="work">{{ createdDate }}</span>
    </mat-card-subtitle>
    <div class="side-menu">
      <!-- <div class="time-ago">{{createdDate}}</div> -->
      <div class="sub-menu" *ngIf="data.editable">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon matTooltip="{{ 'TOOLTIP_MORE' | translate }}">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="context-menu">
          <button mat-menu-item (click)="editOpportunity()">
            <mat-icon color="primary">edit</mat-icon>
            <span>Edit Job</span>
          </button>
          <button mat-menu-item (click)="deleteOpportunity()">
            <mat-icon color="warn">delete</mat-icon>
            <span>Delete Job</span>
          </button>
        </mat-menu>
      </div>
      <div class="sponsored" *ngIf="data.sponsored">
        <mat-icon matTooltip="{{ 'SPONSORED' | translate }}">policy</mat-icon>
      </div>
    </div>
  </mat-card-header>
  <mat-card-header class="author" *ngIf="data.author">
    <div mat-card-avatar class="header-image">
      <img appDefault mat-card-avatar [routerLink]="[data.author.profileUrl]" (mouseenter)="openUserDetails($event)"
        (mouseleave)="closeUserDetails($event)" src="{{data.author.picture}}">
    </div>
    <mat-card-title>
      <span class="name router-link underline-hover" (mouseenter)="openUserDetails($event)"
        (mouseleave)="closeUserDetails($event)" [routerLink]="[data.author.profileUrl]">
        {{author}}
      </span>
      <span *ngIf="data.author.schools" class="university" appMaxLength="10" content="{{data.author.schools[0]}}"
        matTooltip="{{data.author.schools[0]}}"></span>
      <span *ngIf="data.author.graduating" class="year">'{{data.author.graduating.toString().substring(2)}}</span>
      <span *ngIf="!data.author.graduating && data.author.enrollment"
        class="year">'{{data.author.enrollment.toString().substring(2)}}</span>
    </mat-card-title>
    <mat-card-subtitle *ngIf="profession">
      <span class="work" appMaxLength="{{ author.length > 15? 35 : 0 }}" [content]="profession"
        [matTooltip]="profession"></span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="column" fxFlex class="opportunity-container" [class.extended]="!data.associatedSociatesCount">
      <p [appReadMore]="data.description" maxLength="150">
        <!-- <span class="content" *ngIf="(data.description.length > 150 && isOpportunityTextExpanded === false)"
          [innerHTML]="data.description.substring(0,147)"></span>
        <span class="content" *ngIf="(data.description.length <= 150 || isOpportunityTextExpanded !== false)"
          [innerHTML]="data.description"></span>
        <span class="more-less-button" *ngIf="(data.description.length > 150 && isOpportunityTextExpanded === false)"
          (click)="isOpportunityTextExpanded = true">... see more</span> -->
      </p>
      <div class="tags" *ngIf="tags?.length > 0">
        <span *ngFor="let tag of tags" (click)="selectTag(tag)">#{{tag.code | titlecase}}</span>
      </div>
      <div *ngIf="data.link" class="job-link">
        <a href="{{data.link}}" target="_blank">
          Job link
        </a>
      </div>
      <div fxFlex *ngIf="data.attachments">
        <app-thumb-preview [thumbs]="data.attachments"></app-thumb-preview>
      </div>
    </div>

  </mat-card-content>
  <!-- <mat-card-content class="mar-bot-0">
    <mat-chip-list class="tags">
      <mat-chip class="tag" (click)="tagSelected.emit(tag)" *ngFor="let tag of tags">{{tag.name}}</mat-chip>
    </mat-chip-list>
  </mat-card-content> -->

  <mat-card-footer class="associated-sociates" *ngIf="data.associatedSociatesCount > 0" fxLayoutAlign="start center">

    <div *ngFor="let sociate of data.associatedSociates">
      <div mat-card-avatar class="header-image">
        <img appDefault mat-card-avatar [routerLink]="['/users', sociate.userId]" src="{{sociate.picture}}"
          matTooltip="{{sociate.firstname+' '+sociate.lastname}}" tooltip-position="below">
      </div>
    </div>

    <div class="sociates-count" [routerLink]="['/hub/sociates']"
      [queryParams]="{cp: data.company.domain || data.company }">{{
      data.associatedSociatesCount }} Sociates work here</div>

  </mat-card-footer>

</mat-card>