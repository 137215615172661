import { Injectable, OnInit, EventEmitter } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { serialize, deserialize } from 'bson';
import { has, get, values, each } from 'lodash-es';
// import { pako } from 'pako';

@Injectable({
  providedIn: 'root',
})
export class StorageService implements OnInit {

  private static _contextKey = '__st';
  private static _observers: Object = {};
  private _instance: Object;

  /**
   * Constructor of the class.
   *
   * @param {LocalStorageService} localStorage
   */
  constructor(
    private localStorage?: LocalStorageService,
    // private utils?: UtilityService,
  ) {
    this._instance = this.localStorage.retrieve(StorageService._contextKey) || {};
  }

  ngOnInit(): void {
  }

  private pKey(key: string): string {
    return window.btoa(key);
  }

  // private compress(data: any): string {
  //   return pako.deflate(JSON.stringify(data), { to: 'string' });
  // }

  // private decompress(compressed: string): any {
  //   return JSON.parse(pako.inflate(compressed, { to: 'string' }));
  // }

  public save(key: string, value: any): void {
    const _key = this.pKey(key);
    this._instance[_key] = serialize([value]).join('|');
    // this._instance[_key] = this.compress(value);
    this.localStorage.store(StorageService._contextKey, this._instance);
    if (has(StorageService._observers, key)) {
      return (<EventEmitter<any>>get(StorageService._observers, key)).emit(value);
    }
  }

  public get(key: string): any {
    const _key = this.pKey(key);
    return this.has(key) && typeof this._instance[_key] === 'string' ? deserialize(new Uint8Array(this._instance[_key].split('|').map(Number)))[0] : null;
    // return this.has(key) ? this.decompress(this._instance[_key]) : null;
  }

  public delete(key: string): void {
    this.save(key, null);
  }

  public clear(): void {
    this._instance = {};
    this.localStorage.clear(StorageService._contextKey);
    each(<Array<any>>values(StorageService._observers), (observer: EventEmitter<any>) => {
      observer.emit(null);
    });
  }

  public has(key): boolean {
    const _key = this.pKey(key);
    return has(this._instance, _key);
  }

  public observe(key: string): EventEmitter<any> {
    if (has(StorageService._observers, key)) {
      return get(StorageService._observers, key);
    }
    return StorageService._observers[key] = new EventEmitter();
  }

}
