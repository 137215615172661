<div class="opportunity-container">
  <form [formGroup]="opportunityForm" #formDirective="ngForm" (submit)="saveOpportunity($event, formDirective)" novalidate autocomplete="off"
    [class.opacity-50]="loading">

    <div class="opportunity-content" fxFill>
      <div fxLayout="column" fxFlex>
        <div fxLayout="row">
          <div mat-card-avatar class="logo">
            <img mat-card-avatar src="{{company.logo}}">
          </div>
          <div fxLayout="column" class="content">
            <mat-form-field hideRequiredMarker>
              <input name="title" placeholder="{{ 'PLACEHOLDER_TITLE' | translate }}" type="text" required matInput
                formControlName="titleControl" [(ngModel)]="title" />
            </mat-form-field>

            <!-- Company Name -->
            <mat-form-field hideRequiredMarker>
              <input name="company" placeholder="{{ 'PLACEHOLDER_COMPANY' | translate }}" type="text" required matInput
                [matAutocomplete]="autoCompany" [ngModel]="company" (ngModelChange)="setCompany($event)"
                formControlName="companyControl" />
            </mat-form-field>

            <mat-autocomplete #autoCompany="matAutocomplete" [displayWith]="getCompanyName">
              <div class="company-autocomplete">
                <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
                  <div class="item">
                    <span class="logo"><img src="{{ company.logo }}"></span>
                    <span class="name">{{ company.name }}</span>
                    <span class="domain">{{ company.domain }}</span>
                  </div>
                </mat-option>
              </div>
            </mat-autocomplete>
            <!-- End -->

            <mat-form-field hideRequiredMarker>
              <input name="location" placeholder="{{ 'PLACEHOLDER_LOCATION' | translate }}" type="text" required
                matInput [readonly]="loading" [matAutocomplete]="autoLocation" [(ngModel)]="location"
                formControlName="locationControl" />
            </mat-form-field>

            <mat-autocomplete #autoLocation="matAutocomplete">
              <mat-option *ngFor="let location of filteredLocations | async" [value]="location">
                {{ location }}
              </mat-option>
            </mat-autocomplete>

          </div>
          <div class="close">
            <div>
              <mat-icon class="icon-button" [class.disabled]="loading" (click)="closeDialog()"
                matTooltip="{{ 'TOOLTIP_CLOSE' | translate }}">close</mat-icon>
            </div>
          </div>
        </div>
        <!-- <mat-card-header>
            <div mat-card-avatar class="profile">
              <img appDefault mat-card-avatar src="{{currentUser.picture}}">
            </div>
            <mat-card-title>
              <span class="name">{{currentUser.firstname}}&nbsp;{{currentUser.lastname}}</span>
            </mat-card-title>
            <mat-card-subtitle *ngIf="currentUser.profession">
              <span class="work">{{currentUser.profession.title}}, {{currentUser.profession.company}}</span>
            </mat-card-subtitle>
          </mat-card-header> -->
        <div fxLayout="row">
          <div fxFlex>
            <mat-form-field hideRequiredMarker>
              <textarea name="description" minRows="2" placeholder="{{ 'PLACEHOLDER_DESCRIPTION' | translate }}"
                required matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" matAutosizeMinRows="2"
                matAutosizeMaxRows="10" [(ngModel)]="description" formControlName="descriptionControl"></textarea>
            </mat-form-field>
            <mat-form-field hideRequiredMarker>
              <input name="link" placeholder="{{ 'PLACEHOLDER_LINK' | translate }}" type="url" matInput
                formControlName="linkControl" [(ngModel)]="link" />
            </mat-form-field>
            <mat-form-field hideRequiredMarker>
              <!-- <mat-icon matPrefix>local_offer</mat-icon> -->
              <span matPrefix class="hash-tag">#</span>
              <mat-select name="selectedTags" multiple placeholder="{{ 'PLACEHOLDER_NEW_POST_TAGS' | translate }}"
                required [(ngModel)]="selectedTags" formControlName="selectedTagsControl">
                <mat-optgroup label="Tags">
                  <mat-option *ngFor="let s of specializations" [value]="s.id">{{s.name}}</mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="schools">
              <mat-icon matPrefix>school</mat-icon>
              <mat-select #selectedSchoolsControl name="selectedSchools" multiple
                placeholder="{{ 'PLACEHOLDER_SCHOOL' | translate }}" required [disabled]="loading"
                [(ngModel)]="selectedSchools" formControlName="selectedSchoolsControl">
                <mat-optgroup label="Schools">
                  <mat-option *ngFor="let school of schools" [value]="school.id" (click)="selectSchool(school)">
                    {{school.name}}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="opportunity-footer" fxLayout="row" fxLayoutAlign="end" fxFill>
      <!-- <ng-container *ngIf="!isRecruiter">
        <mat-checkbox class="anonymous-container" name="isAnonymous" [(ngModel)]="isAnonymous"
          formControlName="isAnonymousControl">{{ 'PLACEHOLDER_NEW_POST_ANONYMOUS' | translate }}</mat-checkbox>
        <button mat-raised-button color="primary"
          [disabled]="opportunityForm.invalid || loading">{{ 'BUTTON_POST_LOCAL' | translate }}</button>
        <button mat-raised-button color="primary" (click)="isGlobal=true"
          [disabled]="opportunityForm.invalid || loading">{{ 'BUTTON_POST_GLOBAL' | translate }}</button>
      </ng-container> -->
      <ng-container>
        <!-- School Starts -->
        <!-- <mat-form-field class>
          <mat-icon matPrefix>school</mat-icon>
          <mat-select #schoolControl name="school" multiple placeholder="{{ 'PLACEHOLDER_SCHOOL' | translate }}"
            required [disabled]="loading" [(ngModel)]="school" formControlName="schoolControl">
            <mat-optgroup label="Schools">
              <mat-option *ngFor="let school of schools" [value]="school.id">
                {{school.name}}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field> -->
        <!-- School Ends -->
        <button mat-raised-button color="primary"
          [disabled]="opportunityForm.invalid || loading">{{ (opportunity? 'BUTTON_SAVE' : 'BUTTON_POST') | translate }}</button>
      </ng-container>
    </div>

  </form>

</div>