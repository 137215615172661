import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
// import { isArray, isString } from 'util';
import { find, filter, isString } from 'lodash-es';
import { startWith, map as rxMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  public getObservableList(control: AbstractControl, list: Array<any>) {
    // const items = list[0].name ? map(list, 'name') : list;
    return control ?
      control.valueChanges
        .pipe(
          startWith(null),
          rxMap(item => this.filterList(list, item))
        ) : null;

  }

  public filterList(list: Array<any>, val: string): Array<any> {
    return val ? filter(list, item => item && (item?.name || item)?.toLowerCase().includes(val.toString().toLowerCase()))
      : list;
  }

  public getItemId(value: any | Array<any>, list: Array<any>): string {
    if (value?.length > 0) {
      if (Array.isArray(value)) {
        value = value[0];
      }
      const item = find(list, ['name', value]);
      return item ? item.id : null;
    }
    return null;
  }

  // public selectOne(list: Array<string>, item: string, prop: string): void {
  //   if (list.length > 0) {
  //     list.splice(list.indexOf(item), 1);
  //   }
  //   list.push(item);
  //   this[prop] = null;
  //   this[`${prop}Control`].nativeElement.blur();
  //   this.validate(list, prop);
  // }

  public removeSelected(list: Array<string>, item: string, control: AbstractControl, validate: boolean = false): void {
    const idx = list.indexOf(item);
    if (idx > -1) {
      list.splice(idx, 1);
    }
    control.markAsTouched();
    if (validate) {
      this.validate(list, control);
    }
  }

  public validate(list: Array<string> | string, control: AbstractControl) {
    if (isString(list)) {
      list = list.length > 0 ? [list] : [];
    }

    if (list.length === 0) {
      control.setErrors({ required: true });
      control.markAsTouched();
    } else {
      control.setErrors(null);
    }
  }

}
