import { Component, OnInit, Inject, ElementRef, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { ClientService } from '../../../dependencies';

@Component({
  selector: 'app-scroll-top, [appScrollTop]',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent implements OnInit {

  @Input() appScrollTop: number;
  windowScrolled: boolean;

  constructor(
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private clientService: ClientService,
  ) { }

  ngOnInit() {
    this.appScrollTop = this.clientService.device === 'mobile' ? -1 : this.appScrollTop || 250;
  }

  scrollToTop(): void {
    let el = this.elementRef.nativeElement;
    (function smoothscroll() {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || el.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        if (window.pageXOffset > 0) {
          el = window;
        } else if (document.documentElement.scrollTop > 0) {
          el = document.documentElement;
        } else if (document.body.scrollTop > 0) {
          el = document.body;
        }

        el.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

}
