import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { first } from 'rxjs/operators';

import { UserService, RouterService } from '../services';

/**
 * This class implements a guard for routes that require that user is not authenticated.
 */
@Injectable({
  providedIn: 'root',
})
export class AnonymousGuard implements CanActivate {
  /**
   * Constructor of the class.
   *
   * @param {UserService} userService
   * @param {Router}      router
   */
  constructor(
    private userService: UserService,
    private routerService: RouterService,
    // private router: Router,
    // private configService: ConfigService,
    // private activatedRoute: ActivatedRoute,
  ) { }

  /**
   * Purpose of this guard is check that current user has not been authenticated to application. If user is
   * authenticated he/she is redirected to profile page.
   *
   * @param {ActivatedRouteSnapshot}  route
   * @param {RouterStateSnapshot}     state
   * @returns {Observable<boolean>}
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const self = this;
    return new Observable((observer: Observer<boolean>) => {
      const profile = this.userService.profile;

      if (window.location.href.search('[/?&]redirect=') > 0 || !profile) {
        this.userService.erase();
        observer.next(true);
      } else {
        this.routerService.navigate(profile); // .pipe(first()).subscribe((result) => {
        // observer.next(result);
        observer.next(true);
        // });
      }
      observer.complete();
    });
  }
}
