<div id="chat" *ngIf="isBootstrapped" class="chat-container">

  <!-- Main window -->
  <div id="chat-people" [class.chat-people-collapsed]="isCollapsed">
    <a href="javascript:void(0);" class="chat-title shadowed {{ connectionStatusName[connectionStatus] | lowercase }}"
      (click)="onChatTitleClicked($event)">
      <span> {{localization.title}} </span>
    </a>
    <ng-template #connecting>
      <div [ngClass]="connectionStatusName[connectionStatus] | lowercase">
        <mat-icon matPrefix (click)="connectionStatus==0 && chatService.connect()">offline_bolt</mat-icon>
        <div>{{connectionStatusName[connectionStatus]}}</div>
      </div>
    </ng-template>
    <div class="connected" *ngIf="connectionStatus > 0; else connecting">
      <input *ngIf="searchEnabled" id="chat-search_friend" type="search" [placeholder]="localization.searchPlaceholder"
        [(ngModel)]="searchInput" />
      <ul id="chat-users" *ngIf="!isCollapsed" [class.offset-search]="searchEnabled">

        <div *ngIf="filteredChats?.length > 0" class="text-center" style="background-color:aliceblue">
          <span layout="row">
            <hr flex />Recents
            <hr flex />
          </span>
        </div>
        <li *ngFor="let user of filteredChats; let lastItem = last;" (click)="openChatWindow(user, true, true)">
          <img appDefault alt="" height="30" width="30" src="{{user.picture}}" [ngClass]="user.displayStatus" />
          <strong title="{{user.displayName}}">{{user.displayName}}</strong>
          <span class="chat-user-status {{user.displayStatus}}"
            title="{{getStatusTitle(user.displayStatus)}}"></span>
          <span *ngIf="unreadMessagesTotalByUser(user).length > 0"
            class="chat-unread-messages-count">{{unreadMessagesTotalByUser(user)}}</span>
        </li>

        <div *ngIf="filteredUsers?.length > 0" class="text-center" style="background-color:aliceblue">
          <span layout="row">
            <hr flex />Bookmarks
            <hr flex />
          </span>
        </div>
        <li *ngFor="let user of filteredUsers" (click)="openChatWindow(user, true, true)">
          <img appDefault alt="" [ngClass]="getStatusTitle(user.displayStatus)" height="30" width="30"
            src="{{user.picture}}" />
          <strong title="{{user.displayName}}">{{user.displayName}}</strong>
          <span class="chat-user-status {{user.displayStatus}}"
            title="{{getStatusTitle(user.displayStatus)}}"></span>
        </li>
      </ul>
    </div>
  </div>

  <!-- Chat windows -->
  <div [hidden]="connectionStatus <= 0" *ngFor="let window of windows; let i = index"
    [ngClass]="{'chat-window': true, 'chat-window-collapsed': window.isCollapsed}"
    [ngStyle]="{'right': friendsListWidth + 20 + windowSizeFactor * i + 'px'}">
    <div class="chat-title" [ngClass]="window.chattingTo.displayStatus">
      <img appDefault alt="" height="20" width="20" src="{{window.chattingTo.picture}}"
        [ngClass]="window.chattingTo.displayStatus" />
      <strong title="{{window.chattingTo.displayName}}">
        {{window.chattingTo.displayName}}
      </strong>
      <span
        [ngClass]="{'chat-user-status': true, 'online': window.chattingTo.status == UserStatus.Online, 'busy': window.chattingTo.status == UserStatus.Busy, 'away': window.chattingTo.status == UserStatus.Away, 'offline': window.chattingTo.status == UserStatus.Offline}"
        title="{{getStatusTitle(window.chattingTo.displayStatus)}}"></span>
      <span class="chat-action">
        <mat-icon *ngIf="!window.isCollapsed" (click)="onChatWindowClicked(window)"
          matTooltip="{{ 'TOOLTIP_MINIMIZE' | translate }}">minimize
        </mat-icon>
        <mat-icon *ngIf="window.isCollapsed" (click)="onChatWindowClicked(window)"
          matTooltip="{{ 'TOOLTIP_RESTORE' | translate }}">maximize
        </mat-icon>
        <mat-icon (click)="onCloseChatWindow(window)" matTooltip="{{ 'TOOLTIP_CLOSE' | translate }}">close</mat-icon>
      </span>
    </div>
    <ng-container *ngIf="!window.isCollapsed">
      <div #chatMessages class="chat-messages">
        <div *ngIf="window.isLoadingHistory" class="chat-loading-wrapper">
          <div class="loader">Loading history...</div>
        </div>

        <div class="load-more-container" *ngIf="window.pageToken > 0">
          <!-- TODO: Finite or Infinite subscribe? Need in HTML? -->
          <button mat-raised-button (click)="loadMoreHistory(window)">Load More</button>
        </div>

        <div *ngFor="let message of window.messages; index as i; first as isFirst"
          [ngClass]="{'chat-message': true, 'chat-message-received': message.fromId != userId}">
          <app-divider *ngIf="displayDate(message, isFirst? null: window.messages[i-1])" font-size="11px"
            data="{{ message.created * 1000 | date:'mediumDate' }}">
          </app-divider>
          <img appDefault *ngIf="window.chattingTo.picture && isAvatarVisible(window, message, i)" alt="" class="avatar"
            height="30" width="30" [src]="window.chattingTo.picture" />
          <span class="content" [innerHtml]="message.message | emojify:emojisEnabled | linkfy:linkfyEnabled"
            matTooltip="{{ message.created * 1000 | date:'MMMM d, y \'at\' h:mm a' }}"></span>
        </div>
      </div>
      <div class="chat-input">
        <textarea matInput cdkTextareaAutosize #chatWindowInput cdkAutosizeMinRows="2" cdkAutosizeMaxRows="8"
          [ngModel]="window.newMessage | emojify:emojisEnabled" (ngModelChange)="window.newMessage=$event" type="text"
          (keydown)="onChatInputTyped($event, window)" [placeholder]="localization.messagePlaceholder"
          (blur)="toggleWindowFocus(window)" (focus)="toggleWindowFocus(window)"></textarea>
      </div>
    </ng-container>
  </div>

</div>