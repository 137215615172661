import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as StackTrace from 'stacktrace-js';
import { first } from 'rxjs/operators';
import * as moment from 'moment';

import { ConfigService } from './config.service';
import { IResponse } from '../../../interfaces';
@Injectable({
  providedIn: 'root',
})
export class LogService {
  private debugEnabled: boolean;

  /**
  * Constructor of the class.
  *
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) {
    this.debugEnabled = isDevMode() || this.configService.isDebugMode;
  }

  public enableDebug(enable: boolean = true): void {
    this.debugEnabled = enable;
  }

  private log(level: string, message: string, metadata?: any, logToConsole: boolean = false): void {

    const local = isDevMode() && (/local/.test(document.location.host) || /127.0.0.1/.test(document.location.host));

    if (logToConsole || local) {
      console.log(`${ level }: ${ message } | ${ Array.isArray(metadata) ? '' : JSON.stringify(metadata) }`);
      if (metadata) {
        if (Array.isArray(metadata)) {
          console.table(metadata)
        } else if (metadata instanceof Object && JSON.stringify(metadata) !== '{}') {
          console.dir(metadata);
        }
      }
    }

    if (!local) {
      this.http
        .post(`${ this.configService.getApiUrl() }/log`, { level: level, message: message, metadata: metadata }).pipe(first())
        .subscribe(
          (response: IResponse) => { },
          (error: any) => {
            console.log('Failed to log event(s).');
            console.log(JSON.stringify(error));
          }
        );
    }
  }

  public debug(message: string, metadata: any = {}): void {
    if (!this.debugEnabled) { return };
    this.log('debug', message, metadata, true);
  }

  public info(message: string, metadata: any = {}): void {
    this.log('info', message, metadata);
  }

  public warn(message: string, metadata: any = {}): void {
    this.log('warn', message, metadata);
  }

  public error(message: string, metadata: any = {}): void {
    this.log('error', message, metadata);
  }

  public trace(prefix?: string, metadata?: any, stackTrace: boolean = false): void {
    if (!this.debugEnabled) { return };
    const stack: Array<any> = StackTrace.getSync();
    this.log('trace', `${ moment().format(moment.HTML5_FMT.DATETIME_LOCAL_MS) } | ${ prefix }: ${ stack[1].source }`, stackTrace ? {
      trace: stack,
      metadata: metadata
    } : metadata, true);
  }

}
