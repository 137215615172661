import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { UtilityService } from '../utilities';
import { ConfigService } from '../services';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class DecryptionInterceptor implements HttpInterceptor {

    constructor(
        private utils: UtilityService,
        private configService: ConfigService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const self = this;

        if (this.configService?.getApiUrl && !req.url.startsWith(this.configService?.getApiUrl())) {
            return next.handle(req);
        }

        const newReq = req.clone({
            responseType: 'text',
            setParams: this.configService?.isDebugMode ? { debug: this.configService?.debugToken } : {},
        });

        console.log(`trace: ${ moment().format(moment.HTML5_FMT.DATETIME_LOCAL_MS) } | Sending Request - ${ req.url }`);
        return next.handle(newReq)
            .pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        console.log(`trace: ${ moment().format(moment.HTML5_FMT.DATETIME_LOCAL_MS) } | Received Response - ${ event.url }`);
                        const data = (<HttpResponse<any>>event).body;
                        if (typeof data === 'string') {
                            const decryptedData = data.startsWith('{') && data.endsWith('}') ? data : self.utils.decrypt(data).data;
                            return event.clone({ body: JSON.parse(decryptedData) });
                        }
                    }
                    return event;
                })
            );

    }
}
