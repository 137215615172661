import { MessageService } from './message.service';
import { ImageService } from './image.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { RouterService } from './router.service';
import { ChatService } from './chat.service';
import { ProfileService } from './profile.service';

export * from './message.service';
export * from './image.service';
export * from './storage.service';
export * from './user.service';
export * from './router.service';
export * from './chat.service';
export * from './profile.service';

export const FunctionalServices = [
  // MessageService,
  // ImageService,
  // StorageService,
  // UserService,
  // RouterService,
  // ChatService,
  // ProfileService,
];
