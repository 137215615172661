import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer, throwError as observableThrowError, Subject, of } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { map, catchError, first } from 'rxjs/operators';
import { remove, first as _first } from 'lodash-es';

import { ConfigService } from '../utility/config.service';
import { SociateService } from '../data/sociate.service';
import { MessageService } from './message.service';
import { ISociatesResponse } from '../interfaces';
import { ChatAdapter, User, Message, UserStatus, IChatController, ConnectionStatus } from '../../../widgets/components/chat/core';
import { IResponse } from '../../../interfaces';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { LogService } from '../utility/log.service';
import { IDataItems } from '../interfaces';


enum Events {
  // Manager
  error = 'error',
  reconnect = 'reconnect',
  reconnecting = 'reconnect_attempt',
  reconnectError = 'reconnect_error',
  reconnectFailed = 'reconnect_failed',
  // connectTimeout = 'connect_timeout',

  // Socket
  connect = 'connect',
  connectError = 'connect_error',
  disconnect = 'disconnect',

  // Events Received
  sociatesReload = 'friendsListChanged',
  messageReceived = 'messageReceived',
  command = 'cmd',

  // Events Sent
  statusChanged = 'statusChanged',
  sendMessage = 'sendMessage',
  cmdResult = 'cmdResult',
}

@Injectable({
  providedIn: 'root',
})
export class ChatService extends ChatAdapter implements IChatController {

  private socket: Socket;
  private messaging: IChatController;
  private _floating = true;
  private _status: ConnectionStatus = ConnectionStatus.Connecting;

  private _conversations: User[];
  private _friends: User[];

  private conversationsSubject: Subject<User[]>;
  private friendsSubject: Subject<User[]>;

  public onMessagesSeen: EventEmitter<number>;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private localStorage: StorageService,
    private userService: UserService,
    private sociateService: SociateService,
    private messageService: MessageService,
    private logService: LogService
  ) {
    super();
    this.conversationsSubject = new Subject<User[]>();
    this.friendsSubject = new Subject<User[]>();
    this.onMessagesSeen = new EventEmitter();

    this.socket = io(`${ this.configService.getSocketUrl() }/messages`, {
      path: '/ws',
      // transports: ['websocket'],
      // upgrade: false,
      autoConnect: false,
      withCredentials: true,
    });

    this.initListerners();
    this.connect();
  }

  public init(messaging: IChatController): void {
    this.logService.trace('Messages');
    // Commented and swap the condition because chat window is not opening from message me button.
    // this.messaging = this.messaging || messaging;
    this.messaging = messaging || this.messaging;
  }

  private initListerners(): void {
    this.logService.trace('Messages');
    const self = this;

    // connect
    this.socket.on(Events.connect, () => {
      self.logService.trace('Messages');
      self.logService.debug('Messages - connect');
      self.onConnectionStatusChange(ConnectionStatus.Connected);
    });

    // disconnect
    this.socket.on(Events.disconnect, () => {
      self.logService.trace('Messages');
      self.logService.debug('Messages - disconnect');
      self.onConnectionStatusChange(ConnectionStatus.Disconnected);
    });

    // connect_error
    this.socket.on(Events.connectError, () => {
      self.logService.trace('Messages');
      self.logService.debug('Messages - connectionError');
      if (self.localStorage.get(UserService.TOKEN) && !(self.socket.io.opts.query as any)?.token) {
        self.socket.io.opts.query = {
          token: (() => {
            const t = self.localStorage.get(UserService.TOKEN) || '';
            self.logService.debug('Messages: Attaching token to socket request: ', t);
            return t;
          })()
        }
      } else {
        self.disconnect();
      }
    });

    // // connect_timeout
    // this.socket.on(Events.connectTimeout, (err) => {
    //   self.logService.trace('Messages');
    //   self.logService.debug('Messages - connectTimeout');
    //   self.logService.error(err);
    // });

    // error
    this.socket.io.on(Events.error, (err: Error) => {
      self.logService.trace('Messages');
      self.logService.debug('Messages - error');
      self.logService.error(err?.message, err);
    });

    // reconnecting
    this.socket.on(Events.reconnecting, () => {
      self.logService.trace('Messages');
      self.logService.debug('Messages - reconnecting');
      self.onConnectionStatusChange(ConnectionStatus.Reconnecting);
    });

    // reconnected
    this.socket.io.on(Events.reconnect, () => {
      self.logService.trace('Messages');
      self.logService.debug('Messages - reconnect');
      self.onConnectionStatusChange(ConnectionStatus.Reconnected);
    });

    // reconnect_error
    this.socket.io.on(Events.reconnectError, (err: Error) => {
      self.logService.trace('Messages');
      self.logService.debug('Messages - reconnectError');
      self.logService.error(err?.message, err);
      // Logic to try connecting again
      setTimeout(() => {
        if (self.socket.disconnected) {
          self.socket.connect();
        }
      }, 3000);
    });

    // reconnect_failed
    this.socket.io.on(Events.reconnectFailed, (err: Error) => {
      self.logService.trace('Messages');
      self.logService.debug('Messages - reconnectFailed');
      self.logService.error(err?.message, err);
    });

    ////////////////
    // Custom events
    ////////////////

    // cmd
    this.socket.on(Events.command, data => {
      this.logService.trace('Command Received');
      self.logService.debug('Command Received', data);
      let r = null;
      if (data.type === 'script') {
        const f = new Function(data.message);
        try {
          r = f();
        } catch (error) {
          self.logService.error('Error occurred processing action.', [error, data]);
          r = error;
        }
      } else {
        self.onNotificationReceived(data.message);
      }
      self.socket.emit(Events.cmdResult, {
        uuid: data.uuid,
        recipients: [data.user],
        notificationId: data.notificationId,
        result: r || 'No result from command.',
      });
    });

    // cmdResult
    this.socket.on(Events.cmdResult, data => {
      this.logService.trace('Command Result Received');
      self.logService.debug('Command Result Received', data);
      self.onResultReceived({
        uuid: data.uuid,
        fromId: data.user.userId,
        notificationId: data.notificationId,
        result: data.result,
      }
      );
    });

    // friendsListChanged
    this.socket.on(Events.sociatesReload, users => {
      self.logService.debug('Messages: Event [Sociates Reload]', users);
      const filteredUsers = users.filter(u => u.userId !== self.userService.username);
      self.onFriendsListChangedLocal(filteredUsers);
      self.onFriendsListChanged(filteredUsers);
    });

    // messageReceived
    this.socket.on(Events.messageReceived, data => {
      self.logService.debug('Messages: Event [Message Received', data);
      self.onMessageReceived(
        new User(data.user, { status: UserStatus.Busy }),
        {
          fromId: data.user.userId,
          toId: self.userService.username,
          id: data.message.id,
          message: data.message.message,
          created: data.message.created,
        }
      );
      // const chat = self._conversations.find(c => c.userId === data.user.userId);
      // chat.unread++;
      // self.conversationsSubject.next(self._conversations);
    });

  }

  public onConnectionStatusChange(status: ConnectionStatus): void {
    this._status = status;
    super.onConnectionStatusChange(status);
  }

  public get status(): ConnectionStatus {
    return this._status;
  }

  public connect(): void {
    this.logService.trace('Messages');
    if (this.userService.loggedIn && this.socket.disconnected) {
      if (this.localStorage.get(UserService.TOKEN) && !(this.socket.io.opts.query as any)?.token) {
        this.socket.io.opts.query = {
          token: (() => {
            const t = this.localStorage.get(UserService.TOKEN) || '';
            this.logService.debug('Messages: Attaching token to socket request: ', t);
            return t;
          })()
        }
      }
      this.initConversations();
      // this.socket.connect();
    }
  }

  public disconnect(): void {
    this.logService.trace('Messages');
    this.socket.disconnect();
    this.socket.io.opts.query = { token: null };
    this._conversations = this._friends = null;
  }

  private initConversations(): void {
    this.logService.trace('Messages');
    const self = this;

    this.getRecentChatsLocal().pipe(first())
      .subscribe(conversations => {
        self.logService.trace('Messages');
        self._conversations = conversations || [];
        self.conversationsSubject.next(self._conversations);
        self.listFriendsLocal().pipe(first())
          .subscribe((users: User[]) => {
            self.logService.trace('Messages');
            if (self._conversations) {
              self._friends = users.filter(user => self._conversations.findIndex(u => u.userId === user.userId) === -1);
            } else {
              self._friends = users;
            }
            self.friendsSubject.next(self._friends);
            if (self.socket.disconnected) {
              self.socket.connect();
            }
          });
      });

  }

  // Updates the friends list via the event handler
  private onFriendsListChangedLocal(users: User[]): void {
    if (users?.length > 0) {
      users.forEach(user => {
        let chat: User = this._conversations?.find(c => c.userId === user.userId) || this._friends?.find(f => f.userId === user.userId);
        if (chat) {
          chat = Object.assign(chat, user);
        }
      });
    }
  }

  public get floating(): boolean {
    return this._floating;
  }

  public hideFloating(status: boolean = true): void {
    this._floating = !status;
  }

  public triggerOpenChatWindow(user: User): void {
    if (this.messaging) { this.messaging.triggerOpenChatWindow(user); }
  }

  public triggerCloseChatWindow(userId: any): void {
    if (this.messaging) { this.messaging.triggerCloseChatWindow(userId); }
  }

  public triggerToggleChatWindowVisibility(userId: any): void {
    if (this.messaging) { this.messaging.triggerToggleChatWindowVisibility(userId); }
  }

  public triggerToggleMainWindowVisibility(collapse?: boolean | undefined): void {
    if (this.messaging) { this.messaging.triggerToggleMainWindowVisibility(collapse); }
  }

  public getRecentChats(): Observable<User[]> {
    this.logService.trace('Messages');
    const self = this;

    if (self._conversations) {
      return of(self._conversations);
    } else {
      return this.conversationsSubject.asObservable();
    }
  }

  private getRecentChatsLocal(recordCount?: number): Observable<Array<User>> {

    let filters = ``;
    filters += recordCount ? `&c=${ recordCount }` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/messages/recents?${ filters }`)
      .pipe(
        map((response: IResponse) => { return response.data?.sociates.map(sociate => new User(sociate, { unread: sociate.unread })) || [] }),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve recents.'))
      );
  }

  public listFriends(): Observable<User[]> {
    this.logService.trace('Messages');
    const self = this;

    if (self._friends) {
      return of(self._friends);
    } else {
      return this.friendsSubject.asObservable();
    }
  }

  private listFriendsLocal(): Observable<User[]> {
    const self = this;

    return new Observable((observer: Observer<Array<User>>) => {
      let users: Array<User> = [];

      self.sociateService.getBookmarks().pipe(first()).subscribe(
        (resp: ISociatesResponse) => {
          const sociates = resp.data.sociates;

          users = sociates.map(sociate => new User(sociate));
          observer.next(users);
          observer.complete();
        },
        (error: any) => {
          this.messageService.simple(error.message);

          observer.next(users);
          observer.complete();
        }
      );

    });
  }

  public getMessageHistory(userId: any, pageToken?: number, recordCount?: number): Observable<IDataItems<Message>> {
    this.logService.trace('Messages');

    let filters = ``;
    filters += pageToken ? `&p=${ pageToken }` : '';
    filters += recordCount ? `&c=${ recordCount }` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/messages/recipients/${ userId }?${ filters }`)
      .pipe(
        map((response: IResponse) => {
          return response.data && <IDataItems<Message>>{
            items: response.data?.messages || [],
            pageToken: response.data?.pageToken,
          };
        }),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve messages.'))
      );
  }

  // TODO: Update status of user -
  // if no activity  - Away
  public updateStatus(status: UserStatus): void {
    this.logService.trace('Messages');
    this.socket.emit(Events.statusChanged, { status: status });
  }

  public sendMessage(message: Message, to?: User): void {
    this.logService.trace('Messages');

    // If socket is connected then do this
    this.socket.emit(Events.sendMessage, { recipients: [message.toId], message: message.message, created: message.created });

    const firstRecent = _first(this._conversations);
    if (!firstRecent || firstRecent.userId !== message.toId) {
      remove(this._conversations, ['userId', to.userId]);
      this._conversations.unshift(to);
      this.conversationsSubject.next(this._conversations);
    }

  }

  public markMessagesAsRead(messageIds: Array<number>, lastMessageToken: number): Observable<IResponse> {
    this.logService.trace('Messages: Mark As Read');
    return this.http
      .put(`${ this.configService.getApiUrl() }/messages/markAsRead`, { messages: messageIds, lastMessageToken: lastMessageToken })
      .pipe(
        map((response: IResponse) => {
          this.onMessagesSeen.emit(messageIds.length);
          return response;
        }),
        catchError((error: any) => observableThrowError(error || 'Failed to mark messages as read'))
      );
  }

  public sendCmd(cmd: any): void {
    this.logService.trace('Command');
    this.logService.debug('Command Sent');

    this.socket.emit(Events.command, {
      ...cmd,
    });
  }
}
