import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-fixed',
  templateUrl: './fixed.component.html',
  styleUrls: ['./fixed.component.scss']
})
export class FixedComponent implements OnDestroy {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.document.documentElement.style.height = '100%';
    this.document.body.style.height = '100%';
  }

  ngOnDestroy() {
    this.document.documentElement.style.height = 'auto';
    this.document.body.style.height = 'auto';
  }

}

