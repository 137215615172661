import { Component, OnInit, HostListener, Input, ViewChild, ViewContainerRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { first } from 'rxjs/operators';

import { ISociate } from '../../../interfaces';
import { SociateService, ISociatesResponse } from '../../../dependencies';

@Component({
  selector: 'app-user-tags, [appUserTags]',
  templateUrl: './user-tags.component.html',
  styleUrls: ['./user-tags.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class UserTagsComponent implements OnInit, AfterViewInit {

  @ViewChild('userTagsContainer') container;
  @Input() appUserTags: string;

  sociates: Observable<Array<ISociate>>;
  keyPressObserver: Observer<Array<ISociate>>;
  active: boolean;
  criteria: string;

  constructor(
    private viewContainer: ViewContainerRef,
    private sociateService: SociateService,
  ) { }

  ngOnInit() {

    const self = this;
    this.appUserTags = this.appUserTags || '@';
    this.criteria = '';

    if (this.appUserTags.length > 1) {
      throw new Error(`Invalid value for appUserTags. Length cannot be greater than 1.`);
    }

    this.sociates = new Observable((observer: Observer<Array<ISociate>>) => {
      self.keyPressObserver = observer;
    });

  }

  ngAfterViewInit() {
    this.viewContainer.createEmbeddedView(this.container);
  }

  @HostListener('keypress', ['$event'])
  public onKeyPress(event): void {

    if (this.active) {
      // When Enter/Return is pressed
      if (event.keyCode === 13) {
        this.criteria = '';
        this.active = false;
      } else {
        this.criteria += event.key;
        const self = this;
        this.sociateService.find(this.criteria)
          .pipe(first())
          .subscribe(
            (response: ISociatesResponse) => {
              if (response.status.status === 'STA_SUCCESS') {
                self.keyPressObserver.next(response.data.sociates);
              }
            });
      }

    } else {
      this.active = event.key === this.appUserTags;
    }

  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event): void {

    if (this.active && event.keyCode === 8) {
      if (this.criteria.length === 0) {
        this.criteria = '';
        this.active = false;
      } else {
        this.criteria = this.criteria.substr(0, this.criteria.length - 1);
        if (this.criteria.length > 0) {
          const self = this;
          this.sociateService.find(this.criteria)
            .pipe(first())
            .subscribe(
              (response: ISociatesResponse) => {
                if (response.status.status === 'STA_SUCCESS') {
                  self.keyPressObserver.next(response.data.sociates);
                }
              });
        } else {
          this.keyPressObserver.next([]);
        }
      }

    }

  }

}
