import { Routes } from '@angular/router';

import { LoginComponent } from './login.component';
import { TranslationGuard, AnonymousGuard } from '../../../shared';

export const LoginRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [
      AnonymousGuard,
      TranslationGuard,
    ],
  },
];
