import { NgModule } from '@angular/core';

import { ListRequiredDirective } from './list-required.directive';
import { ScrollTrackerDirective } from './scroll-tracker.directive';
import { DefaultImageDirective } from './default-image.directive';
import { HtmlEditorDirective } from './html-editor.directive';
import { MaxLengthDirective } from './max-length.directive';
import { UserAgentDirective } from './user-agent-info.directive';
import { AutoFocusDirective } from './auto-focus.directive';

export const Directives = [
    ScrollTrackerDirective,
    ListRequiredDirective,
    DefaultImageDirective,
    HtmlEditorDirective,
    MaxLengthDirective,
    UserAgentDirective,
    AutoFocusDirective,
];

@NgModule({
    imports: [
    ],
    declarations: [
        ...Directives,
    ],
    exports: [
        ...Directives,
    ],
})

export class DirectivesModule { }
