import { NgModule, ErrorHandler } from '@angular/core';

import { ExternalModules } from '../external';
// import { Guards } from './guards';
// import { ErrorHandlerService, Services } from './services';
// import { Utilities } from './utilities';

@NgModule({
  declarations: [],
  imports: [
    ...ExternalModules,
  ],
  providers: [
    // {
    //   provide: 'Window',
    //   useValue: window,
    // },
    // {
    //   provide: ErrorHandler,
    //   useClass: ErrorHandlerService,
    // },
    // ...Guards,
    // ...Services,
    // ...Utilities,
  ]
})
export class DependenciesModule { }
