import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { find } from 'lodash-es';
import { first } from 'rxjs/operators';

import * as moment from 'moment';

import { IOpportunity, BusinessNames, IMetaData, IResponse } from '../../../interfaces';
import { OpportunityService, MetadataService, UserService, MessageService, OverlayDialogRef, OverlayDialogService, OverlayDialogConfig } from '../../../dependencies';
import { SociateComponent } from '../sociate';
import { AddOpportunityComponent } from '../add-opportunity';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class OpportunityComponent implements OnInit, OnDestroy {

  @Input() hideComments: boolean;
  @Input() hideAddComments: boolean;
  @Input() data: IOpportunity;
  @Output() dataChange: EventEmitter<IOpportunity>;
  @Input() size: 'small' | 'medium' | 'large';
  // @Input() visible: boolean;

  @Output() tagSelected: EventEmitter<IMetaData>;
  @Output() deleted: EventEmitter<IOpportunity>;

  commentForm: FormGroup;

  isCommentSectionExpanded: boolean;
  isOpportunityTextExpanded: boolean;

  specializations: IMetaData[];
  tags: IMetaData[];
  createdDate: string;
  comment: string;
  pageToken: number;

  author: string;
  profession: string;

  overlayRef: OverlayDialogRef;

  constructor(
    private opportunityService: OpportunityService,
    private metadataService: MetadataService,
    private userService: UserService,
    private dialog: MatDialog,
    private overlay: OverlayDialogService,
  ) {
    this.commentForm = new FormGroup({
      commentControl: new FormControl(),
    });

    this.dataChange = new EventEmitter<IOpportunity>();
    this.tagSelected = new EventEmitter<IMetaData>();
    this.deleted = new EventEmitter<IOpportunity>();

  }

  ngOnInit() {
    // this.visible = true;

    // if (!this.data || !this.data.content || this.data.content.trim().length === 0) {
    //   this.visible = false;
    //   return;
    // }

    const self = this;

    if (this.data) {
      // this.tags = this.getTagNames(this.data.tags);
      // moment.unix to be used for 10 digit unix timestamp
      this.createdDate = moment.unix(this.data.createdTimestamp).fromNow();

      this.author = this.data.author.displayName;
      this.profession = this.data.author.profession ? `${ this.data.author.profession.title } @ ${ this.data.author.profession.company }` : null;
      this.data.author.profileUrl = this.userService.username === this.data.authorId ? '/my' : `/users/${ this.data.author.userId }`;

      // if (this.data.link && !this.data.preview) {
      //   this.metadataService
      //     .generatePreview(this.data.link)
      //     .subscribe((res: IPreviewResponse) => {
      //       self.data.preview = res.data;
      //     });
      // }

      if (this.data.company && !this.data.company.name) {
        this.metadataService.findCompanies(this.data.company)
          .pipe(first())
          .subscribe(
            (metaData) => {
              self.data.company = metaData.data[0] || self.data.company;
            });
      }

      this.metadataService.get(BusinessNames.FunctionalAreas)
        .pipe(first())
        .subscribe(
          (metaData) => {
            self.specializations = metaData;
            self.tags = self.getTagNames(self.data.tags);
          }
        );

    }

    this.size = this.size || 'large';
    this.isOpportunityTextExpanded = false;

  }

  ngOnDestroy() {
    this.overlayRef?.close(true);
  }

  getTagNames(tagIds: string | string[]): Array<IMetaData> {
    if (!tagIds) { return [] };
    if (!Array.isArray(tagIds)) { tagIds = tagIds.split('|'); }

    const tags = [];
    tagIds.map((tagId) => {
      const tag = find(this.specializations, ['id', +tagId]);
      // const tag = this.specializations.find(spcl => spcl.id === +tagId);
      if (tag) {
        tags.push(tag);
      }
    })
    return tags;
  }

  selectTag(tag: IMetaData): void {
    this.tagSelected.emit(tag);
  }

  openUserDetails(event: any): void {
    this.overlayRef = this.overlay.open(SociateComponent, {
      parent: event.srcElement,
      data: this.data.author,
      config: {
        hasBackdrop: false,
      }
    } as OverlayDialogConfig);
  }

  closeUserDetails(): void {
    setTimeout((overlayRef) => {
      overlayRef.close();
    }, 100, this.overlayRef);
  }

  editOpportunity(): void {
    // Open edit post dialog
    const dialogRef = this.dialog.open(AddOpportunityComponent, {
      data: this.data,
      panelClass: 'soc-dialog',
      backdropClass: 'soc-dialog-backdrop',
    });
    dialogRef.afterClosed().pipe(first()).subscribe((result: IOpportunity) => {
      if (result) {
        this.dataChange.emit(
          {
            ...this.data,
            description: result.description,
            tags: result.tags,
            global: result.global,
            attachments: result.attachments,
          }
        );
      }
    });
  }

  deleteOpportunity(): void {

    this.opportunityService
      .delete(this.data.opportunityId)
      .pipe(first())
      .subscribe(
        (res: IResponse) => {
          if (res?.status.status === 'STA_SUCCESS') {
            this.deleted.emit(this.data);
          }
        }
      );
  }

}
