import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import * as URI from 'urijs';
import { get } from 'lodash-es';

import { ConfigService } from '../services/utility/config.service';

@Injectable({
  providedIn: 'root',
})
export class PathService {

  constructor(
    private configService: ConfigService,
  ) { }

  public resolveFileUrl(filename: string, folder: string = ''): string {
    if (!filename) {
      return filename;
    }

    const uri = URI(filename);
    const staticUrl = (this.configService.get().urls?.static || `static.${ document.location.host }`);

    if (uri.scheme().startsWith('s3.')) {
      return this.join(
        staticUrl,
        get(this.configService.get().prefixes, uri.scheme().replace('s3.', 'static.')),
        folder,
        uri.hostname()
      );
    } else if (uri.scheme() === 'public') {
      return this.join(
        staticUrl,
        get(this.configService.get().prefixes, `static.${ uri.scheme() }`),
        folder,
        uri.hostname()
      );
    } else if (uri.is('absolute')) {
      return filename;
    } else {
      return this.join(
        staticUrl,
        folder,
        filename
      );
    }

  }

  public resolveWebUrl(url: string): string {
    if (!url || url === '') { return url; }
    const uri = URI(url);
    if (uri.scheme() === '') { uri.scheme('http'); }
    return uri.href();
  }

  public join(...paths: Array<string>): string {
    let result = '';
    paths.forEach(path => result = Location.joinWithSlash(result, path));
    return result;
  }

  public fileName(path: string): string {
    return path ? URI(path).filename() : null;
  }

}
