import { Component, OnInit, Input } from '@angular/core';

import { ConfigService, ImageService } from '../../../dependencies';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss']
})
export class PlaceholderComponent implements OnInit {

  @Input() type: 'post' | 'sociate' | 'article';
  @Input() size: 'small' | 'medium' | 'large';
  defaultImage: string;

  constructor(
    private configService: ConfigService,
    private imageService: ImageService,

  ) {
    this.defaultImage = this.imageService.resolvePictureUrl(this.configService.defaults.account.picture);
    this.size = this.size || 'medium';
  }

  ngOnInit() {
  }

}
