import { Pipe, PipeTransform } from '@angular/core';

/*
 * Transforms text containing URLs or E-mails to valid links/mailtos
*/
@Pipe({ name: 'translateDefault' })
export class TranslateDefaultPipe implements PipeTransform {
    transform(message: string, key: string, defaultValue: string): string {
        if (message?.toLowerCase() === key?.toLowerCase() && defaultValue) {
            return defaultValue;
        }
        return message;
    }
}
