
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';

import { ConfigService } from '../utility/config.service';
import { ISearchResponse } from '../interfaces';


@Injectable({
  providedIn: 'root',
})
export class SearchService {

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  public find(criteria: string, recordCount?: number): Observable<ISearchResponse> {

    let filters = ``;
    filters += recordCount ? `&c=${recordCount}` : '';

    return this.http
      .get(`${this.configService.getApiUrl()}/search/${criteria}?${filters}`)
      .pipe(
        map((response: ISearchResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to find results.'))
      );
  }

}
