import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, HostListener, Inject } from '@angular/core';
import { FormGroup, FormControl, FormGroupDirective } from '@angular/forms';
import { debounceTime, startWith, tap, delay, map, first } from 'rxjs/operators';
import { filter, difference, map as _map } from 'lodash-es';

import { IOpportunity, BusinessNames, IMetaData, ISchool } from '../../../interfaces';
import { OpportunityService, MetadataService, UserService, IUser, IOpportunitiesResponse, MessageService, PathService } from '../../../dependencies';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-opportunity',
  templateUrl: './add-opportunity.component.html',
  styleUrls: ['./add-opportunity.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class AddOpportunityComponent implements OnInit {

  @Input() hideUpload: boolean;
  @Input() hideAnonymous: boolean;
  @Input() size: 'small' | 'medium' | 'large';
  @Input() visible: boolean;

  @Output() added: EventEmitter<IOpportunity>;
  @Output() closed: EventEmitter<void>;

  loading = false;

  opportunityForm: FormGroup;
  title: string;
  description: string;
  company: any;
  location: string;
  link: string;
  selectedTags: Array<any>;
  isAnonymous: boolean;
  // isGlobal: boolean;

  selectedSchools: Array<number>;
  public schools: Array<IMetaData> = [{
    id: 0,
    code: '',
    name: 'All',
  }];
  // public filteredSchools: any;
  isRecruiter = false;

  locationList: Array<any>;
  companyList: Array<any>;

  filteredLocations: any;
  filteredCompanies: any;

  specializations: Array<IMetaData>;
  currentUser: IUser;

  constructor(
    private opportunityService: OpportunityService,
    private metadataService: MetadataService,
    private messageService: MessageService,
    private userService: UserService,
    private pathService: PathService,
    @Inject(MAT_DIALOG_DATA) public opportunity: IOpportunity,
    private dialogRef: MatDialogRef<AddOpportunityComponent>,
  ) {

    this.opportunityForm = new FormGroup({
      titleControl: new FormControl(),
      companyControl: new FormControl(),
      locationControl: new FormControl(),
      descriptionControl: new FormControl(),
      linkControl: new FormControl(),
      selectedTagsControl: new FormControl(),
      isAnonymousControl: new FormControl(),
      selectedSchoolsControl: new FormControl(),
    });

    this.added = new EventEmitter<IOpportunity>();
    this.closed = new EventEmitter<void>();

  }

  ngOnInit() {
    const self = this;
    this.isRecruiter = this.userService.verifyRole('recruiter');
    this.visible = true;
    this.size = this.size || 'large';
    this.currentUser = this.userService.profile;
    
    this.title = this.opportunity?.title || '';
    this.description = this.opportunity?.description || '';
    this.company = this.opportunity?.company || {
      logo: '/assets/img/company-logo.png',
    };
    this.location = this.opportunity?.location || '';
    this.link = this.opportunity?.link || '';
    this.selectedTags = this.opportunity?.tags || [];
    this.selectedSchools = this.opportunity?.schools || [0];
    this.isAnonymous = this.opportunity?.anonymous || false;
    // this.isGlobal = false;

    this.metadataService
      .get(BusinessNames.FunctionalAreas).pipe(first())
      .subscribe(
        (metaData) => {
          self.specializations = metaData;
        }
      );

    this.metadataService.get(BusinessNames.Schools).pipe(first()).subscribe(
      (metaData) => {
        self.schools.push(...metaData);
        // const u_schools = self.currentUser.schools;
        // self.schools.forEach(s => {
        //   if (u_schools?.includes(s.name)) {
        //     self.selectedSchools.push(s.id);
        //   }
        // });
      }
    );

    this.filteredCompanies = this.opportunityForm.controls['companyControl'].valueChanges
      .pipe(
        debounceTime(200),
        startWith(null),
        tap(item => item && this.metadataService.findCompanies(item?.name || item)?.pipe(first()).subscribe(
          (metaData) => {
            this.companyList = metaData.data;
          })
        ),
        delay(300),
        map(item => item ? this.companyList : [])
      );

    this.filteredLocations = this.opportunityForm.controls['locationControl'].valueChanges
      .pipe(
        debounceTime(200),
        startWith(null),
        tap(item => item && this.metadataService.findLocations(item).pipe(first()).subscribe(
          (metaData) => {
            this.locationList = metaData.data;
          })
        ),
        delay(300),
        map(item => item ? this.locationList : [])
      );

    self.loading = false;
  }

  @HostListener('window:keydown', ['$event'])
  onkeydown($event): void {
    // console.log($event.srcElement);
    if ($event.key === 'Escape' && $event.path.findIndex((el) => {
      return el.localName === 'mat-optgroup' || el.localName === 'mat-select';
    }) === -1) {
      this.closeDialog();
      $event.srcElement.blur();
    }
  }

  getObservableList(controlName: string, list: Array<any>) {
    // const items = list[0].name ? map(list, 'name') : list;
    const control = this.opportunityForm.controls[controlName];
    return control ?
      control.valueChanges
        .pipe(
          startWith(null),
          map(item => this.filterList(list, item))
        ) : null;

  }

  filterList(list: Array<any>, val: string): Array<any> {
    return val ? filter(list, item => item && (item?.name || item)?.toLowerCase().includes(val.toString().toLowerCase()))
      : list;
  }

  setCompany(company: any): void {
    this.company = company;
    // this.link = !this.link && company ? this.pathService.resolveWebUrl(company.domain) : this.link;
  }

  getCompanyName(company?: any): string | undefined {
    return company ? company.name : undefined;
  }

  selectSchool(school: ISchool): void {
    if (this.selectedSchools.length > 0 && (
      school.id === 0 || this.selectedSchools.includes(0)
    )) {
      this.selectedSchools = [school.id];
    }

    const diff = difference(_map(this.schools, 'id'), this.selectedSchools);
    if (diff.length === 1 && diff[0] === 0) {
      this.selectedSchools = diff;
    }
  }

  saveOpportunity(event: Event, formDirective: FormGroupDirective): void {
    const self = this;
    this.loading = true;
    this.opportunityForm.disable();

    const isGlobal = this.selectedSchools.includes(0);

    this.selectedSchools = isGlobal ? [] : this.selectedSchools;
    const data = {
      title: this.title,
      description: this.description,
      company: this.company.domain || this.company.name || this.company,
      location: this.location,
      link: this.link || this.pathService.resolveWebUrl(this.company.domain),
      tags: this.selectedTags,
      anonymous: this.isAnonymous,
      global: isGlobal,
      schools: this.selectedSchools,
    };

    if (this.opportunity) {
      this.opportunityService
        .edit(this.opportunity.opportunityId, data).pipe(first())
        .subscribe(
          (res: IOpportunitiesResponse) => {
            if (res?.status.status === 'STA_SUCCESS') {
              this.dialogRef.close(data);
            } else {
              self.messageService.simple(res.status.message);
            }
            self.loading = false;
          }
        );
    } else {
      this.opportunityService
        .add(data).pipe(first())
        .subscribe(
          (res: IOpportunitiesResponse) => {
            if (res?.status.status === 'STA_SUCCESS') {
              formDirective.resetForm({
                companyControl: {
                  logo: '/assets/img/company-logo.png',
                },
                selectedSchoolsControl: [0],
              });
              self.opportunityForm.enable();
              self.added.emit(res.data.opportunities[0]);
            } else {
              self.messageService.simple(res.status.message);
            }
            self.loading = false;
          }
        );
    }
  }

  closeDialog(): void {
    if (this.opportunity) {
      this.dialogRef.close(false);
    } else {
      this.closed.emit();
    }
  }

}
