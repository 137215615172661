import { NgModule, ErrorHandler, ModuleWithProviders } from '@angular/core';

import { ExternalModules } from './external';
// import { Guards } from './guards';
// import { Utilities } from './utilities';
import { TranslationModule } from './translation';
import { WidgetsModule } from './widgets';
import { Guards, Utilities, Services, Interceptors, DIALOG_DATA } from './dependencies';

@NgModule({
  declarations: [],
  imports: [
    ...ExternalModules,
    TranslationModule,
    WidgetsModule,
  ],
  providers: [
  ],
  // entryComponents: [
  //   // ...Components,
  // ],
  exports: [
    ...ExternalModules,
    TranslationModule,
    WidgetsModule,
  ],
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...Interceptors,
        ...Guards,
        ...Services,
        ...Utilities,
        {
          provide: DIALOG_DATA,
          useValue: null,
        }
      ],
    }
  }
}
