import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from '../dependencies';
import { TranslationCacheService } from './services/translation-cache.service';
import { map } from 'rxjs/operators';

export class TranslationLoader implements TranslateLoader {
  private url: string;

  /**
   * Constructor of the class.
   *
   * @param {Http}                    http
   * @param {ConfigService}           configService
   * @param {TranslationCacheService} translationCacheService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private translationCacheService: TranslationCacheService
  ) {
    this.url = this.configService.get().translations.useLocal
      ? `./assets/i18n/` : `${this.configService.getApiUrl()}/translation/`;
  }

  /**
   * Gets the translations from the server
   *
   * @param {string}  language
   * @returns {Observable<any>}
   */
  public getTranslation(language: string): Observable<any> {
    return this.http
      .get(`${this.url}${language}.json`)
      .pipe(
        map((res) => {
          const translations = res;

          this.translationCacheService.base(language, translations);

          return translations;
        })
      );
  }
}
