<div fxLayout="column" fxLayoutAlign="center center" fxFill>

    <mat-card>
        <mat-toolbar color="primary">
            <div>{{ (data?.title || 'SPLASH_FIND_OPPORTUNITY_TITLE') | translate }}</div>
            <div class="close">
                <mat-icon (click)="dismiss($event)" matTooltip="{{ 'TOOLTIP_CLOSE' | translate }}">close</mat-icon>
            </div>
        </mat-toolbar>


        <mat-card-content>
            <!-- <div class="title">{{ 'SPLASH_FIND_OPPORTUNITY_TITLE' | translate }}</div> -->
            <mat-stepper orientation="vertical" class="stepper" selectedIndex="2">
                <mat-step editable="false" completed="true" state="completed">
                    <ng-template matStepLabel>
                        <span class="app-clickable highlight-link" (click)="dismiss($event, '/my/professional')">{{
                            'SPLASH_FIND_OPPORTUNITY_LINK_1' | translate }}</span>
                        <span>{{ 'SPLASH_FIND_OPPORTUNITY_1' | translate }}</span>
                    </ng-template>
                </mat-step>
                <mat-step editable="false" completed="true" state="completed">
                    <ng-template matStepLabel>
                        <span>{{ 'SPLASH_FIND_OPPORTUNITY_2' | translate }}</span>
                        <span class="app-clickable highlight-link" (click)="dismiss($event, '/hub/opportunities')">{{
                            'SPLASH_FIND_OPPORTUNITY_LINK_2' | translate }}</span>
                    </ng-template>
                </mat-step>
                <mat-step editable="false" completed="true" state="completed">
                    <ng-template matStepLabel>
                        <span class="app-clickable highlight-link" (click)="dismiss($event, '/hub/sociates')">{{
                            'SPLASH_FIND_OPPORTUNITY_LINK_3' | translate }}</span>
                        <span>{{ 'SPLASH_FIND_OPPORTUNITY_3' | translate }}</span>
                    </ng-template>
                </mat-step>
            </mat-stepper>
        </mat-card-content>
    </mat-card>

</div>