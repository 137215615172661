<div class="mat-input-wrapper">
  <div class="mat-input-flex">
    <div class="mat-input-prefix" *ngIf="_prefixChildren.length">
      <ng-content select="[matPrefix], [matPrefix]"></ng-content>
    </div>

    <div class="mat-input-infix">
      <ng-content selector="select, ng-select"></ng-content>
    </div>

    <div class="mat-input-suffix" *ngIf="_suffixChildren.length">
      <ng-content select="[matSuffix], [matSuffix]"></ng-content>
    </div>

  </div>

  <div class="mat-input-underline">
    <span class="mat-input-ripple"></span>
  </div>

</div>