import { PathService } from './path.service';
import { EmailService } from './email.service';
import { Dictionary } from './dictionary';
import { FormService } from './form.service';

// export * from './interfaces';
export * from './path.service';
export * from './email.service';
export * from './dictionary';
export * from './utils';
export * from './form.service';

export const Utilities = [
  // PathService,
  // EmailService,
  // Dictionary,
  // FormService,
];
