
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IResponse, IInvitation } from '../../../interfaces';
import { ConfigService } from '../utility/config.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {

  /**
     * Constructor of the class.
     *
     * @param {Http}            http
     * @param {ConfigService}   configService
     */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  public save(data): Observable<IResponse> {
    return this.http
      .post(`${this.configService.getApiUrl()}/referrals`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to save referral data.'))
      );
  }

  public validateReferral(data): Observable<any> {
    return this.http
      .post(`${this.configService.getApiUrl()}/referrals/validate`, data)
      .pipe(
        map((res: any) => { return res }),
        catchError((error: any) => observableThrowError(error || 'Failed to validate token.')),
      )
  }
}
