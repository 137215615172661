import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { IComment } from '../../../interfaces';

import * as moment from 'moment';
import { UserService } from '../../../dependencies';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class CommentComponent implements OnInit {

  @Input() data: IComment;
  @Input() size: 'small' | 'medium' | 'large';

  createdDate: string;
  isUserComment: boolean;

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit() {

    this.data = this.data || <IComment>{};
    this.size = this.size || 'medium';

    // moment.unix to be used for 10 digit unix timestamp
    this.createdDate = moment.unix(this.data.createdTimestamp).fromNow()
    this.isUserComment = this.userService.username === this.data.authorId;
    this.data.author.profileUrl = this.isUserComment ? '/my' : `/users/${ this.data.author.userId }`;

  }

}
