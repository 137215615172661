import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AnonymousLayoutRoutes } from '../../shared';

import { LoginRoutes } from './login/login.routing';
import { LogoutRoutes } from './logout/logout.routing';
import { ForgotRoutes } from './forgot/forgot.routing';

import { AuthComponent } from './auth.component';

const AuthRoutes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
      ...AnonymousLayoutRoutes,
      ...LoginRoutes,
      ...LogoutRoutes,
      ...ForgotRoutes,
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild([
      ...AuthRoutes,
    ]),
  ],
  exports: [
    // RouterModule,
  ],
})

export class AuthRoutingModule { }
