import { EmojifyPipe } from './emojify.pipe';
import { LinkfyPipe } from './linkfy.pipe';
import { CleanPipe } from './clean.pipe';
import { MachineCodePipe } from './machine-code.pipe';
import { DistinctPipe } from './distinct.pipe';
import { TranslateDefaultPipe } from './translate-default.pipe';

export * from './emojify.pipe';
export * from './linkfy.pipe';
export * from './clean.pipe';
export * from './machine-code.pipe';
export * from './distinct.pipe';
export * from './translate-default.pipe';

export const Pipes = [
    EmojifyPipe,
    LinkfyPipe,
    CleanPipe,
    MachineCodePipe,
    DistinctPipe,
    TranslateDefaultPipe,
];
