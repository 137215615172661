import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { NgxWebstorageModule } from 'ngx-webstorage';
// import { ImageCropperModule } from 'ngx-img-cropper';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FileUploadModule } from 'ng2-file-upload';
import { ResponsiveModule, IResponsiveConfig } from 'ngx-responsive';
import { Angulartics2Module, Angulartics2Settings } from 'angulartics2';
// import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
// import { MentionModule } from 'angular2-mentions/mention';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { JwtModule } from '@auth0/angular-jwt';
import { CookieService } from 'ng2-cookies';
// import {  } from 'angular2-autosize';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { NgChartsModule } from 'ng2-charts';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

const responsiveConfig = {
    breakPoints: {
        xs: { max: 600 },
        sm: { min: 601, max: 959 },
        md: { min: 960, max: 1279 },
        lg: { min: 1280, max: 1919 },
        xl: { min: 1920 }
    },
    debounceTime: 100 // allow to debounce checking timer
};

// export function ResponsiveDefinition() {
//     return new ResponsiveConfig(config);
// };


@NgModule({
    imports: [
        // JwtModule,
        ImageCropperModule,
        FileUploadModule,
        ResponsiveModule.forRoot(responsiveConfig),
        MomentModule,
        NgxWebstorageModule.forRoot({
            prefix: '$_sio_v2',
            caseSensitive: true,
            // separator: '|',
        }),
        Angulartics2Module.forRoot(<Partial<Angulartics2Settings>>{
            pageTracking: {
                // basePath: window.location.origin,
                clearIds: true,
                idsRegExp: new RegExp(/^[#0-9]+/),
                clearQueryParams: true,
                clearHash: false,
            },
        }),
        // MentionModule,
        InfiniteScrollModule,
        PdfViewerModule,
        // NgChartsModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
    ],
    providers: [
        // {
        //     provide: ResponsiveConfig,
        //     useFactory: ResponsiveDefinition
        // },
        CookieService,
    ],
    exports: [
        // JwtModule,
        ImageCropperModule,
        FileUploadModule,
        ResponsiveModule,
        MomentModule,
        NgxWebstorageModule,
        // MentionModule,
        InfiniteScrollModule,
        PdfViewerModule,
        // NgChartsModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
    ]
})

export class ExternalModule { }
