import { Component, OnInit, Input, Inject, ElementRef, SecurityContext, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import { isHtmlString, parseHtmlEntities } from '../../../dependencies';

@Component({
  selector: 'app-read-more, [appReadMore]',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent implements OnInit {

  @Input() appReadMore: string;
  @Input() maxLength: number;
  @Input() maxHeight: number;
  @Input() readMoreLabel = 'see more';
  @Input() position = 'top';
  @Input() mode: 'default' | 'formatted';

  @ViewChild('contentControl') contentControl: ElementRef;
  isExpanded = false;
  isHtmlContent = false;
  htmlContent: any;
  sanitizedContent: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer,
  ) {
    this.maxHeight = this.maxHeight || 250;
  }

  ngOnInit() {
    const content = this.appReadMore;
    if (isHtmlString(content)) {
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(content);
      this.sanitizedContent = parseHtmlEntities(this.sanitizer.sanitize(SecurityContext.HTML, content)).replace(/<[^>]*>/g, '');
      this.isHtmlContent = true;
    } else {
      this.sanitizedContent = this.htmlContent = content;
    }
    this.sanitizedContent = this.sanitizedContent.substr(0, this.maxLength);
    this.sanitizedContent = this.sanitizedContent.substr(0, Math.min(this.sanitizedContent.length, Math.max(
      this.sanitizedContent.lastIndexOf(' '),
      this.sanitizedContent.lastIndexOf(','),
      this.sanitizedContent.lastIndexOf(';'),
      this.sanitizedContent.lastIndexOf('!'),
    )));
  }

  checkLength(): boolean {
    return this.appReadMore.length > this.maxLength;
  }

  getContentHeight(): number {
    return this.contentControl ? this.contentControl.nativeElement.scrollHeight : 0;
  }

}
