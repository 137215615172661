import { Directive, HostBinding, Input, HostListener } from '@angular/core';
import { ConfigService, ImageService } from '../../dependencies';

@Directive({
  selector: 'img[appDefault]',
})
export class DefaultImageDirective {
  @Input() appDefault: string;
  @HostBinding('src') @Input() src: string;
  // @HostBinding('class') className: string;

  constructor(
    private configService: ConfigService,
    private imageService: ImageService,
  ) {
    // this.className = 'mat-card-avatar';
  }

  @HostListener('error')
  updateUrl(): void {
    this.src = this.appDefault || this.imageService.resolvePictureUrl(this.configService.defaults.account.picture);
    // this.className += ' default-pic';
  }

}
