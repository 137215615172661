import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { SpecializationComponent } from '../../../features/account';

@Directive({
  selector: '[appListRequired]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ListRequiredDirective, multi: true }],
})
export class ListRequiredDirective implements Validator, OnInit {

  @Input() appListRequired: any[];
  validator: Function;

  constructor(
    private view: ViewContainerRef,
  ) { }

  ngOnInit(): void {
    this.validator = this.listValidatorFactory();
  }

  public validate(c: AbstractControl): { [key: string]: any; } {
    return this.validator(c);
  }

  private listValidatorFactory(): Function {

    return (c: AbstractControl): any => this.appListRequired?.length > 0 ? null : {
      required: {
        valid: false
      }
    };

  }

}
