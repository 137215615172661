import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';
import { UtilityService } from '../../dependencies';

@Directive({
    selector: 'input[appAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
    @Input() appAutoFocus: boolean;

    constructor(
        private elementRef: ElementRef,
        private utils: UtilityService,
    ) { }

    ngAfterViewInit(): void {
        this.utils.setFocus(this.elementRef.nativeElement);
    }
}
