import { Routes } from '@angular/router';

import { LogoutComponent } from './logout.component';
import { TranslationGuard, AuthenticationGuard } from '../../../shared';

export const LogoutRoutes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [
      AuthenticationGuard,
      TranslationGuard,
    ],
  },
];
