import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class DividerComponent implements OnInit {

  @Input() data: string;
  @Input() fontSize: string;

  constructor() { }

  ngOnInit() {
  }

}
