import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import * as moment from 'moment';

import { ISociate, IResponse } from '../../../interfaces';
import { SociateService, UserService, MessageService, ChatService, ClientService, DIALOG_DATA } from '../../../dependencies';
import { User } from '../chat';

@Component({
  selector: 'app-sociate',
  templateUrl: './sociate.component.html',
  styleUrls: ['./sociate.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class SociateComponent implements OnInit {

  @Input() data: ISociate;
  @Input() size: 'small' | 'medium' | 'large';
  @Input() visible: boolean;
  @Input() messaging = true;
  @Input() showProfession = true;
  @Input() showResumeIcon = true;
  @Input() showOpenToWorkIcon = true;
  @Input() disableUserLink = false;
  @Input() editable = false;

  @Output() deleted: EventEmitter<ISociate>;
  @Output() changed: EventEmitter<ISociate>;

  nameLength = {
    small: 10,
    medium: 15,
    large: 15,
  }

  joinedDate: string;
  isUserSociate: boolean;
  isBookmarked: boolean;
  isLoggedInUser: boolean;
  isRecruiter = false;
  pictureLink = null;
  personalLink = null;
  professionalLink = null;
  openToWork: boolean;

  constructor(
    private router: Router,
    private sociateService: SociateService,
    private clientService: ClientService,
    private userService: UserService,
    private messageService: MessageService,
    private chatService: ChatService,
    @Inject(DIALOG_DATA) input?: ISociate,
  ) {
    this.deleted = new EventEmitter<ISociate>();
    this.changed = new EventEmitter<ISociate>();

    this.data = input || this.data;
  }

  ngOnInit() {
    this.visible = true;

    this.size = this.size || 'medium';

    // moment.unix to be used for 10 digit unix timestamp
    this.joinedDate = moment.unix(this.data.created).fromNow();
    this.isUserSociate = this.userService.username === this.data.userId;
    this.isBookmarked = this.data.bookmarked;
    this.isLoggedInUser = this.data.userId === this.userService.username;
    this.pictureLink = this.editable ? ['/my/personal'] : ['/users', this.data.userId];
    this.personalLink = this.editable ? ['/my/basic'] : ['/users', this.data.userId];
    this.professionalLink = this.editable ? ['/my/professional'] : ['/users', this.data.userId];
    this.isRecruiter = this.userService.verifyRole('recruiter');
    this.openToWork = this.showOpenToWorkIcon && this.data.profession?.openToWork;
  }

  messageSociate(): void {
    if (this.clientService.device?.toLowerCase() === 'desktop') {
      this.chatService.triggerOpenChatWindow(new User(this.data));
    } else {
      this.router.navigate(['messages', this.data.userId]);
    }
  }

  bookmarkMe(): void {

    const self = this;
    this.sociateService
      .setBookmark(
        this.data.userId,
        !this.isBookmarked,
      )
      .pipe(first())
      .subscribe(
        (res: IResponse) => {
          if (res.status.status === 'STA_SUCCESS') {
            self.data.bookmarked = self.isBookmarked = !self.isBookmarked;
            self.changed.emit(self.data);
            const t_msg = self.isBookmarked ? `bookmarked ${ self.data.firstname }` : 'removed bookmark';
            self.messageService.simple(`Successfully ${ t_msg }`);
          } else {
            this.messageService.simple(res.status.message);
          }
        },
        (error) => {
          self.messageService.simple(error.message);
        }
      );

  }

}
