import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import { AuthService } from '../services';
import { AccountStatus, ClientService, UserService, ConfigService, MessageService, EmailService } from '../../../shared';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class ForgotComponent implements OnInit {

  forgotForm: FormGroup;

  @ViewChild('usernameControl')
  usernameControl: ElementRef;

  username: string;

  public loading = false;
  public device: string;
  public emailPattern: RegExp;

  /**
   * Constructor of the class.
   *
   * @param {AuthService}     authService
   * @param {UserService}     userService
   * @param {MessageService}  messageService
   * @param {Router}          router
   */
  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private userService: UserService,
    private messageService: MessageService,
    private clientService: ClientService,
    private translate: TranslateService,
    private router: Router,
    private emailService: EmailService,
  ) { 
    this.forgotForm = new FormGroup({
      usernameControl: new FormControl(),
    });
  }

  ngOnInit() {
    // Reset form data
    this.device = this.clientService.device;
    this.emailPattern = this.emailService.getEmailPattern();
    this.username = this.userService.getSession('email') || this.configService.debug?.user ? this.configService.debug.user.email : '';

    // Remove loading
    this.loading = false;
  }

  /**
 * Method to make actual forgot
 *
 * @param {Event} event
 */
  public forgot(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    this.loading = true;
    const user = this.forgotForm.controls['usernameControl'].value;

    this.authService
      .forgot({ email: this.emailService.construct(user) }).pipe(first())
      .subscribe(
        (data) => {

          if (data.status.status === 'STA_SUCCESS') {

            // Attach message
            this.translate.get('FORGOT_SUCCESS').pipe(first()).subscribe((msg: string) => {
              this.messageService.simple(msg);
              this.loading = false;
              return this.router.navigate(['/auth/login']);
            });

          } else {
            this.translate.get('FORGOT_FAILED').pipe(first()).subscribe((msg: string) => {
              this.messageService.simple(msg);
              this.loading = false;
            });
          }

        },
        (error) => {

          this.translate.get('UNEXPECTED_ERROR').pipe(first()).subscribe((msg: string) => {
            this.messageService.simple(`${msg}`);

            // this.ngOnInit();
            this.loading = false;
          });

        }
      )
      ;
  }
}
