
export * from './angular.module';
export * from './material.module';
export * from './external.module';

import { AngularModule } from './angular.module';
import { MaterialModule } from './material.module';
import { ExternalModule } from './external.module';

export const ExternalModules = [
    AngularModule,
    MaterialModule,
    ExternalModule,
]
