import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { AuthService } from '../services';
import { ConfigService } from '../../../shared';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent implements OnInit {

  /**
   * Constructor of the class.
   *
   * @param {ConfigService}     configService
   * @param {AuthService}     authService
   */
  constructor(
    private configService: ConfigService,
    private authService: AuthService,
  ) {
    this.authService.logout();
  }

  ngOnInit() {
  }

}
