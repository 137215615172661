import { Directive, OnDestroy, AfterViewInit, Provider, forwardRef, HostBinding, Input, NgZone, ElementRef, PLATFORM_ID, Inject, SecurityContext, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { merge } from 'lodash-es';

import { noop } from '../../dependencies/utilities';

const getTinymce = () => {
    const w = typeof window !== 'undefined' ? (window as any) : undefined;
    return w?.tinymce || null;
};

export const TinyMceValueAccessor: Provider = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line: no-use-before-declare
    useExisting: forwardRef(() => HtmlEditorDirective),
    multi: true,
};

// Tinymce directive
@Directive({
    selector: '[appHtmlEditor]',
    providers: [TinyMceValueAccessor]
})
export class HtmlEditorDirective implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
    static nextUniqueId = 0;

    @HostBinding('attr.id') id: string;
    @HostBinding('attr.data-tinymce-uniqueid') uniqueId: string;
    @Input() appHtmlEditor: any;
    @Input() initialValue: string | undefined;

    private _disabled: boolean | undefined;
    private _editor: any;
    innerValue: string;
    // init = false;

    defaultConfig: any;

    private onTouchedCallback = this.updateValue;
    private onChangeCallback = noop;

    constructor(
        private sanitizer: DomSanitizer,
        private ngZone: NgZone,
        private elementRef: ElementRef,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {
        this.uniqueId = `tinymce-host-${HtmlEditorDirective.nextUniqueId++}`;
    }

    ngOnInit(): void {
        this.defaultConfig = {
            branding: false,
            base_url: '/tinymce',
            suffix: '.min',
            // selector: `[data-tinymce-uniqueid=${this.uniqueId}]`,
            target: this.elementRef.nativeElement,
            // inline: true,
            // schema: 'html5',
            setup: (editor: any) => {
                this._editor = editor;
                editor.on('init', (e: Event) => {
                    this.initEditor(e, editor);
                    // if (this.innerValue) { e.target.setContent(this.innerValue); }
                    // // this.init = true;
                    // editor.on('blur', () => this.ngZone.run(() => this.onTouchedCallback()));
                    // editor.focus(false);
                    // editor.selection.select(editor.getBody(), true);
                    // editor.selection.collapse(false);
                });
                editor.on('focus', function (e) {
                    console.log('focus event', e);
                });
            },
            menubar: false,
            statusbar: false,
            plugins: 'lists advlist fullscreen autoresize link autolink',
            toolbar: 'undo redo | bold italic link | forecolor backcolor | bullist numlist outdent indent | fullscreen',
            // menubar: 'file edit view insert format tools table help',
            // plugins: 'print preview fullpage paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
            // toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
            // imagetools_cors_hosts: ['picsum.photos'],
            // toolbar_sticky: true,
            // autosave_ask_before_unload: true,
            // autosave_interval: '30s',
            // autosave_prefix: '{path}{query}-{id}-',
            // autosave_restore_when_empty: false,
            // autosave_retention: '2m',
            // image_advtab: true,
            min_height: 100,
            max_height: 250,
            content_css: [
                '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
                // '//www.tiny.cloud/css/codepen.min.css'
            ],
            // link_list: [
            //     { title: 'My page 1', value: 'http://www.tinymce.com' },
            //     { title: 'My page 2', value: 'http://www.moxiecode.com' }
            // ],
            // image_list: [
            //     { title: 'My page 1', value: 'http://www.tinymce.com' },
            //     { title: 'My page 2', value: 'http://www.moxiecode.com' }
            // ],
            // image_class_list: [
            //     { title: 'None', value: '' },
            //     { title: 'Some class', value: 'class-name' }
            // ],
            // importcss_append: true,
            // height: 100,
            // templates: [
            //     { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
            //     { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
            //     { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
            // ],
            // template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
            // template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
            // image_caption: true,
            // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
            // noneditable_noneditable_class: 'mceNonEditable',
            // toolbar_drawer: 'sliding',
            contextmenu: 'link image imagetools table',
        };
    }

    @Input() set setFocus(v) {
        if (v) {
            setTimeout(() => {
                this._editor.focus(false);
                // getTinymce().get('postControl').focus();
                // getTinymce().execCommand('mceFocus', false, this.elementRef.nativeElement.id);
            }, 0);
        }
    }
    @Input() set disabled(val) {
        this._disabled = val;
        if (this._editor?.initialized) {
            this._editor.setMode(val ? 'readonly' : 'design');
        }
    }

    get disabled() {
        return this._disabled;
    }

    // get accessor
    get value(): any {
        return this.innerValue;
    };

    // set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    ngAfterViewInit(): void {
        // console.log('tinymce');
        if (isPlatformBrowser(this.platformId) && getTinymce() !== null) {
            this.id = this.id || this.uniqueId;
            this.ngZone.runOutsideAngular(() => {
                getTinymce().init(merge(this.defaultConfig, this.appHtmlEditor));
            });
        }
    }

    updateValue() {
        const content = getTinymce().activeEditor.getContent();
        this.value = this.sanitizer.bypassSecurityTrustHtml(content);
        // this.value = this.sanitizer.sanitize(SecurityContext.HTML, content);
    }

    writeValue(value: string | null): void {
        this.initialValue = value || this.initialValue;
        value = value || '';

        if (value !== this.innerValue) {
            this.innerValue = value;
        }

        if (this._editor?.initialized && typeof value === 'string') {
            this._editor.setContent(value);
            // getTinymce().activeEditor.setContent(value);
        }
    }

    public registerOnChange(fn: (_: any) => void): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    public setDisabledState(isDisabled: boolean) {
        if (this._editor) {
            this._editor.setMode(isDisabled ? 'readonly' : 'design');
        } else if (isDisabled) {
            this.defaultConfig = { ...this.defaultConfig, readonly: true };
        }
    }

    ngOnDestroy(): void {
        // if (this.init) { getTinymce().remove(`[data-tinymce-uniqueid=${this.uniqueId}]`); }

        if (getTinymce() !== null) {
            getTinymce().remove(this._editor);
        }
    }

    private initEditor(initEvent: Event, editor: any) {
        if (typeof this.initialValue === 'string') {
            this.ngZone.run(() => editor.setContent(this.initialValue));
        }
        // if (this.innerValue) { initEvent.target.setContent(this.innerValue); }
        // this.init = true;
        editor.on('blur', () => this.ngZone.run(() => this.onTouchedCallback()));
        editor.on('change keyup undo redo', () => this.ngZone.run(() => this.onChangeCallback(editor.getContent())));
        // editor.focus(false);
        // editor.selection.select(editor.getBody(), true);
        // editor.selection.collapse(false);

        // if (typeof this.initialValue === 'string') {
        //     this.ngZone.run(() => editor.setContent(this.initialValue));
        // }
        // editor.on('blur', () => this.ngZone.run(() => this.onTouchedCallback()));
        // editor.on('change keyup undo redo', () => this.ngZone.run(() => this.onChangeCallback(editor.getContent())));
        // bindHandlers(this, editor, initEvent);
    }
}
