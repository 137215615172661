import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  /**
   * Constructor of the class
   *
   * @param {MatSnackBar}  snackBar
   */
  public constructor(private snackBar: MatSnackBar) { }

  /**
   * Method to show simple snack-bar / toast on page.
   *
   * @param {string}  message
   * @param {boolean} showCloseButton
   * @param {number}  duration
   *
   * @returns {MatSnackBarRef<SimpleSnackBar>}
   */
  public simple(message: string, showCloseButton = true, closeMessage = 'DISMISS', duration = 6000): MatSnackBarRef<SimpleSnackBar> {
    const ref = this.snackBar.open(
      message || 'Unexpected error occurred.',
      showCloseButton ? closeMessage : null,
      <MatSnackBarConfig>{
        duration: duration,
        panelClass: [
          'app-snackbar'
        ],
      }
    );

    ref.onAction().pipe(first()).subscribe(() => { });

    return ref;
  }
}
