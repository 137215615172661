import { Directive, HostBinding, Input, HostListener, ElementRef, OnInit } from '@angular/core';
import { ConfigService, ImageService } from '../../dependencies';

@Directive({
  selector: '[appMaxLength]',
})
export class MaxLengthDirective implements OnInit {
  @Input() appMaxLength: number;
  @Input() content: string;
  // @HostBinding('matTooltip') @Input() toolTip: string;

  constructor(
    private element: ElementRef,
    private configService: ConfigService,
    private imageService: ImageService,
  ) {
  }

  ngOnInit() {
    // this.content = this.element.nativeElement.innerText;
    this.element.nativeElement.innerText = this.appMaxLength > 0 && this.content?.length > this.appMaxLength ? this.content.substr(0, this.appMaxLength).concat('...') : this.content;
  }

}
