<!-- <div class="footer"> -->
  <mat-toolbar class="content">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFill>
      <span *ngFor="let link of links">
        <a href="{{ link.url }}" target="_blank">
          <mat-icon>{{ link.icon }}</mat-icon>

          <span [ngSwitch]="link.translate">
            <span *ngSwitchCase="true">
              {{ link.name | translate }}
            </span>
            <span *ngSwitchDefault>
              {{ link.name }}
            </span>
          </span>
        </a>
      </span>

      <div fxFlex></div>

      <span class="copyright">Copyright © {{ year }} Sociate | <small>v{{ version }}</small></span>
    </div>
  </mat-toolbar>
<!-- </div> -->