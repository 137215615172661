import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { FileUploader, FileItem } from 'ng2-file-upload';
import Compressor from 'compressorjs';

import { IPicture } from './interfaces';

@Component({
  selector: 'app-image-editor',
  templateUrl: 'image-editor.component.html',
  styleUrls: ['image-editor.component.scss'],
})
export class ImageEditorComponent implements OnInit, AfterViewInit {

  uploader: FileUploader;
  name: string;
  hasDropZoneOver: boolean;
  data: IPicture;
  fileChangeEvent: any;
  imageUrl: string;
  imageBase64: string;
  fileBlob: Blob;
  showCropper: boolean;


  /**
 * Constructor of the class.
 *
 * @param {AuthService}     authService
 * @param {MessageService}  messageService
 * @param {Router}          router
 */
  constructor(
    @Inject(MAT_DIALOG_DATA) input: IPicture,
  ) {
    const self = this;
    this.name = 'image-editor';
    // this.cropperSettings = new CropperSettings();
    this.data = input ?? {} as IPicture;
    this.loadImage();
    this.uploader = new FileUploader({ url: '' });
    this.uploader.onAfterAddingFile = (file: FileItem) => {
      this.fileDropListener(self, file);
    };
    this.hasDropZoneOver = false;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  loadImage(): void {
    if (this.data?.original) {
      this.imageBase64 = this.data.original.src;
    } else {
      this.imageUrl = this.data?.url;
    }
  }

  loadFileFromUrl(url: string): void {
    const self = this;
    const img: HTMLImageElement = document.createElement('img');
    img.crossOrigin = 'Anonymous';
    img.onload = e => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 10, 10);
      const dataURL = canvas.toDataURL();
      // return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
      self.imageBase64 = dataURL;
    }
    img.src = url;
  }

  fileOverHandler(e: any): void {
    this.hasDropZoneOver = e;
  }

  loaded(image: LoadedImage) {
    this.data.original = image.original.image;
    this.showCropper = true;
  }

  cropped(event: ImageCroppedEvent) {
    const cropped = new Image();
    cropped.src = event.base64;
    cropped.height = event.height;
    cropped.width = event.width;
    this.data.cropped = cropped;
  }

  fileChangeListener(event): void {
    // this.loadFile($event.target.files[0]);
    this.fileChangeEvent = event;
  }

  fileDropListener(self: ImageEditorComponent, droppedFile: FileItem): void {
    self.loadFile(droppedFile._file);
  }

  loadFile(file: File): void {

    const self = this;

    new Compressor(file, {
      quality: 0.6,
      success: (result: Blob) => {
        self.fileBlob = result;

        // const image: any = new Image();
        // image.onload = (ev) => {
        //   // self.cropper.setImage(image);
        // };
        // image.sizes = result.size;

        // const myReader: FileReader = new FileReader();
        // myReader.onloadend = function (loadEvent: any) {
        //   image.src = loadEvent.target.result;
        //   self.data.original = image;
        //   if (self.data.cropped && (<HTMLImageElement>self.data.cropped).src.startsWith('http')) {
        //     self.data.cropped = image;
        //   } else {
        //     self.data.cropped = new Image();
        //   }
        // };
        // myReader.readAsDataURL(result);
      }
    });

    // this.ng2ImgMaxService.compressImage(file, 3).pipe(first())
    //   .subscribe((result) => {

    //     const image: any = new Image();
    //     image.onload = (ev) => {
    //       self.cropper.setImage(image);
    //     };
    //     image.sizes = result.size;

    //     const myReader: FileReader = new FileReader();
    //     myReader.onloadend = function (loadEvent: any) {
    //       image.src = loadEvent.target.result;
    //       self.data.original = image;
    //       if (self.data.cropped && (<HTMLImageElement>self.data.cropped).src.startsWith('http')) {
    //         self.data.cropped = image;
    //       } else {
    //         self.data.cropped = new Image();
    //       }
    //     };
    //     myReader.readAsDataURL(result);
    //   });

  }

  hasFile(): boolean {
    return this.uploader.queue.length > 0 || this.imageBase64?.length > 0 || this.imageUrl?.length > 0;
  }

  clearFile(): void {
    this.fileChangeEvent = null;
    this.imageUrl = null;
    this.imageBase64 = null;
    this.uploader.clearQueue();
  }

  returnData(): IPicture {
    // this.data.cropped = this.croppedImage;
    return this.data;
  }

}
