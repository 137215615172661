import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { first } from 'rxjs/operators';

import { RouterService, UserService } from '../services';

/**
 * This class implements a guard for routes that require successful authentication.
 */
@Injectable({
  providedIn: 'root',
})
export class SimulationGuard implements CanActivate, CanLoad {
  /**
   * Constructor of the class.
   *
   * @param {UserService} userService
   * @param {Router}      router
   */
  constructor(
    private userService: UserService,
    private routerService: RouterService,
  ) { }

  /**
   * Purpose of this guard is check that current user has been authenticated to application. If user is not
   * authenticated he/she is redirected to login page.
   *
   * @param {ActivatedRouteSnapshot}  route
   * @param {RouterStateSnapshot}     state
   * @returns {Observable<boolean>}
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.processRoute();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.processRoute();
  }

  private processRoute(): boolean {
    return !this.userService.isSimulating;
  }
}
