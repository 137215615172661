import { Component, OnInit, ViewChildren, AfterViewInit, QueryList, HostListener } from '@angular/core';
import { RoutesRecognized, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { filter, map, pairwise } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
// import { MatSidenav } from '@angular/material';
import { Angulartics2GoogleTagManager } from 'angulartics2';

// import { SidenavService } from './layout/sidenav/sidenav.service';
import { StorageService, ClientService, UserService, IChatController, ChatService, AccountStatus, ConfigService, RequestCache, LogService, UserStatus } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})

export class AppComponent implements OnInit, AfterViewInit {
  // @ViewChild('sidenav', { static: false }, { static: false }) public sidenav: MatSidenav;

  public isMobile: boolean;
  public userId: string;

  @ViewChildren('messaging')
  protected messaging: QueryList<IChatController>;

  /**
   * Constructor of the class.
   *
   * @param {TranslateService}    translate
   * @param {LocalStorageService} localStorage
   * @param {SidenavService}      sidenavService
   */
  public constructor(
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private localStorage: StorageService,
    private clientService: ClientService,
    private userService: UserService,
    private chatService: ChatService,
    private configService: ConfigService,
    private cache: RequestCache,
    private router: Router,
    private logService: LogService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    // private sidenavService: SidenavService,
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    // this.translate.reloadLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.localStorage.get('language') || 'en');
    this.angulartics2GoogleTagManager.startTracking();
  }

  /**
   * OnInit life cycle hook
   */
  public ngOnInit(): void {
    // this.userId = this.userService.username;
    // Store sidenav to service
    // this.sidenavService
    //   .setSidenav(this.sidenav);

    const self = this;
    this.logService.trace('Startup');
    this.configService.refresh();
    // this.cache.resetMaxAge();

    if (this.configService.isDebugMode) {
      this.router.events
        .pipe(filter((e: any) => e instanceof RoutesRecognized),
          pairwise()
        ).subscribe((e: any) => {
          this.logService.debug(`Route changed: `, e); // previous url
        });
    }

    this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        return matches;
      })
    ).subscribe(result => self.isMobile = result);
  }

  ngAfterViewInit(): void {
    // this.chatService.init(this.messaging.first);
  }

  public clientInit(client: any): void {
    this.logService.trace('Startup Agent Info', client);
    if (client) {
      this.clientService.init(client);
    }
  }

  public get showFloatingChat(): boolean {
    return this.userService.loggedIn
      && this.userService.profile.status === AccountStatus.Active
      // && this.clientService.device?.toLowerCase() === 'desktop'
      && !this.isMobile
      && this.chatService.floating
      && !this.userService.isSimulating
      && !this.userService.isReadOnly
      ;
  }

  @HostListener('document:visibilitychange', ['$event'])
  public onDocumentVisibilityChange(event) {
    this.chatService.updateStatus(document.visibilityState === 'hidden' ? UserStatus.Away : UserStatus.Online);
  }

  // @HostListener('window:blur', ['$event'])
  // public onWindowBlur(event) {
  //   this.chatService.updateStatus(UserStatus.Away);
  // }

  // @HostListener('window:focus', ['$event'])
  // public onWindowFocus(event) {
  //   this.chatService.updateStatus(UserStatus.Online);
  // }


}
