<div class="container">
    <h2 mat-dialog-title>{{ 'Select Picture' }}</h2>
    <mat-dialog-content>
        <!-- <div>{{ file.name }}</div> -->
        <div ng2FileDrop 
            class="drop-zone" 
            [class.drop-zone-over]="hasDropZoneOver || hasFile()" 
            [uploader]="uploader" 
            (fileOver)="fileOverHandler($event)"
            fxLayout="row" 
            fxLayoutAlign="space-around center">
            <!-- <img-cropper #cropper [image]="data.cropped" [settings]="cropperSettings" (onCrop)="cropped($event)" *ngIf="hasFile()"></img-cropper> -->
            <image-cropper 
                [imageChangedEvent]="fileChangeEvent"
                [imageURL]="imageUrl"
                [imageBase64]="imageBase64"
                [roundCropper]="true"
                (imageLoaded)="loaded($event)"
                (imageCropped)="cropped($event)" 
                *ngIf="hasFile()">
            </image-cropper>

            <div *ngIf="!hasFile()" fxLayout="column" fxLayoutAlign="center center">
                <button mat-raised-button color="accent">
                    <label for="file-input">Select File</label>
                </button>
                <br />
                <span>Or</span>
                <br />
                <span>Drag and drop your picture here</span>
            </div>
            <input id="file-input" ng2FileSelect [uploader]="uploader" type="file" accept="image/*" (change)="fileChangeListener($event)">
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button color="primary" [mat-dialog-close]="data">{{ 'BUTTON_APPLY' | translate }}</button>
        <button mat-raised-button color="accent" (click)="clearFile()" >{{ 'BUTTON_CLEAR' | translate }}</button>
        <button mat-button [mat-dialog-close]>{{ 'BUTTON_CANCEL' | translate }}</button>
    </mat-dialog-actions>
</div>