import { Routes } from '@angular/router';

import { SociateComponent } from './sociate.component';
import { TranslationGuard } from '../../../../../translation';


export const SociateSidenavRoutes: Routes = [
  {
    path: '',
    component: SociateComponent,
    outlet: 'sidenav',
    canActivate: [
      TranslationGuard,
    ],
    data: {
      translation: {
        domain: 'layout',
        common: true,
      }
    }
  },
];
