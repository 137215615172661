
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { IResponse, ISummaryResponse, IMetricsResponse } from '../../../interfaces';
import { ConfigService } from '../utility/config.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  // private placesConfig: any = this.configService.get('places_api');
  // private client: any = google.createClient({ key: this.placesConfig.KEY });

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  public getSummary(name: string = ''): Observable<ISummaryResponse> {

    if (name?.length > 0) {
      name = `/${ name }`;
    }

    return this.http
      .get(`${ this.configService.getApiUrl() }/admin/dashboard/summary${ name?.toLowerCase() }`)
      .pipe(
        map((res: ISummaryResponse) => res),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve data.'))
      );
  }

  public getMetrics(name: string, mode: string = 'days'): Observable<IResponse> {

    return this.http
      .get(`${ this.configService.getApiUrl() }/admin/dashboard/metrics/${ name?.toLowerCase() }?m=${ mode }`)
      .pipe(
        map((res: IResponse) => res),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve data.'))
      );
  }

}
