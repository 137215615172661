import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { ISociate } from '../../../interfaces';
import { LogService } from '../utility';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  public refreshed: EventEmitter<any>;

  public constructor(
    private logService: LogService,
  ) {
    this.refreshed = new EventEmitter<any>();
  }

  // Used in Account widgets for User feature
  public refresh(): void {
    this.logService.trace('Profile');
    this.refreshed.emit();
  }

}
