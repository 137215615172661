import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { sumBy, capitalize } from 'lodash-es';
import { debounceTime, startWith, tap, delay, map, first } from 'rxjs/operators';

import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { LocaleModel } from '../../../../translation';
import { StorageService, UserService, ClientService, ChatService, RouterService, SearchService, IUser } from '../../../../dependencies';
import { IHeaderComponentResolve } from './interfaces';
import { IMessage, IOpportunity, IPost, ISearchResult, ISociate } from '../../../../interfaces';

@Component({
  selector: 'app-auth-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})

export class HeaderComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = new Array<Subscription>();

  searchForm: FormGroup;
  resultList: Array<ISearchResult>;
  filteredResults: any;
  searchText: any;

  public user: IUser;
  public locales: Array<LocaleModel>;
  public isAdmin: boolean;
  public isRecruiter: boolean;
  public isSociate: boolean;
  public device: string;
  public unread: number;
  public isReadOnly: boolean;

  /**
   * Constructor of the class.
   *
   * @param {ActivatedRoute}      activatedRoute
   * @param {LocalStorageService} localStorage
   * @param {UserService}         userService
   * @param {TranslateService}    translateService
   * @param {SidenavService}      sidenavService
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private localStorage: StorageService,
    public userService: UserService,
    private translateService: TranslateService,
    private chatService: ChatService,
    private searchService: SearchService,
    private routerService: RouterService,
    private router: Router,
  ) {
    this.searchForm = new FormGroup({
      searchControl: new FormControl(),
    });
  }

  /**
   * On component init we need to store current user and make a subscription for token changes so that we
   * get user value to update within login / logout states.
   */
  public ngOnInit(): void {
    this.device = this.clientService.device;
    this.user = this.userService.profile;
    // Keep below statements and do not use verifyRole as it current user may not have initialized yet.
    this.isAdmin = this.userService.verifyRole('administrator');
    this.isRecruiter = this.userService.verifyRole('recruiter');
    this.isSociate = this.userService.verifyRole('sociate');
    this.isReadOnly = this.userService.isReadOnly;

    const self = this;
    this.subscriptions.push(
      this.userService.profileAsObserable()
        .subscribe((profile) => {
          self.user = profile;
        })
    );

    // Store locales from route resolve
    this.subscriptions.push(
      this.activatedRoute.data
        .subscribe((data: IHeaderComponentResolve) => {
          self.locales = data.locales;
        })
    );

    this.subscriptions.push(
      this.chatService.getRecentChats()
        .subscribe(recents => {
          self.unread = sumBy(recents, 'unread');
        })
    );

    this.subscriptions.push(
      this.chatService.onMessagesSeen
        .subscribe((seenCount: number) => {
          this.unread = this.unread - seenCount;
        })
    );

    this.subscriptions.push(
      this.chatService.messageReceivedHandler
        .subscribe(msg => {
          this.unread++;
        })
    );

    this.filteredResults = this.searchForm.controls['searchControl'].valueChanges
      .pipe(
        debounceTime(200),
        startWith(null),
        tap(item => item && this.searchService.find(item, 5).pipe(first()).subscribe(
          (metaData) => {
            self.resultList = metaData.data;
            // self.resultList.push(<any>{
            //   name: item.name || capitalize(item),
            // });
          })
        ),
        delay(500),
        map(item => {
          if (!item) {
            self.resultList = [];
          }
          return self.resultList;
        })
      );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Method to change current language.
   *
   * @param {LocaleModel} locale
   */
  public changeLocale(locale: LocaleModel): void {
    this.translateService.use(locale.code);
  }

  public showLogout(): boolean {
    return this.user !== null;
  }

  public getMessageCount(): string {
    if (this.unread > 0) {
      if (this.unread > 99) {
        return '99+';
      } else {
        return String(this.unread);
      }
    }

    return '';
  }

  public getDuration(timestamp: number): string {
    return moment.unix(timestamp).fromNow();
  }

  public getResultLink(result: ISearchResult): Array<string> {
    if ((result as IPost).postId) {
      return ['/hub/forums'];
    } else if ((result as IOpportunity).opportunityId) {
      return ['/hub/opportunities'];
    } else if ((result as IMessage).id) {
      return [`/messages/${ (result as IMessage).recipient.userId }`];
    } else {
      return [this.userService.username === (result as ISociate).userId ? '/my' : `/users/${ (result as ISociate).userId }`];
    }
  }

  setToNull(): undefined {
    return undefined;
  }

  isActive(path: string): boolean {
    return this.router.url.split('/')[1].toLowerCase() === path.toLowerCase();
  }

  get isSimulating(): boolean {
    return this.userService.isSimulating;
  }

  dismiss(): void {
    if (this.userService.endSimulation()) {
      this.router.navigate(['/admin']);
    }
  }

}
