import { Routes } from '@angular/router';

import { ForgotComponent } from './forgot.component';
import { TranslationGuard, AnonymousGuard } from '../../../shared';

export const ForgotRoutes: Routes = [
  {
    path: 'forgot',
    component: ForgotComponent,
    canActivate: [
      AnonymousGuard,
      TranslationGuard,
    ],
  },
];
