import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

import { AccountService } from '../../../../dependencies';

@Component({
  selector: 'app-splash-hiring',
  templateUrl: './splash-hiring.component.html',
  styleUrls: ['./splash-hiring.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class SplashHiringComponent implements OnInit {

  public static readonly code: string = 'splash-hiring';

  data: {
    title: string,
    skipUpdate: boolean,
  }

  constructor(
    private dialogRef: MatDialogRef<SplashHiringComponent>,
    private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) input?: { title: string, skipUpdate: boolean },
  ) { }

  ngOnInit(): void {
  }

  dismiss(event: Event, redirect?: string): void {
    event.stopPropagation();
    event.preventDefault();

    const self = this;
    if (redirect?.toLowerCase().startsWith('mailto:')) {
      window.location.href = 'mailto:contact@sociate.io';
      redirect = null;
    }

    this.accountService.saveUserPreferences({
      pref: SplashHiringComponent.code,
      value: null,
    }).pipe(first())
      .subscribe(
        res => {
          self.dialogRef.close({ redirect });
        }
      );

  }

}
