import { Component, OnInit, HostBinding, ContentChildren, QueryList, ViewEncapsulation } from '@angular/core';
import { MatPrefix, MatSuffix } from '@angular/material/form-field';

@Component({
  moduleId: module.id,
  selector: 'app-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['input-container.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class InputContainerComponent implements OnInit {

  @HostBinding('class.mat-form-field')
  isMatInputContainer = true;

  @ContentChildren(MatPrefix)
  _prefixChildren: QueryList<MatPrefix>;

  @ContentChildren(MatSuffix)
  _suffixChildren: QueryList<MatSuffix>;

  constructor() { }

  ngOnInit() {
  }

}
