
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IResponse } from '../../../interfaces';
import { ConfigService } from '../utility/config.service';
import { IOpportunitiesResponse } from '../interfaces';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class OpportunityService {

  // private placesConfig: any = this.configService.get('places_api');
  // private client: any = google.createClient({ key: this.placesConfig.KEY });

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  public get(opportunityId: number): Observable<IOpportunitiesResponse> {

    return this.http
      .get(`${ this.configService.getApiUrl() }/opportunities/${ opportunityId }`)
      .pipe(
        map((response: IOpportunitiesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Opportunity data.'))
      );
  }

  /**
   * getPreview
   */
  public getAll(
    filters: {
      company?: string,
      school?: string,
      func?: string,
      location?: string,
    } = {},
    pageToken?: number,
    recordCount?: number,
  ): Observable<IOpportunitiesResponse> {

    let qs = '';

    qs += filters.func ? `f=${ filters.func }&` : '';
    qs += filters.company ? `cp=${ filters.company }&` : '';
    qs += filters.school ? `s=${ filters.school }&` : '';
    qs += filters.location ? `l=${ filters.location }&` : '';
    qs += pageToken ? `p=${ pageToken }&` : '';
    qs += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/opportunities?${ qs }`)
      .pipe(
        map((response: IOpportunitiesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Opportunities data.'))
      );
  }


  public add(data): Observable<IOpportunitiesResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/opportunities`, data)
      .pipe(
        map((response: IOpportunitiesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to add a new opportunity.'))
      );
  }

  public edit(opportunityId, data): Observable<IOpportunitiesResponse> {
    return this.http
      .put(`${ this.configService.getApiUrl() }/opportunities/${ opportunityId }`, data)
      .pipe(
        map((response: IOpportunitiesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to add a new opportunity.'))
      );
  }

  public delete(opportunityId): Observable<IResponse> {
    return this.http
      .delete(`${ this.configService.getApiUrl() }/opportunities/${ opportunityId }`)
      .pipe(
        map((response: IOpportunitiesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to delete the opportunity.'))
      );
  }

  public deleteOpportunities(data: Array<number>): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/opportunities/deleteSelected`, data)
      .pipe(
        map((response: IOpportunitiesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to delete the opportunities.'))
      );
  }

  public toggle(data: Array<number>): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/opportunities/toggleSelected`, data)
      .pipe(
        map((response: IOpportunitiesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to toggle status of the opportunities.'))
      );
  }

}
