<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'sociate'">
        <ng-container *ngTemplateOutlet="sociate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'opportunity'">
        <ng-container *ngTemplateOutlet="opportunity"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'article'">
        <ng-container *ngTemplateOutlet="article"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="post"></ng-container>
    </ng-container>
</ng-container>


<ng-template #post>
    <mat-card class="placeholder-post">
        <mat-card-header class="top-header">
            <mat-card-title fxFlex fxLayout="row">
                <div class="placeholder placeholder-30"></div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-header>
            <div mat-card-avatar class="header-image">
                <!-- <img mat-card-avatar [src]="defaultImage"> -->
                <mat-icon>account_circle</mat-icon>
            </div>
            <mat-card-title>
                <div fxFlex fxLayout="row">
                    <div class="placeholder placeholder-20"></div>
                    <div class="placeholder placeholder-10 link inline"></div>
                </div>
            </mat-card-title>
            <mat-card-subtitle>
                <div class="placeholder placeholder-50 subtitle"></div>
            </mat-card-subtitle>
            <mat-card-subtitle>
                <div class="placeholder placeholder-10 subtitle"></div>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="spacer-10"></div>
            <div class="placeholder placeholder-100 content"></div>
            <div class="placeholder placeholder-100 content"></div>
            <div class="placeholder placeholder-100 content"></div>
            <div class="placeholder placeholder-70 content"></div>
            <div class="spacer-10"></div>
            <div class="placeholder placeholder-20 link"></div>
            <div class="spacer-10"></div>
            <div class="placeholder placeholder-10 subtitle"></div>
        </mat-card-content>
        <div class="actions">
            <mat-card-actions>
                <button mat-button>
                    <mat-icon matPrefix>thumb_up</mat-icon>
                    <span>Like&nbsp;</span>
                </button>
                <button mat-button>
                    <mat-icon matPrefix>comment</mat-icon>
                    <span>Comment&nbsp;</span>
                </button>
                <button mat-button class="comment-show-hide">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </mat-card-actions>
        </div>
    </mat-card>
</ng-template>

<ng-template #sociate>
    <mat-card [ngClass]="{
            'placeholder-sociate': true, 
            'small': size=='small', 
            'medium': size=='medium', 
            'large': size=='large'
        }">
        <mat-card-header class="sociate" fxLayout="column" fxLayoutAlign="start center">
            <div mat-card-avatar class="header-image">
                <mat-icon>account_circle</mat-icon>
            </div>
            <mat-card-title class="text-center">
                <span class="bookmark">
                    <mat-icon>bookmark_border</mat-icon>
                </span>
                <div class="placeholder placeholder-70 title"></div>
                <div class="spacer-5" *ngIf="size=='small'"></div>
                <div class="placeholder placeholder-50 title"></div>
                <div class="spacer-5"></div>
                <div class="placeholder placeholder-30 link"></div>
                <ng-container *ngIf="size!='small'">
                    <div class="spacer-5"></div>
                    <div class="placeholder placeholder-60 subtitle"></div>
                    <div class="placeholder placeholder-40 subtitle"></div>
                </ng-container>
            </mat-card-title>
        </mat-card-header>

        <mat-card-footer class="text-center" *ngIf="size!='small'">
            <button mat-stroked-button color="primary">
                <mat-icon>message</mat-icon> Message
            </button>
        </mat-card-footer>

    </mat-card>

</ng-template>

<ng-template #opportunity>
    <mat-card class="placeholder-opportunity">
        <mat-card-header class="company">
            <div mat-card-avatar class="company-image">
                <div class="placeholder placeholder-100 image"></div>
            </div>
            <mat-card-title>
                <div class="placeholder placeholder-80 title"></div>
                <div class="placeholder placeholder-40 title"></div>
                <div class="placeholder placeholder-60 subtitle"></div>
                <div class="placeholder placeholder-40 subtitle"></div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-header>
            <div mat-card-avatar class="header-image">
                <mat-icon>account_circle</mat-icon>
            </div>
            <mat-card-title>
                <div fxFlex fxLayout="row">
                    <div class="placeholder placeholder-50"></div>
                    <div class="placeholder placeholder-30 link inline"></div>
                </div>
            </mat-card-title>
            <mat-card-subtitle>
                <div class="placeholder placeholder-80 subtitle"></div>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="spacer-10"></div>
            <div class="placeholder placeholder-100 content"></div>
            <div class="placeholder placeholder-100 content"></div>
            <div class="placeholder placeholder-100 content"></div>
            <div class="placeholder placeholder-70 content"></div>
            <div class="spacer-10"></div>
            <div class="placeholder placeholder-20 link"></div>
            <div class="spacer-50"></div>
        </mat-card-content>

    </mat-card>
</ng-template>

<ng-template #article>
    <div class="placeholder-article {{size}}" fxLayout="{{size=='large'?'column':'row'}}" fxFill>
        <div fxFlex="{{size=='large'?'75':'22'}}">
            <!-- <img class="image" src="{{data.image}}"> -->
            <div class="placeholder placeholder-100 image"></div>
        </div>
        <div class="attributes" fxFlex fxLayout="column">
            <div class="placeholder placeholder-100 title"></div>
            <div class="placeholder placeholder-70 title"></div>
            <div class="spacer-5"></div>
            <div *ngIf="size != 'small'" class="description">
                <div class="placeholder placeholder-100 content"></div>
                <div class="placeholder placeholder-100 content"></div>
                <div class="placeholder placeholder-70 content"></div>
            </div>
            <div class="spacer-5"></div>
            <div fxFlex fxLayout="row">
                <div class="placeholder placeholder-30 link"></div>
                <div class="placeholder placeholder-30 subtitle inline"></div>
            </div>
        </div>
    </div>
</ng-template>