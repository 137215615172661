import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

import { AccountService, MessageService } from '../../../../dependencies';

@Component({
  selector: 'app-splash-open-to-work',
  templateUrl: './splash-open-to-work.component.html',
  styleUrls: ['./splash-open-to-work.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class SplashOpenToWorkComponent implements OnInit {

  public static readonly code: string = 'splash-open-to-work';

  data: {
    title: string;
    skipUpdate: boolean;
  }

  constructor(
    private dialogRef: MatDialogRef<SplashOpenToWorkComponent>,
    private accountService: AccountService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) input?: { title: string, skipUpdate: boolean },
  ) {
    this.data = input;
  }

  ngOnInit(): void {
  }

  dismiss(event: Event, value?: boolean): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.data?.skipUpdate) {
      this.dialogRef.close(value);
    } else {
      const self = this;
      self.accountService.saveProfessionalDetails({
        openToWork: +value,
      }).pipe(first())
        .subscribe(res => {
          if (res.status.status === 'STA_SUCCESS') {
            self.accountService.saveUserPreferences({
              pref: SplashOpenToWorkComponent.code,
              value: null,
            }).pipe(first())
              .subscribe(res => {
                self.dialogRef.close({ value });
              });
          } else {
            self.messageService.simple(res.status.message);
          }
        });
    }


  }
}
