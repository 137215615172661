<div class="container" fxLayout="column" fxLayoutAlign="center center" fxFill>
    <mat-spinner *ngIf="loading" color="accent"></mat-spinner>

    <mat-card>
        <mat-toolbar color="primary">{{ input.title | translate | titlecase }}
            <div class="close" [mat-dialog-close]="false">
                <div>
                    <mat-icon matTooltip="{{ 'TOOLTIP_CLOSE' | translate }}">close</mat-icon>
                </div>
            </div>
        </mat-toolbar>

        <mat-card-content [class.mat-card-content]="device!='mobile'">
            <ng-container *ngIf="input.data && input.data.length > 0">
                <mat-list>
                    <mat-list-item *ngFor="let item of input.data">
                        {{item.firstname}} {{item.lastname}} - {{ item.mail }}
                    </mat-list-item>
                </mat-list>
            </ng-container>
            <div class="buttons" fxLayout="row" fxLayoutAlign="space-around">
                <button mat-raised-button color="warn" [mat-dialog-close]="true">{{ input.confirmButtonLabel | translate
                    }}{{ input.data?.length > 1 ? 's' : '' }}</button>
                <button mat-button [mat-dialog-close]>{{ 'BUTTON_CANCEL' | translate }}</button>
            </div>
        </mat-card-content>
    </mat-card>

</div>