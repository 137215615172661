import { UserStatus } from './interfaces';
import { ISociate, IProfession } from '../../../../interfaces';

export class User implements ISociate {

    public userId: any;
    public firstname: string;
    public lastname: string;
    public displayName: string;
    public status: UserStatus;
    public unread: number;
    public picture: string;
    public schools: string[];
    public enrollment: number;
    public graduating: number;
    public profession: IProfession;
    public resume: string;
    public schoolname: string;

    constructor(sociate?: ISociate, addOns: {
        status?: UserStatus,
        unread?: number,
    } = {}) {
        this.userId = sociate.userId;
        this.displayName = sociate.displayName;
        this.picture = sociate.picture;
        this.status = addOns.status || UserStatus.Offline;
        this.unread = addOns.unread;
        this.schools = sociate.schools;
        this.enrollment = sociate.enrollment;
        this.graduating = sociate.graduating;
        this.profession = sociate.profession;
        this.schoolname = sociate.schoolname;
    }

    public get displayStatus(): string {
        return UserStatus[this.status]?.toLowerCase();
    }

}
