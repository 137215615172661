import { merge } from 'lodash-es';
import { config as env } from './config.env';

const _config = {

  // Dev or Production
  production: false,
  staging: false,

  urls: {
    company: {
      provider: 'clearbit',
      url: 'https://autocomplete.clearbit.com/v1/companies/suggest',
      logo: 'https://logo.clearbit.com',
    },
  },

  prefixes: {
    static: {
      picture: '/pictures',
      public: '/files/public',
    },
    v1: {
      public: '/sites/default/files',
    },
    styles: {
      default: '',
      small: '/styles/small_user/public',
      medium: '/styles/medium_user/public',
      large: '/styles/large_user/public',
    }
  },

  data: {
    useLocal: true,
  },

  defaults: {
    account: {
      picture: 's3.public://sociate-user.png',
    },
    school: {
      logo: 's3.public://school-logo.png',
      banner: 's3.public://school-banner.png',
    },
    home: {
      introYouTubeVideo: 'MXiR4nUZOSI',
    }
  },

  cache: {
    disabled: false,
    level: 'SOCIATE',
    excludes: [
      {
        pathMatch: 'pattern',
        path: new RegExp(/v\d+\/auth\/.*/)
      },
      {
        pathMatch: 'pattern',
        path: new RegExp(/assets\/.*\/overrides\.[\w+\.]json/)
      }
    ]
  },

  // Where to load translations
  translations: {
    useLocal: true,
  },

  modes: {
    placeholder: true,
  },

  overrides: {
    source: './assets/data/overrides.json'
  }

}

export const config = merge(_config, env);
