import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ng2-cookies';
// import { CookieService } from 'angular2-cookie';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { intersection } from 'lodash-es';
import * as moment from 'moment';

import { StorageService } from './storage.service';
import { IUser, ITokenData, AccountStatus } from '../interfaces';
import { RequestCache } from '../cache';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '../utility';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  static readonly TOKEN = 'token';
  static readonly REFRESH_TOKEN = 'refresh-token';
  private static readonly SUPER_TOKEN = 'super-token';

  private profileSubject: Subject<IUser>;

  /**
   * Constructor of the class.
   *
   * @param {LocalStorageService} localStorage
   * @param {JwtHelper}           jwtHelper
   */
  constructor(
    private localStorage: StorageService,
    private sessionStorage: SessionStorageService,
    private jwtHelper: JwtHelperService,
    private cookie: CookieService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private cache: RequestCache,
    private configService: ConfigService,
  ) {
    this.profileSubject = new Subject();
  }

  /**
   * Method to store JWT token data to local storage.
   *
   * @param {ITokenData} data
   */
  public storeTokens(data: ITokenData): void {
    if (data.token) { this.localStorage.save(UserService.TOKEN, data.token); }
    if (data.refresh_token) { this.localStorage.save(UserService.REFRESH_TOKEN, data.refresh_token); }
    // if (data.super) { this.localStorage.save('super', data.super); }
    const profile = this.profile;
    if (profile) {
      this.profileSubject.next(profile);
      this.angulartics2GoogleTagManager.setUsername(this.username);
    }
  }

  public simulate(data: ITokenData): boolean {
    if (!this.loggedIn && !data) {
      return false;
    }

    this.localStorage.save(UserService.SUPER_TOKEN, <ITokenData>{
      token: this.localStorage.get(UserService.TOKEN),
      refresh_token: this.localStorage.get(UserService.REFRESH_TOKEN),
    });
    this.storeTokens(data);

    return true;
  }

  /**
   * endSimulation
   */
  public endSimulation(): boolean {
    const superToken = this.localStorage.get(UserService.SUPER_TOKEN);

    if (!this.loggedIn && !superToken) {
      return false;
    }

    this.storeTokens(superToken);
    this.localStorage.delete(UserService.SUPER_TOKEN);
    this.cache.clear();

    return true;
  }

  /**
   * Method to get current user profile data from JWT data.
   *
   * @returns {IUser|boolean}
   */
  public get profile(): IUser {
    return (this.loggedIn) ? this.jwtHelper.decodeToken(this.localStorage.get(UserService.TOKEN)) : undefined;
  }

  public profileAsObserable(): Observable<IUser> {
    return this.profileSubject.asObservable();
  }

  public get displayName(): string {
    const profile = this.profile;
    if (profile) {
      return [profile.firstname, profile.lastname].join(' ');
    } else {
      return 'Anonymous User';
    }
  }

  public get username(): string {
    const profile = this.profile;
    if (profile) {
      return profile[`@`];
    } else {
      return 'Anonymous';
    }
  }

  /**
   * Method to check if current user is logged in or not.
   *
   * @returns {boolean}
   */
  public get loggedIn(): boolean {
    const token = this.localStorage.get(UserService.TOKEN);
    const forceLogout = this.configService.get().forceLogout;
    return token && !this.jwtHelper.isTokenExpired(token) && (!forceLogout || this.jwtHelper.decodeToken(token)?.iat > forceLogout);
  }

  public get isSimulating(): boolean {
    // const superToken = this.localStorage.get(UserService.SUPER_TOKEN);
    // return superToken && !this.jwtHelper.isTokenExpired((<ITokenData>superToken).token);
    return !!this.localStorage.get(UserService.SUPER_TOKEN);
  }

  public verifyRole(roles: string | string[], profile: IUser = this.profile): boolean {
    roles = typeof roles === 'string' ? [roles] : roles;
    return profile?.roles && intersection(profile.roles, roles).length > 0;
  }

  public verifyEntitlements(entitlements: string | string[], profile: IUser = this.profile): boolean {
    entitlements = typeof entitlements === 'string' ? [entitlements] : entitlements;
    return profile?.entitlements && intersection(profile.entitlements, entitlements).length > 0;
  }

  public get isReadOnly(): boolean {
    return this.verifyEntitlements(['SUPPORT', 'READONLY']);
  }

  public get isActive(): boolean {
    return this.profile?.status === AccountStatus.Active;
  }

  /**
   * Method to erase data from local storage.
   */
  public erase(): void {
    this.localStorage.clear();
    this.cookie.deleteAll('/', '.sociate.io');
    // this.cookie.removeAll();
  }

  public getSession(key: string): any {
    return this.sessionStorage.retrieve(key);
  }

  public saveSession(key: string, value: any): void {
    this.sessionStorage.store(key, value);
  }
}
