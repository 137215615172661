import { Component, OnInit, Input, Output, EventEmitter, SecurityContext, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { first } from 'rxjs/operators';
import { find } from 'lodash-es';

import * as moment from 'moment';
import * as linkify from 'linkifyjs';

import { IPost, BusinessNames, IMetaData, IResponse, IComment, IThumb, ISociate } from '../../../interfaces';
import { PostService, MetadataService, UserService, IPreviewResponse, ICommentsResponse, MessageService, ConfigService, StorageService, isHtmlString, parseHtmlEntities, OverlayDialogService, OverlayDialogConfig, OverlayDialogRef, UtilityService } from '../../../dependencies';
import { MatDialog } from '@angular/material/dialog';
import { ViewLikesComponent } from './view-likes';
import { SociateComponent } from '../sociate';
import { AddPostComponent } from '../add-post';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit, OnDestroy {

  @Input() hideComments: boolean;
  @Input() hideAddComments: boolean;
  @Input() data: IPost;
  @Output() dataChange: EventEmitter<IPost>;
  @Input() size: 'small' | 'medium' | 'large';
  @Input() visible: boolean;

  @Output() tagSelected: EventEmitter<IMetaData>;
  @Output() deleted: EventEmitter<IPost>;

  supportedFormats = [
    'image/*',
    '.pdf',
    '.doc',
    '.docx',
    'application/msword',
  ].join(',');

  commentForm: FormGroup;

  isCommentSectionExpanded: boolean;
  isPostTextExpanded: boolean;

  isHtmlContent = false;
  htmlContent: any;
  sanitizedContent: any;

  specializations: Array<IMetaData>;
  tags: IMetaData[];
  createdDate: string;
  comment: IComment;
  pageToken: number;
  loading: boolean;

  currentUser;

  uploader: FileUploader;
  hasDropZoneOver: boolean;

  overlayRef: OverlayDialogRef;

  constructor(
    private postService: PostService,
    private metadataService: MetadataService,
    private userService: UserService,
    private messageService: MessageService,
    private configService: ConfigService,
    private localStorage: StorageService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private overlay: OverlayDialogService,
    private utils: UtilityService,
  ) {
    this.commentForm = new FormGroup({
      commentControl: new FormControl(),
    });

    this.dataChange = new EventEmitter<IPost>();
    this.tagSelected = new EventEmitter<IMetaData>();
    this.deleted = new EventEmitter<IPost>();
    this.comment = <IComment>{
      attachments: [],
    };

    // this.uploader = this.fileService.getUploader();
    this.uploader = new FileUploader({
      url: `${ this.configService.getApiUrl() }/file/stream`,
      authTokenHeader: 'Authorization',
      authToken: (() => {
        return `Bearer ${ this.localStorage.get(UserService.TOKEN) }`;
      })(),
    });

  }

  ngOnInit() {
    this.visible = true;

    // if (!this.data || !this.data.content || this.data.content.trim().length === 0) {
    //   this.visible = false;
    //   return;
    // }

    const self = this;

    if (this.data) {
      this.pageToken = this.data.pageToken;
      // moment.unix to be used for 10 digit unix timestamp
      this.createdDate = moment.unix(this.data.createdTimestamp).fromNow();

      this.data.author.profileUrl = this.userService.username === this.data.authorId ? '/my' : `/users/${ this.data.author.userId }`;
      this.data.liked = this.data.likes?.length > 0 && this.data.likes.find(l => l.userId === this.userService.username)?.userId.length > 0;

      if (this.data.commentCount === 0 && !this.data.comments) {
        this.data.comments = [];
      }

      this.data.content = this.utils.linkifyContent(this.data.content);

      if (isHtmlString(this.data.content)) {
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.data.content);
        this.sanitizedContent = this.sanitizer.sanitize(SecurityContext.HTML, this.data.content);
        this.isHtmlContent = true;
      } else {
        this.sanitizedContent = this.htmlContent = this.data.content;
      }

      const plainTextContent = parseHtmlEntities(this.sanitizer.sanitize(SecurityContext.HTML, this.data.content)).replace(/<[^>]*>/g, '');
      const linkified = linkify.find(plainTextContent);
      if (linkified.length > 0) {
        linkified.map((l) => {
          if (l.type === 'url' && !this.data.preview) {
            this.metadataService
              .generatePreview(l.href)
              .pipe(first())
              .subscribe((res: IPreviewResponse) => {
                self.data.preview = res.data;
              });
          }
        });
      }

      this.metadataService.get(BusinessNames.Specializations)
        .pipe(first())
        .subscribe(
          (metaData) => {
            self.specializations = metaData;
            self.tags = self.getTagNames(self.data.tags);
          }
        );

      this.uploader.onAfterAddingFile = this.fileDropListener;
      this.uploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
        self.fileUploaded.apply(self, [item, response, status, headers]);
      };
    }

    this.size = this.size || 'large';
    this.currentUser = this.userService.profile;

    this.hasDropZoneOver = false;
    this.isPostTextExpanded = false;

  }

  ngOnDestroy() {
    this.overlayRef?.close(true);
  }

  getTagNames(tagIds: string | string[]): Array<IMetaData> {
    if (!tagIds) { return [] };
    if (!Array.isArray(tagIds)) { tagIds = tagIds.split('|'); }

    const tags = [];
    tagIds.map((tagId) => {
      const tag = find(this.specializations, ['id', +tagId]);
      // const tag = this.specializations.find(spcl => spcl.id === +tagId);
      if (tag) {
        tags.push(tag);
      }
    })
    return tags;
  }

  likePost(liked: boolean): void {
    const self = this;
    this.postService
      .setLike(this.data.postId, { liked: liked })
      .pipe(first())
      .subscribe(
        (res) => {
          const post: IPost = self.data;

          if (res?.status.status === 'STA_SUCCESS') {
            // post.likeCount = liked ? ++post.likeCount : --post.likeCount;
            post.liked = liked;

            // self.postService
            //   .getLikes(post.postId)
            //   .subscribe(
            //     likesResp => {
            //       if (likesResp?.status.status === 'STA_SUCCESS') {
            //         post.likes = likesResp.data.likes;
            //       }
            //     }
            //   );

            if (liked) {
              // post.likeCount++;
              post.likes.push({
                ...self.userService.profile,
                userId: self.userService.username,
              });
            } else {
              // post.likeCount--;
              post.likes = post.likes.filter(l => l.userId !== self.userService.username);
            }
            post.likeCount = post.likes.length;

          } else {
            self.postService
              .getLikes(post.postId)
              .subscribe(
                likesResp => {
                  if (likesResp?.status.status === 'STA_SUCCESS') {
                    post.likes = likesResp.data.likes;
                    post.likeCount = post.likes.length;

                    post.liked = post.likes?.length > 0 && post.likes.find(l => l.userId === self.userService.username)?.userId.length > 0;
                  }
                }
              );
          }

        }

      );
  }

  selectTag(tag: IMetaData): void {
    this.tagSelected.emit(tag);
  }

  openUserDetails(event: any): void {
    this.overlayRef = this.overlay.open(SociateComponent, {
      parent: event.srcElement,
      data: this.data.author,
      config: {
        hasBackdrop: false,
      }
    } as OverlayDialogConfig);
  }

  closeUserDetails(): void {
    setTimeout((overlayRef) => {
      overlayRef.close();
    }, 100, this.overlayRef);
  }

  editPost(): void {
    // Open edit post dialog
    const dialogRef = this.dialog.open(AddPostComponent, {
      data: this.data,
      panelClass: 'soc-dialog',
      backdropClass: 'soc-dialog-backdrop',
    });
    dialogRef.afterClosed().pipe(first()).subscribe((result: IPost) => {
      if (result) {
        this.dataChange.emit({
          ...this.data,
          content: result.content,
          tags: result.tags,
          global: result.global,
          attachments: result.attachments,
        });
      }
    });
  }

  deletePost(): void {

    this.postService
      .delete(this.data.postId)
      .pipe(first())
      .subscribe(
        (res: IResponse) => {
          if (res?.status.status === 'STA_SUCCESS') {
            this.deleted.emit(this.data);
          }
        }
      );
  }

  getComments(): void {
    const self = this;
    this.postService
      .getComments(this.data.postId, this.pageToken)
      .pipe(first())
      .subscribe(
        (resp: ICommentsResponse) => {
          resp.data.comments.map((cmt) => {
            // cmt.createdDate = moment(cmt.createdDate).fromNow();
            cmt.isCommentTextExpanded = false;
          })
          Array.prototype.unshift.apply(self.data.comments, resp.data.comments.reverse());
          self.pageToken = resp.data.pageToken;
        },
        (error) => {
          this.messageService.simple(error.message);
          // console.log('Dumping error from loadPreviousComments');
          // console.log(error);
        }
      );
  }

  addComment(event: Event, formDirective: FormGroupDirective): void {
    const self = this;
    this.loading = true;
    this.commentForm.disable();

    this.postService
      .addComment({
        postId: this.data.postId,
        comment: {
          content: this.comment.content,
          attachments: this.comment.attachments.map(item => item.id)
        }
      })
      .pipe(first())
      .subscribe(
        (res: IResponse) => {
          if (res?.status.status === 'STA_SUCCESS') {
            self.data.comments = self.data.comments.concat(res.data.comments);
            ++self.data.commentCount;
            self.data.commented = true;
            // self.pageToken = res.data.pageToken;
            // self.comment = '';
            // self.commentForm.controls['commentControl'].markAsUntouched();
            // self.commentForm.reset();
            self.comment = <IComment>{
              attachments: [],
            };
            formDirective.resetForm({
              commentControl: '',
            });
            self.commentForm.enable();
          } else {
            this.messageService.simple(res.status.message);
          }
          self.loading = false;
        });
  }

  fileOverHandler(e: any): void {
    this.hasDropZoneOver = e;
  }

  fileChangeListener(): void {
    // this.loadFile($event.target.files[0]);
  }

  fileDropListener(droppedFile: FileItem): void {
    droppedFile.upload();
  }

  fileUploaded(item: FileItem, response: string): void {
    const resp: IResponse = JSON.parse(response);
    if (!this.comment.attachments) {
      this.comment.attachments = Array<IThumb>();
    }
    this.comment.attachments.push({
      id: resp.data.id,
      url: resp.data.file,
      type: item.file.type,
      file: item,
    });
  }

  openView(likes: any): void {
    const dialogRef = this.dialog.open(ViewLikesComponent, {
      // panelClass: 'soc-dialog',
      // backdropClass: 'soc-dialog-backdrop',
      data: {
        postId: this.data.postId,
        likes: likes || this.data.likes,
        count: this.data.likes?.length,
      },
    });
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.data.likes = result;
      }
    });
  }
}
