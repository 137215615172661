
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '../utility/config.service';
import { IResponse } from '../../../interfaces';
import { ICompanyResponse } from '../interfaces';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class CompanyService {

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  /*
  * Find companies based on search...
  */
  public findCompanies(query: string): Observable<ICompanyResponse> {

    return this.http
      .get(`${this.configService.get().urls.company.url}?query=${query}`)
      .pipe(
        map((response: ICompanyResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Company suggestions.'))
      );
  }

  public getCompanyLogo(domain: string): Observable<IResponse> {
    return this.http
      .get(`${this.configService.get().urls.company.logo}/${domain}`)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Preview data.'))
      );
  }

}
