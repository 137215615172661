import { Output, EventEmitter, Directive, OnInit, OnDestroy, ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import { ResponsiveState, UserAgentInfoDirective } from 'ngx-responsive';

@Directive({
    selector: '[appUserAgentInfo]'
})
export class UserAgentDirective extends UserAgentInfoDirective implements OnInit, OnDestroy {
    @Output() info: EventEmitter<any> = new EventEmitter();
    constructor(
        public _responsiveState: ResponsiveState,
        public cd: ChangeDetectorRef,
        @Inject(PLATFORM_ID) _platformId,
    ) {
        super(_responsiveState, cd, _platformId);
    }
}
