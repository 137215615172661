<div fxLayout="column" fxLayoutAlign="center center" fxFill>

    <mat-card>
        <mat-toolbar color="primary">
            <div>{{ (data?.title || 'SPLASH_OPEN_TO_WORK_TITLE') | translate }}</div>
            <div class="close">
                <mat-icon (click)="dismiss($event)" matTooltip="{{ 'TOOLTIP_CLOSE' | translate }}">close</mat-icon>
            </div>
        </mat-toolbar>


        <mat-card-content>
            <div class="buttons" fxLayout="row" fxLayoutAlign="space-around">
                <button mat-raised-button color="primary" (click)="dismiss($event, true)">{{ 'BUTTON_YES' | translate
                    }}</button>
                <button mat-raised-button color="accent" (click)="dismiss($event, false)">{{ 'BUTTON_NO' | translate
                    }}</button>
            </div>
        </mat-card-content>
    </mat-card>

</div>