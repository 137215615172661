import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { Environment } from './environments/environment';
import { AppModule } from './app/app.module';

if ((Environment.production || Environment.staging)
  &&
(!/local/.test(document.location.host) && !/127.0.0.1/.test(document.location.host))
) {
  enableProdMode();
}

console.log('Running in', isDevMode() ? 'development' : 'production', 'mode');
platformBrowserDynamic().bootstrapModule(AppModule);
