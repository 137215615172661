import { OverlayRef } from '@angular/cdk/overlay';

export class OverlayDialogRef {

  private isMouseOver: boolean;

  constructor(private overlayRef: OverlayRef) { }

  close(force?: boolean): void {
    if (force || !this.isMouseOver) {
      this.overlayRef.dispose();
    }
  }

  setMouseOver(value: boolean): void {
    this.isMouseOver = value;
  }
}
