import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { startCase, template, get } from 'lodash-es';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { pako } from 'pako';

const noop: (...args: any[]) => void = () => { };

const isHtmlString: (content: string) => boolean = content => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(content, 'text/html');
    return parsedHtml.body.firstElementChild === parsedHtml.body.firstChild;
};

const parseHtmlEntities: (str: string) => string = str => {
    return str.replace(/&#([0-9]{1,4});/gi, function (match, numStr) {
        const num = parseInt(numStr, 10); // read num as normal number
        return String.fromCharCode(num);
    });
}

@Injectable({
    providedIn: 'root',
})
class UtilityService {

    constructor(
        private jwtHelper: JwtHelperService,
    ) { }

    formatValue(field: any, value: any): string {
        if (value === null || value === undefined) {
            return '';
        }
        switch (field.type) {
            case 'longdatetime':
                return moment.unix(value).format('dddd, MMMM Do YYYY, h:mm:ss a');

            case 'shortdatetime':
                return moment.unix(value).format('MM/DD/YYYY h:mm:ss a');

            case 'template':
                const compiled = template(field.template);
                return compiled(value);

            case 'property':
                return get(value, field.property);

            case 'enum':
                return startCase(field.enum[value]);

            case 'custom':
                return field.handler(value);

            case 'array':
                return value.join(', ');

            case 'string':
            default:
                return value;
        }
    }

    getReadableDateTime(timestamp: number): string {
        return !timestamp || timestamp === 0 ? 'Never' : moment.unix(timestamp).fromNow();
    }

    decrypt(data: string): any {
        return this.jwtHelper.decodeToken(data);
    }

    // compress(data: any): string {
    //     return pako.deflate(JSON.stringify(data), { to: 'string' });
    // }

    // decompress(compressed: string): any {
    //     return JSON.parse(pako.inflate(compressed, { to: 'string' }));
    // }

    getAccessCode(length: number): number {
        return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1));
    }

    setFocus(element: string | any, timeout: number = 0) {
        let e = element as any;
        if (typeof e === 'string') {
            e = document.querySelector(`.mat-input-element[name="${ element }"]`) as any;
        }
        if (e) {
            setTimeout(() => e.focus(), timeout);
        }
    }

    exportToFile(filename: string, data: string, type: string = 'text/plain'): void {
        if (!type?.startsWith('application')) {
            type = `application/${ type }`;
        }
        const el = document.createElement('a');
        el.setAttribute('href', `data:${ type };charset=utf-8,${ encodeURIComponent(data) }`);
        el.setAttribute('download', filename || 'data.txt');
        el.style.display = 'none';
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
    }

    linkifyContent(content: string): string {
        return content.replace(
            /<\w+>(https?:\/\/[^\s"<]+)|<\w+>(https?:\/\/[^\s"<]+)|<.*(?<!href|src)=".*>(https?:\/\/[^\s"<]+)|(?<!="|>)(https?:\/\/[^\s"<]+)/g,
            '<a href="$1$2$3$4" target="_blank">$1$2$3$4</a>',
        );
    }
}

export {
    noop,
    isHtmlString,
    parseHtmlEntities,
    UtilityService,
};
