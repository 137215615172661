import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import { AuthService } from '../services';
import { AccountStatus, ClientService, UserService, ConfigService, MessageService, RouterService, EmailService, IPreference, AccountService } from '../../../shared';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['login.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  @ViewChild('usernameControl')
  usernameControl: ElementRef;

  // @ViewChild('passwordControl', { static: false })
  // passwordControl: ElementRef;

  username: string;
  password: string;

  public hidePassword = true;
  public loading = false;
  public device: string;
  public returnUrl: string;
  public redirect: string;
  public emailPattern: RegExp;

  /**
   * Constructor of the class.
   *
   * @param {AuthService}     authService
   * @param {UserService}     userService
   * @param {MessageService}  messageService
   * @param {Router}          router
   */
  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private userService: UserService,
    private messageService: MessageService,
    private clientService: ClientService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private emailService: EmailService,
    private accountService: AccountService,
  ) {
    this.loginForm = new FormGroup({
      usernameControl: new FormControl(),
      passwordControl: new FormControl(),
    });
  }

  // On component init we want to set focus to username / email input.
  ngOnInit(): void {
    // Reset form data
    this.device = this.clientService.device;
    this.emailPattern = this.emailService.getEmailPattern();
    this.username = this.userService.getSession('email') || this.configService.debug?.user ? this.configService.debug.user.email : '';
    this.password = this.userService.getSession('passwd') || this.configService.debug?.user ? this.configService.debug.user.password : '';

    // reset login status
    // this.authService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
    this.redirect = this.activatedRoute.snapshot.queryParams['redirect'];

    // Remove loading
    this.loading = false;
  }

  /**
   * Method to make actual login
   *
   * @param {Event} event
   */
  public login(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    this.loading = true;

    const self = this;
    const user = this.loginForm.controls['usernameControl'].value;
    const passwd = this.loginForm.controls['passwordControl'].value;

    this.authService
      .login({ email: user, passwd: window.btoa(passwd) }).pipe(first())
      .subscribe(
        (data) => {

          if (data.status.status === 'STA_SUCCESS') {

            // Fetch user profile from token
            const profile = this.userService.profile;

            // this.loading = false;
            // this.userService.saveSession('anchor', this.redirect ?? this.returnUrl);

            // Attach message
            self.messageService.simple(`Welcome ${ profile.firstname }!`);

            // this.activatedRoute.queryParams.subscribe((params: Params) => {
            if (self.redirect) {
              return window.location.href = self.redirect;
            } else if (self.returnUrl) {
              return self.router.navigateByUrl(self.returnUrl);
            } else {

              self.accountService
                .getUserPreferences().pipe(first())
                .subscribe(
                  (data: Array<IPreference>) => {
                    self.userService.saveSession('preferences', data);
                  },
                  (error) => {
                    self.messageService.simple(error.message);
                  },
                  () => {
                    self.routerService.navigate(profile); //.pipe(first()).subscribe((result) => {
                  }
                );

              //   return;
              // });
            }
            // });

          } else if (data.status.status === 'ERR_GEN' || data.status.status === 'ERR_DB') {
            self.translate.get('UNEXPECTED_ERROR').pipe(first()).subscribe((msg: string) => {
              self.messageService.simple(`${ msg }`);
              // Clear local storage data
              self.userService.erase();
              self.loading = false;
            });
          } else {
            self.translate.get('LOGIN_FAILED').pipe(first()).subscribe((msg: string) => {
              self.messageService.simple(msg);
              self.loading = false;
            });
          }

        },
        (error) => {

          this.translate.get('UNEXPECTED_ERROR').pipe(first()).subscribe((msg: string) => {
            self.messageService.simple(`${ msg }`);

            // Clear local storage data
            self.userService.erase();

            // this.ngOnInit();
            self.loading = false;
          });

        }
      )
      ;
  }
}
