import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { Observable, Observer } from 'rxjs';
import { first } from 'rxjs/operators';

import { UserService, ConfigService, RouterService } from '../services';

/**
 * This class implements a guard for routes that require successful authentication.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate, CanLoad {
  /**
   * Constructor of the class.
   *
   * @param {UserService} userService
   * @param {Router}      router
   */
  constructor(
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private userService: UserService,
    private router: Router,
    private configService: ConfigService,
    private routerService: RouterService,
  ) { }

  /**
   * Purpose of this guard is check that current user has been authenticated to application. If user is not
   * authenticated he/she is redirected to login page.
   *
   * @param {ActivatedRouteSnapshot}  route
   * @param {RouterStateSnapshot}     state
   * @returns {Observable<boolean>}
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.processAuthenticatedRoute(route, state);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.processAuthenticatedRoute(route);
    // if (!this.userService.loggedIn) {
    //   this.router.navigate(['/auth/login'], { queryParams: { returnUrl: location.hash.replace('#', '') } });
    //   return false;
    // }
    // return true;
  }

  private processAuthenticatedRoute(route: ActivatedRouteSnapshot | Route, state?: RouterStateSnapshot): Observable<boolean> {

    const self = this;
    const u = state?.url || location.hash.replace('#', '');
    return new Observable((observer: Observer<boolean>) => {
      self.configService.refresh(true).pipe(first()).subscribe(
        (refreshed: boolean) => {
          if (this.userService.loggedIn) {
            this.angulartics2GoogleTagManager.setUsername(this.userService.username);
            observer.next(true);
          } else {
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state?.url || location.hash.replace('#', '') } });
            observer.next(false);
          }
          observer.complete();
        },
        (error: any) => {
          observer.next(true);
          observer.complete();
        }
      );
    });

    // if (this.userService.loggedIn) {
    //   this.angulartics2GoogleTagManager.setUsername(this.userService.username);
    //   return true;
    // } else {
    //   this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    //   return false;
    // }

  }
}
