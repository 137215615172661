<div class="container" [class.simulating]="isSimulating">
  <mat-toolbar color="primary" class="primary">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFill>

      <div class="logo-container" fxLayout="row" fxLayoutAlign="space-around center" [routerLink]="['/home']">
        <span class="logo" text-center>
          <img src="assets/img/logo.png">
        </span>
        <span class="title">Sociate</span>
      </div>
      <span fxFlex></span>

      <span class="buttons" fxLayout="row" fxLayoutAlign="start center">
        <button *ngIf="showLogout()" mat-button [routerLink]="['/auth/logout']">{{ 'HEADER_BUTTON_LOGOUT' |
          translate }}
        </button>

      </span>
    </div>
  </mat-toolbar>

  <mat-toolbar *ngIf="isSimulating" color="warn" class="secondary">
    <div class="simulation-container" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="message-panel">
        Simulating User: {{ userService.displayName }} | {{ userService.profile.email }} | {{ userService.username }}
      </span>
      <div class="action-panel">
        <button mat-raised-button color="accent" [disabled]="loading"
          (click)="dismiss()">{{ 'BUTTON_END_SIMULATION' | translate }}</button>
      </div>
    </div>
  </mat-toolbar>

</div>