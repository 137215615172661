import { NgModule } from '@angular/core';

import { ExternalModules } from '../../../external';
import { TranslationModule } from '../../../translation';

import { FixedComponent } from './fixed.component';

@NgModule({
  imports: [
    ...ExternalModules,
    TranslationModule,
    // AuthenticatedLayoutRoutingModule,
  ],
  exports: [
  ],
  declarations: [
    FixedComponent,
  ],
})

export class FixedLayoutModule { }
