
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { IResponse, INotificationTopic, TNotificationTopicType } from '../../../interfaces';
import { ConfigService } from '../utility/config.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationTopicService {

  // private placesConfig: any = this.configService.get('places_api');
  // private client: any = google.createClient({ key: this.placesConfig.KEY });

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  public save(data: INotificationTopic): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/admin/notifications/topics`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to save setting.'))
      );
  }


  public getAll(topicType: TNotificationTopicType, pageToken?: number, recordCount?: number): Observable<IResponse> {

    let filters = '';    
    filters += topicType ? `t=${ topicType }&` : '';
    filters += pageToken ? `p=${ pageToken }&` : '';
    filters += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/admin/notifications/topics?${ filters }`)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve data.'))
      );
  }

  public delete(data: string[]): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/admin/notifications/topics/deleteSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to delete the messages.'))
      );
  }

  public toggle(data: string[]): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/admin/notifications/topics/toggleSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to toggle status of the messages.'))
      );
  }

}
