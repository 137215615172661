import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, UrlSegment, CanActivate } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { first } from 'rxjs/operators';

import { RouterService, AccountStatus, UserService } from '../services';

/**
 * This class implements a guard for routes that require successful authentication.
 */
@Injectable({
  providedIn: 'root',
})
export class ActivationGuard implements CanActivate, CanLoad {
  /**
   * Constructor of the class.
   *
   * @param {UserService} userService
   * @param {Router}      router
   */
  constructor(
    private userService: UserService,
    private routerService: RouterService,
  ) { }

  /**
   * Purpose of this guard is check that current user has been authenticated to application. If user is not
   * authenticated he/she is redirected to login page.
   *
   * @param {ActivatedRouteSnapshot}  route
   * @param {RouterStateSnapshot}     state
   * @returns {Observable<boolean>}
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.processActivationRoute();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    // return this.userService.isActive;
    return this.processActivationRoute();
  }

  private processActivationRoute(): boolean {
    // return new Observable((observer: Observer<boolean>) => {
    if (!this.userService.isActive) {
      this.routerService.navigate();
    }
    return true;
    // observer.next(true);
    // observer.complete();

    // if (this.userService.isActive) {
    //   observer.next(true);
    //   observer.complete();
    // } else {
    //   this.routerService.navigate().pipe(first()).subscribe((result) => {
    //     observer.next(result);
    //     observer.complete();
    //   });
    // }
    // });
  }
}
