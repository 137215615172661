
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, isDevMode, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { ConfigService } from '../utility/config.service';
import { IResponse } from '../../../interfaces';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { StorageService, UserService } from '../functional';

@Injectable()
export class FileService {

  private uploader: FileUploader;
  public fileUploaded: EventEmitter<{
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  }>;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private localStorage: StorageService,
  ) {

    const self = this;
    this.fileUploaded = new EventEmitter<any>();

    this.uploader = new FileUploader({
      url: `${this.configService.getApiUrl()}/file/stream`,
      authTokenHeader: 'Authorization',
      authToken: (() => {
        return `Bearer ${this.localStorage.get(UserService.TOKEN)}`;
      })(),
    });

    this.uploader.onAfterAddingFile = this.fileDropListener;
    this.uploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      self.fileUploaded.emit({
        item: item,
        response: response,
        status: status,
        headers: headers,
      });
    };

  }

  private fileDropListener(droppedFile: FileItem): void {
    droppedFile.upload();
  }


  public upload(file: { name: string, content: string, size: number, height: number, width: number }): Observable<IResponse> {

    return this.http
      .post(`${this.configService.getApiUrl()}/file`, file)
      .pipe(
        map((res: IResponse) => res),
        catchError((error: any) => observableThrowError(error || 'Failed to upload file.'))
      );
  }

  public delete(fileId: number): Observable<IResponse> {

    return this.http
      .delete(`${this.configService.getApiUrl()}/file/${fileId}`)
      .pipe(
        map((res: IResponse) => res),
        catchError((error: any) => observableThrowError(error || 'Failed to delete file.'))
      );
  }

  // TODO: Use this uploader wherever file/stream is being used.
  public getUploader(): FileUploader {
    return this.uploader;
  }

}
