<mat-card [ngClass]="size">

  <mat-card-header class="sociate" fxLayout="column" fxLayoutAlign="start center">
    <div mat-card-avatar class="header-image" [class.disable]="disableUserLink">
      <img appDefault mat-card-avatar [routerLink]="pictureLink" src="{{data.picture}}">
      <div class="icon-container edit" *ngIf="editable" [routerLink]="pictureLink">
        <mat-icon matPrefix>edit</mat-icon>
      </div>
    </div>
    <mat-card-title class="text-center name-container">
      <div class="left-icons">
        <span class="resume {{openToWork? 'open-to-work': ''}}" *ngIf="showResumeIcon && data.resume">
          <mat-icon class="material-icons-round"
            matTooltip="{{ data.displayName }} has a resume{{openToWork? ' and is open to work': ''}}">file_present</mat-icon>
        </span>
        <span class="open-to-work" *ngIf="openToWork && !data.resume">
          <mat-icon class="material-icons-round" matTooltip="{{ data.displayName }} is open to work">work</mat-icon>
        </span>
      </div>
      <div class="right-icons">
        <span class="bookmark" *ngIf="!isLoggedInUser && (!isRecruiter || data.contactme)">
          <mat-icon (click)="bookmarkMe()" matTooltip="Add {{ data.displayName }} to your bookmarks">{{ isBookmarked?
            'bookmark': 'bookmark_border' }}</mat-icon>
        </span>
      </div>
      <span class="name router-link underline-hover" [routerLink]="personalLink" [class.disable]="disableUserLink">
        <span *ngIf="data.displayName?.length <= nameLength[size]">{{data.displayName}}</span>
        <span *ngIf="data.displayName?.length > nameLength[size]"
          matTooltip="{{data.displayName}}">{{data.displayName?.trim().substr(0,
          nameLength[size]-3)}}...</span>
      </span>
    </mat-card-title>
    <mat-card-title class="text-center school">
      <span *ngIf="data.schools" class="university">{{ data.schools[0] | clean : 10 }}</span>
      <span *ngIf="data.graduating" class="year">'{{data.graduating.toString().substring(2)}}</span>
      <span *ngIf="!data.graduating && data.enrollment" class="year">'{{data.enrollment.toString().substring(2)}}</span>
    </mat-card-title>
    <mat-card-subtitle class="text-center profession" [class.edit]="editable"
      *ngIf="showProfession && data.profession?.company" [routerLink]="professionalLink">
      <span class="work">{{ data.profession.title }} @ {{ data.profession.company }}</span>
      <mat-icon matSuffix *ngIf="editable">edit</mat-icon>
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-footer class="text-center" *ngIf="messaging && (!isRecruiter || data.contactme)">
    <button mat-stroked-button color="primary" (click)="messageSociate()">
      <mat-icon>message</mat-icon> Message
    </button>
  </mat-card-footer>

</mat-card>