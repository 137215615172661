import { Injectable } from '@angular/core';
import { get } from 'lodash-es';

import { ConfigService } from '../utility/config.service';
import { PathService } from '../../utilities';

@Injectable({
  providedIn: 'root',
})
export class ImageService {

  constructor(
    private configService: ConfigService,
    private pathService: PathService,
  ) { }

  public resolvePictureUrl(picture: string, style: string = 'default'): string {
    if (!picture) {
      return picture;
    }

    return `${this.pathService.resolveFileUrl(picture, get(this.configService.get().prefixes, `styles.${style}`))}?${Math.random().toString()}`;
  }

}
