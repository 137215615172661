import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { map, tap, startWith } from 'rxjs/operators';
import { Observable, of, VirtualTimeScheduler } from 'rxjs';

import { ConfigService, RequestCache } from '../services';
import { IConfig } from '../../interfaces';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class CachingInterceptor implements HttpInterceptor {

    constructor(
        private cache: RequestCache,
        private configService: ConfigService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const self = this;

        if (!this.isCachable(req)) {
            return next.handle(req);
        }

        const cachedResponse = this.cache.get(req);

        // cache-then-refresh
        if (req.headers.get('x-refresh')) {
            const results$ = this.sendRequest(req, next);
            return cachedResponse ?
                results$.pipe(startWith(cachedResponse)) :
                results$;
        }

        // cache-or-fetch
        return cachedResponse ?
            of(cachedResponse) : this.sendRequest(req, next);

    }

    /** Is this request cachable? */
    private isCachable(req: HttpRequest<any>): boolean {
        const config = this.configService.get ? this.configService.get() : undefined;
        // const overrides = this.configService.overrides;
        // return !this.configService.get()?.cache?.disabled &&

        return (
            // !(overrides?.cache?.disabled) ||
            !config?.cache?.disabled
        ) &&
            // Only GET requests are cachable
            (req.method === 'GET' || req.method === 'OPTIONS')
            && this.isPathIncluded(config, req.url)
            && !this.isPathExcluded(config, req.url);
    }

    private isPathIncluded(config: IConfig, url: string): boolean {
        return config?.cache?.level === 'ALL'
            || url.match(new RegExp('http(s)?://(\\w+\\.)*sociate\\.io(:\\d{2,5})?/.+')) !== null
            || (config?.cache?.level === 'API' && url.startsWith(this.configService.getApiUrl()))
            ;
    }

    private isPathExcluded(config: IConfig, url: string): boolean {
        config?.cache.excludes.forEach(route => {
            if (route.pathMatch === 'full' && route.path.toString() === url
                || route.pathMatch === 'pattern' && url.match(route.path)) {
                return true;
            }
        });
        return false;
    }

    /**
     * Get server response observable by sending request to `next()`.
     * Will add the response to the cache on the way out.
     */
    private sendRequest(
        req: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {

        // No headers allowed in search request
        // const noHeaderReq = req.clone({ headers: new HttpHeaders() });

        return next.handle(req).pipe(
            tap(event => {
                // There may be other events besides the response.
                if (event instanceof HttpResponse) {
                    this.cache.put(req, event); // Update the cache.
                }
            })
        );
    }

}
