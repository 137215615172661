import { Pipe, PipeTransform } from '@angular/core';
import { workerData } from 'worker_threads';
import { skip } from 'rxjs/operators';

@Pipe({ name: 'clean' })
export class CleanPipe implements PipeTransform {

  transform(message: string, length?: number, addEllipsis?: boolean): any {
    if (length && message?.length > length) {
      let result: string;
      if (message.indexOf(' ') > 0) {
        const words = message.substring(0, length + 1).split(' ');
        words.pop();
        result = words.join(' ');
      } else {
        result = message.substring(0, length);
      }

      if (addEllipsis) {
        result = message.substring(0, length - 3) + '...';
      }

      return result;
    }

    return message;
  }

}
