import { MetadataService } from './metadata.service';
import { PostService } from './post.service';
import { NotificationService } from './notification.service';
import { SociateService } from './sociate.service';
import { FileService } from './file.service';
import { CompanyService } from './company.service';
import { OpportunityService } from './opportunity.service';
import { SearchService } from './search.service';
import { InvitationService } from './invitation.service';
import { SettingService } from './setting.service';
import { PreferenceService } from './preference.service';
import { ReferralService } from './referral.service';
import { DashboardService } from './dashboard.service';
import { ChatMessageService } from './message.service';
import { NotificationTopicService } from './notification-topic.service';

export * from './metadata.service';
export * from './post.service';
export * from './sociate.service';
export * from './file.service';
export * from './company.service';
export * from './opportunity.service';
export * from './search.service';
export * from './invitation.service';
export * from './notification.service';
export * from './setting.service';
export * from './preference.service';
export * from './referral.service';
export * from './dashboard.service';
export * from './account.service';
export * from './message.service';
export * from './notification-topic.service';

export const DataServices = [
  // MetadataService,
  PostService,
  NotificationService,
  // SociateService,
  FileService,
  CompanyService,
  OpportunityService,
  // SearchService,
  InvitationService,
  ChatMessageService,
  // ReferralService,
  // SettingService,
  NotificationTopicService,
];
