<div fxLayout="column" fxLayoutAlign="center center" fxFill>

    <mat-card>
        <mat-toolbar color="primary">
            <div>{{ (data?.title || 'SPLASH_ADD_OPPORTUNITY_TITLE') | translate }}</div>
            <div class="close">
                <mat-icon (click)="dismiss($event)" matTooltip="{{ 'TOOLTIP_CLOSE' | translate }}">close</mat-icon>
            </div>
        </mat-toolbar>

        <mat-card-content>
            <!-- <div class="title">{{ 'SPLASH_ADD_OPPORTUNITY_TITLE' | translate }}</div> -->
            <mat-stepper orientation="vertical" class="stepper" selectedIndex="1">
                <mat-step editable="false" completed="true" state="completed">
                    <ng-template matStepLabel>
                        <span>{{ 'SPLASH_ADD_OPPORTUNITY_1' | translate }}</span>
                        <span class="app-clickable highlight-link" (click)="dismiss($event, '/hub/opportunities')">{{
                            'SPLASH_ADD_OPPORTUNITY_LINK_1'
                            | translate }}</span>
                    </ng-template>
                </mat-step>
                <mat-step editable="false" completed="true" state="completed">
                    <ng-template matStepLabel>
                        <span class="app-clickable highlight-link"
                            (click)="dismiss($event, 'mailto:contact@sociate.io')">{{
                            'SPLASH_ADD_OPPORTUNITY_LINK_2'
                            | translate }}</span>
                        <span>{{ 'SPLASH_ADD_OPPORTUNITY_2' | translate }}</span>
                    </ng-template>
                </mat-step>
            </mat-stepper>
            <!-- <div class="footer">
                <button mat-raised-button color="primary" (click)="dismiss('/hub/opportunities')">{{ 'BUTTON_ADD_OPPORTUNITY' | translate }}</button>
            </div> -->
        </mat-card-content>
    </mat-card>

</div>