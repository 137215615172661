export const config = {

  // Dev or Production
  production: false,
  staging: false,

  tracking: {
    provider: 'google',
    environmentId: 'env-7',
    token: 'DwX7yTGf_vnoYRAKGOgbXQ',
  },

  urls: {
    api: 'https://api.dev.sociate.io/v1',
    socket: 'https://api.dev.sociate.io:9443/v1',
    static: 'https://static.dev.sociate.io',
  },

};
