import { Injectable } from '@angular/core';
import { Router, CanLoad, Route, UrlSegment, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { first } from 'rxjs/operators';

import { ConfigService, RouterService } from '../services';

/**
 * This class implements a guard for routes that require successful authentication.
 */
@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanLoad, CanActivate, CanActivateChild {

  /**
   * Constructor of the class.
   *
   * @param {UserService} userService
   * @param {Router}      router
   */
  constructor(
    private router: Router,
    private configService: ConfigService,
    private routerService: RouterService,
  ) { }

  /**
   * Purpose of this guard is check that current user has been authenticated to application. If user is not
   * authenticated he/she is redirected to login page.
   *
   * @param {ActivatedRouteSnapshot}  route
   * @param {RouterStateSnapshot}     state
   * @returns {Observable<boolean>}
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.processMaintenanceRoute(route);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.processMaintenanceRoute(route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.processMaintenanceRoute(childRoute);
  }

  private processMaintenanceRoute(route?: ActivatedRouteSnapshot | Route, state?: RouterStateSnapshot): Observable<boolean> {

    const self = this;
    return new Observable((observer: Observer<boolean>) => {
      self.configService.refresh(true).pipe(first()).subscribe(
        (refreshed: boolean) => {
          if (self.configService.get().modes.maintenance && !self.configService.isDebugMode) {
            if (route.data.source !== 'maintenance') {
              this.router.navigate(['/error/maintenance']);
              observer.next(false);
            } else {
              observer.next(true);
            }
          } else {
            if (route.data.source === 'maintenance') {
              this.routerService.navigate();
            }
            observer.next(true);
          }
          observer.complete();
        },
        (error: any) => {
          observer.next(true);
          observer.complete();
        }
      );
    });
    
  }
}
