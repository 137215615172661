import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ConfigService } from '../services';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private configService: ConfigService,
        private router: Router,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const self = this;

        if (this.configService?.getApiUrl && !req.url.startsWith(this.configService?.getApiUrl())) {
            return next.handle(req);
        }

        return next.handle(req)
            .pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        if (event.status === 401 || event.status === 403) {
                            self.router.navigate(['/auth/logout']);
                        }
                    }
                    return event;
                })
            );

    }
}
