
import { map, first } from 'rxjs/operators';
import { Component, Input, OnInit, ViewChildren, HostListener, Output, EventEmitter, OnDestroy } from '@angular/core';

import { Subscription, Observable } from 'rxjs';
import * as moment from 'moment';

import { IChatController, UserStatus, Localization, Message, StatusDescription, ChatAdapter, User, Window, ConnectionStatus } from './core';
import { ChatService, UserService, LogService, IDataItems, MessageService, StorageService } from '../../../dependencies';

@Component({
    selector: 'app-chat',
    templateUrl: 'chat.component.html',
    styleUrls: [
        'assets/icons.scss',
        'chat.component.scss',
        'assets/loading-spinner.scss'
    ]
})

export class ChatComponent implements OnInit, OnDestroy, IChatController {

    // Exposes the enum for the template
    UserStatus = UserStatus;

    @Input()
    public adapter: ChatAdapter;

    @Input()
    public userId: any;

    @Input()
    public isCollapsed: boolean;

    @Input()
    public maximizeWindowOnNewMessage: boolean;

    @Input()
    public pollFriendsList: boolean;

    @Input()
    public pollingInterval: number;

    @Input()
    public historyEnabled: boolean;

    @Input()
    public emojisEnabled: boolean;

    @Input()
    public linkfyEnabled: boolean;

    @Input()
    public audioEnabled: boolean;

    @Input()
    public searchEnabled: boolean;

    @Input() // TODO: This might need a better content strategy
    public audioSource: string;

    @Input()
    public persistWindowsState: boolean;

    @Input()
    public title: string;

    @Input()
    public messagePlaceholder: string;

    @Input()
    public searchPlaceholder: string;

    @Input()
    public browserNotificationsEnabled: boolean;

    @Input() // TODO: This might need a better content strategy
    public browserNotificationIconSource: string;

    @Input()
    public browserNotificationTitle: string;

    @Input()
    public localization: Localization;

    @Output()
    public onUserClicked: EventEmitter<User>;

    @Output()
    public onUserChatOpened: EventEmitter<User>;

    @Output()
    public onUserChatClosed: EventEmitter<User>;

    @Output()
    // public onMessagesSeen: EventEmitter<Message[]>;

    private browserNotificationsBootstrapped: boolean;

    // Don't want to add this as a setting to simplify usage. Previous placeholder and title settings available to be used, or use full Localization object.
    private statusDescription: StatusDescription = {
        online: 'Online',
        busy: 'Busy',
        away: 'Away',
        offline: 'Offline'
    };

    private audioFile: HTMLAudioElement;

    public searchInput: string;

    private recentChats: any[];

    protected users: User[];

    // Defines the size of each opened window to calculate how many windows can be opened on the viewport at the same time.
    private windowSizeFactor: number;

    // Total width size of the friends list section
    private friendsListWidth: number;

    // Available area to render the plugin
    private viewPortTotalArea: number;

    windows: Window[];

    isBootstrapped: boolean;

    @ViewChildren('chatMessages') chatMessageClusters: any;

    @ViewChildren('chatWindowInput') chatWindowInputs: any;

    protected subscriptions: Subscription[];

    private connectionStatus: ConnectionStatus;

    private connectionStatusName = ConnectionStatus;

    constructor(
        protected userService: UserService,
        protected chatService: ChatService,
        protected logService: LogService,
        protected localStorage: StorageService,
        private notificationService?: MessageService,
    ) {

        this.isCollapsed = false;
        this.maximizeWindowOnNewMessage = true;
        this.pollFriendsList = false;
        this.pollingInterval = 5000;
        this.historyEnabled = true;

        this.emojisEnabled = true;

        this.linkfyEnabled = true;

        this.audioEnabled = true;

        this.searchEnabled = true;

        this.audioSource = './assets/audio/notification.mp3';

        this.persistWindowsState = true;

        this.title = 'Messages';

        this.messagePlaceholder = 'Type a message & press Enter/Return';

        this.searchPlaceholder = 'Search';

        this.browserNotificationsEnabled = true;

        this.browserNotificationIconSource = './assets/img/notification.png';

        this.browserNotificationTitle = 'New message from';

        this.onUserClicked = new EventEmitter<User>();

        this.onUserChatOpened = new EventEmitter<User>();

        this.onUserChatClosed = new EventEmitter<User>();

        // this.onMessagesSeen = new EventEmitter<Message[]>();

        this.browserNotificationsBootstrapped = false;

        this.searchInput = '';

        // Defines the size of each opened window to calculate how many windows can be opened on the viewport at the same time.
        this.windowSizeFactor = 320;

        // Total width size of the friends list section
        this.friendsListWidth = 262;

        this.windows = [];

        this.isBootstrapped = false;

        this.adapter = this.adapter || this.chatService;

        this.userId = this.userId || this.userService.username;

        this.subscriptions = [];

        this.chatService.init(this);
        this.connectionStatus = this.chatService.status || ConnectionStatus.Connecting;

    }

    ngOnInit() {
        this.bootstrapChat();
    }

    ngOnDestroy(): void {
        return this.subscriptions?.forEach(s => s.unsubscribe());
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.viewPortTotalArea = event.target.innerWidth;

        this.NormalizeWindows();
    }

    private get localStorageKey(): string {
        return `chat-users-${ this.userId }`; // Appending the user id so the state is unique per user in a computer.
    };

    get filteredChats(): User[] {
        if (this.searchInput.length > 0) {
            // Searches in the friend list by the inputted search string
            return this.recentChats.filter(x => x.displayName.toUpperCase().includes(this.searchInput.toUpperCase()));
        }

        return this.recentChats;
    }

    get filteredUsers(): User[] {
        if (this.searchInput.length > 0) {
            // Searches in the friend list by the inputted search string
            return this.users.filter(x => x.displayName.toUpperCase().includes(this.searchInput.toUpperCase()));
        }

        return this.users;
    }

    // Checks if there are more opened windows than the view port can display
    private NormalizeWindows(): void {
        const maxSupportedOpenedWindows = Math.floor(this.viewPortTotalArea / this.windowSizeFactor);
        const difference = this.windows.length - maxSupportedOpenedWindows;

        if (difference >= 0) {
            this.windows.splice(this.windows.length - 1 - difference);
        }
    }

    // Initializes the chat plugin and the messaging adapter
    private bootstrapChat(): void {
        if (this.adapter != null && this.userId != null) {
            this.viewPortTotalArea = window.innerWidth;

            this.initializeDefaultText();
            this.initializeBrowserNotifications();

            // Binding event listeners
            // this.adapter.messageReceivedHandler = (user, msg) => this.onMessageReceived(user, msg);
            // this.adapter.friendsListChangedHandler = (users) => this.onFriendsListChanged(users);

            this.subscriptions.push(this.adapter.messageReceivedHandler.subscribe((msg) => this.onMessageReceived(msg.sender, msg.message)));
            this.subscriptions.push(this.adapter.friendsListChangedHandler.subscribe((users) => this.onFriendsListChanged(users)));
            this.subscriptions.push(this.adapter.notificationHandler.subscribe((message) => this.onNotificationReceived(message)));
            this.subscriptions.push(this.adapter.connectionStatusChangeHandler.subscribe((status) => this.connectionStatus = status));

            // Loading current users list
            if (this.pollFriendsList) {
                // Setting a long poll interval to update the friends list
                this.fetchFriendsList(true);
                setInterval(() => this.fetchFriendsList(false), this.pollingInterval);
            } else {
                // Since polling was disabled, a friends list update mechanism will have to be implemented in the ChatAdapter.
                this.fetchFriendsList(true);
            }

            this.bufferAudioFile();

            this.isBootstrapped = true;
        }

        if (!this.isBootstrapped) {
            console.error(`chat component couldn't be bootstrapped.`);

            if (this.userId == null) {
                console.error(`chat can't be initialized without an user id. Please make sure you've provided an userId as a parameter of the chat component.`);
            }
            if (this.adapter == null) {
                console.error(`chat can't be bootstrapped without a ChatAdapter. Please make sure you've provided a ChatAdapter implementation as a parameter of the chat component.`);
            }
        }
    }

    // Initializes browser notifications
    private async initializeBrowserNotifications() {
        if (this.browserNotificationsEnabled && ('Notification' in window)) {
            if (await Notification.requestPermission()) {
                this.browserNotificationsBootstrapped = true;
            }
        }
    }

    // Initializes default text
    private initializeDefaultText(): void {
        if (!this.localization) {
            this.localization = {
                messagePlaceholder: this.messagePlaceholder,
                searchPlaceholder: this.searchPlaceholder,
                title: this.title,
                statusDescription: this.statusDescription,
                browserNotificationTitle: this.browserNotificationTitle
            };
        }
    }

    // Sends a request to load the friends list
    private fetchFriendsList(isBootstrapping: boolean): void {
        this.logService.trace('Messages');
        const self = this;

        // TODO: Add push to Subscription for infinite subscribe
        this.adapter.getRecentChats().pipe(first())
            .subscribe(users => {
                self.recentChats = users;
                // if (self.users) {
                //     self.users = self.users.filter(user => users.findIndex(u => u.userId === user.userId) === -1);
                // }
            });

        // TODO: Finite or infinite subscribe?
        this.adapter.listFriends().pipe(
            first(),
            map((users: User[]) => {
                // if (self.recentChats) {
                //     self.users = users.filter(user => self.recentChats.findIndex(u => u.userId === user.userId) === -1);
                // } else {
                self.users = users;
                // }
            })).subscribe(() => {
                if (isBootstrapping) {
                    this.restoreWindowsState();
                }
            });
    }

    // Updates the friends list via the event handler
    private onFriendsListChanged(users: User[]): void {
        this.logService.trace('Messages');
        if (users?.length > 0) {
            const chats = this.recentChats ? this.recentChats.concat(this.users) : this.users;
            users.forEach(user => {
                let chat: User = chats.find(c => c.userId === user.userId);
                if (chat) {
                    chat = Object.assign(chat, user);
                }
            });
        }
    }

    // Handles received messages by the adapter
    private onMessageReceived(sender: User, message: Message) {
        this.logService.trace('Messages');
        if (sender && message) {
            const chatWindow = this.openChatWindow(sender);
            const chatMessages = chatWindow[0].messages;

            if (!chatWindow[1] || !this.historyEnabled || (chatMessages.length === 0 || chatMessages[chatMessages.length - 1].id !== message.id)) {
                const cw = chatWindow[0];
                cw.messages.push(message);
                const sentTo = this.filteredChats.find(x => x.userId === cw.chattingTo.userId);
                if (sentTo) {
                    sentTo.unread++;
                }

                this.scrollChatWindowToBottom(chatWindow[0]);
            }

            this.emitMessageSound(chatWindow[0]);

            // Github issue #58
            // Do not push browser notifications with message content for privacy purposes if the 'maximizeWindowOnNewMessage' setting is off and this is a new chat window.
            if (this.maximizeWindowOnNewMessage || (!chatWindow[1] && !chatWindow[0].isCollapsed)) {
                // Some messages are not pushed because they are loaded by fetching the history hence why we supply the message here
                this.emitBrowserNotification(chatWindow[0], message);
            }
        }
    }

    private onNotificationReceived(message: string): void {
        this.logService.trace('Notification');
        this.notificationService.simple(`SOCIATE BROADCAST: ${ message }`, true, 'DISMISS', 0);
    }

    // Opens a new chat whindow. Takes care of available viewport
    // Returns => [Window: Window object reference, boolean: Indicates if this window is a new chat window]
    private openChatWindow(user: User, focusOnNewWindow: boolean = false, invokedByUserClick: boolean = false): [Window, boolean] {
        // Is this window opened?
        const openedWindow = this.windows.find(x => x.chattingTo.userId === user.userId);

        if (!openedWindow) {
            if (invokedByUserClick) {
                this.onUserClicked.emit(user);
            }

            // Refer to issue #58 on Github
            const collapseWindow = invokedByUserClick ? false : !this.maximizeWindowOnNewMessage;

            const newChatWindow: Window = {
                chattingTo: user,
                messages: [],
                isLoadingHistory: this.historyEnabled,
                hasFocus: false, // This will be triggered when the 'newMessage' input gets the current focus
                isCollapsed: collapseWindow
            };

            this.windows.unshift(newChatWindow);

            // Loads the chat history via an RxJs Observable
            if (this.historyEnabled) {
                const self = this;
                this.getMessageHistory(newChatWindow, invokedByUserClick ? undefined : moment().unix()).pipe(first())
                    .subscribe(() => {
                        setTimeout(() => { this.scrollChatWindowToBottom(newChatWindow) });
                        // self.markMessagesAsRead(newChatWindow);
                    });
            }

            // Is there enough space left in the view port ?
            if (this.windows.length * this.windowSizeFactor >= this.viewPortTotalArea - this.friendsListWidth) {
                this.windows.pop();
            }

            this.updateWindowsState(this.windows);

            if (focusOnNewWindow && !collapseWindow) {
                this.focusOnWindow(newChatWindow);
            }

            this.onUserChatOpened.emit(user);

            return [newChatWindow, true];
        } else {
            // Returns the existing chat window
            return [openedWindow, false];
        }
    }

    getMessageHistory(window: Window, pageToken?: number): Observable<void> {
        this.logService.trace('Chat: getMessageHistory');
        const self = this;

        return this.adapter.getMessageHistory(window.chattingTo.userId, pageToken || window.pageToken).pipe(
            map((result: IDataItems<Message>) => {
                // newChatWindow.messages.push.apply(newChatWindow.messages, result);
                window.messages = result.items.concat(window.messages);
                window.pageToken = result.pageToken;
                window.isLoadingHistory = false;

                self.markMessagesAsRead(window);
                // setTimeout(() => { this.scrollChatWindowToBottom(window) });
            }));
        // .subscribe();
    }

    loadMoreHistory(window: Window): void {
        this.getMessageHistory(window).pipe(first()).subscribe();
    }

    // Focus on the input element of the supplied window
    private focusOnWindow(window: Window, callback: Function = () => { }): void {
        const windowIndex = this.windows.indexOf(window);
        if (windowIndex >= 0) {
            setTimeout(() => {
                if (this.chatWindowInputs) {
                    const messageInputToFocus = this.chatWindowInputs.toArray()[windowIndex];

                    messageInputToFocus.nativeElement.focus();
                }

                callback();
            });
        }
    }

    // Scrolls a chat window message flow to the bottom
    private scrollChatWindowToBottom(window: Window): void {
        if (!window.isCollapsed) {
            const windowIndex = this.windows.indexOf(window);

            const self = this;
            setTimeout(() => {
                if (self.chatMessageClusters) {
                    const w = self.chatMessageClusters.toArray()[windowIndex];
                    if (w) {
                        w.nativeElement.scrollTop = w.nativeElement.scrollHeight;
                    }
                }
            });
        }
    }

    // Marks all messages provided as read with the current time.
    private markMessagesAsRead(window: Window): void {
        const currentDate = moment().unix();
        let latestDate: number;

        const unreadMessages: Message[] = [];
        window.messages.filter(message => message.seenOn == null && message.toId === this.userId).forEach(message => {
            unreadMessages.push(message);
        });

        const messageIds = unreadMessages.map(msg => msg.id);
        if (messageIds.length > 0) {
            this.logService.trace('Chat: mark as read');
            const self = this;
            this.chatService.markMessagesAsRead(messageIds, latestDate).pipe(first())
                .subscribe(res => {
                    if (res.status.status === 'STA_SUCCESS') {
                        unreadMessages.forEach((msg) => {
                            msg.seenOn = currentDate;
                            if (latestDate < msg.created) { latestDate = msg.created; }
                        });
                        // self.onMessagesSeen.emit(unreadMessages);
                        window.chattingTo.unread = window.chattingTo.unread -= messageIds.length;
                    }
                });
        }
    }

    // Buffers audio file (For component's bootstrapping)
    private bufferAudioFile(): void {
        if (this.audioSource?.length > 0) {
            this.audioFile = new Audio();
            this.audioFile.src = this.audioSource;
            this.audioFile.load();
        }
    }

    // Emits a message notification audio if enabled after every message received
    private emitMessageSound(window: Window): void {
        if (this.audioEnabled && !window.hasFocus && this.audioFile) {
            this.audioFile.play();
        }
    }

    // Emits a browser notification
    private emitBrowserNotification(window: Window, message: Message): void {
        if (this.browserNotificationsBootstrapped && !window.hasFocus && message) {
            const notification = new Notification(`${ this.localization.browserNotificationTitle } ${ window.chattingTo.displayName }`, {
                'body': message.message, // window.messages[window.messages.length - 1].message,
                'icon': this.browserNotificationIconSource
            });

            setTimeout(() => {
                notification.close();
            }, message.message.length <= 50 ? 5000 : 7000); // More time to read longer messages
        }
    }

    // Saves current windows state into local storage if persistence is enabled
    private updateWindowsState(windows: Window[]): void {
        if (this.persistWindowsState) {
            const usersIds = windows.map((w) => {
                return w.chattingTo.userId;
            });

            // localStorage.setItem(this.localStorageKey, JSON.stringify(usersIds));
            this.localStorage.save(this.localStorageKey, JSON.stringify(usersIds));
        }
    }

    private restoreWindowsState(): void {
        try {
            if (this.persistWindowsState) {
                const stringfiedUserIds = this.localStorage.get(this.localStorageKey);

                if (stringfiedUserIds?.length > 0) {
                    const userIds = <string[]>JSON.parse(stringfiedUserIds);

                    const usersToRestore = this.users.filter(u => userIds.includes(u.userId));

                    usersToRestore.forEach((user) => {
                        this.openChatWindow(user);
                    });
                }
            }
        } catch (ex) {
            this.logService.error(`An error occurred while restoring chat windows state. Details: ${ ex }`);
        }
    }

    // Gets closest open window if any. Most recent opened has priority (Right)
    private getClosestWindow(window: Window): Window | undefined {
        const index = this.windows.indexOf(window);

        if (index > 0) {
            return this.windows[index - 1];
        } else if (index === 0 && this.windows.length > 1) {
            return this.windows[index + 1];
        }
    }

    // Returns the total unread messages from a chat window. TODO: Could use some Angular pipes in the future
    unreadMessagesTotal(window: Window): string {
        if (window) {
            const totalUnreadMessages = window.messages.filter(x => x.fromId !== this.userId && !x.seenOn).length;

            if (totalUnreadMessages > 0) {

                if (totalUnreadMessages > 99) {
                    return '99+';
                } else {
                    return String(totalUnreadMessages);
                }
            }
        }

        // Empty fallback.
        return '';
    }

    unreadMessagesTotalByUser(user: User): string {

        const totalUnreadMessages = user.unread;

        if (totalUnreadMessages > 0) {

            if (totalUnreadMessages > 99) {
                return '99+';
            } else {
                return String(totalUnreadMessages);
            }
        }

        // const openedWindow = this.windows.find(x => x.chattingTo.userId === user.userId);

        // if (openedWindow) {
        //     return this.unreadMessagesTotal(openedWindow);
        // }

        // Empty fallback.
        return '';
    }

    /*  Monitors pressed keys on a chat window
        - Dispatches a message when the ENTER key is pressed
        - Tabs between windows on TAB or SHIFT + TAB
        - Closes the current focused window on ESC
    */
    onChatInputTyped(event: any, window: Window): void {
        switch (event.keyCode) {
            case 13:
                // Enter/Return
                event.preventDefault();
                if (window.newMessage?.trim().length > 0) {
                    const message = new Message();

                    message.fromId = this.userId;
                    message.toId = window.chattingTo.userId;
                    message.message = window.newMessage;
                    message.created = Math.floor(moment.now() / 1000);

                    window.messages.push(message);

                    this.adapter.sendMessage(message, window.chattingTo);

                    window.newMessage = ''; // Resets the new message input

                    this.scrollChatWindowToBottom(window);
                }
                break;
            case 9:
                // Tab
                event.preventDefault();

                const currentWindowIndex = this.windows.indexOf(window);
                let messageInputToFocus = this.chatWindowInputs.toArray()[currentWindowIndex + (event.shiftKey ? 1 : -1)]; // Goes back on shift + tab

                if (!messageInputToFocus) {
                    // Edge windows, go to start or end
                    messageInputToFocus = this.chatWindowInputs.toArray()[currentWindowIndex > 0 ? 0 : this.chatWindowInputs.length - 1];
                }

                messageInputToFocus.nativeElement.focus();

                break;
            case 27:
                // Escape
                const closestWindow = this.getClosestWindow(window);

                if (closestWindow) {
                    this.focusOnWindow(closestWindow, () => { this.onCloseChatWindow(window); });
                } else {
                    this.onCloseChatWindow(window);
                }
        }
    }

    // Closes a chat window via the close 'X' button
    onCloseChatWindow(window: Window): void {
        const index = this.windows.indexOf(window);

        this.windows.splice(index, 1);

        this.updateWindowsState(this.windows);

        this.onUserChatClosed.emit(window.chattingTo);
    }

    // Toggle friends list visibility
    onChatTitleClicked(event: any): void {
        this.isCollapsed = !this.isCollapsed;
    }

    // Toggles a chat window visibility between maximized/minimized
    onChatWindowClicked(window: Window): void {
        window.isCollapsed = !window.isCollapsed;
        this.scrollChatWindowToBottom(window);
    }

    // Asserts if a user avatar is visible in a chat cluster
    isAvatarVisible(window: Window, message: Message, index: number): boolean {
        if (message.fromId !== this.userId) {
            if (index === 0) {
                return true; // First message, good to show the thumbnail
            } else {
                // Check if the previous message belongs to the same user, if it belongs there is no need to show the avatar again to form the message cluster
                if (window.messages[index - 1].fromId !== message.fromId) {
                    return true;
                }
            }
        }

        return false;
    }

    // Toggles a window focus on the focus/blur of a 'newMessage' input
    toggleWindowFocus(window: Window): void {
        window.hasFocus = !window.hasFocus;
        if (window.hasFocus) {
            // const unreadMessages: Message[] = [];
            // window.messages.filter(message => message.seenOn == null && message.toId === this.userId).forEach(message => {
            //     unreadMessages.push(message);
            // });

            // this.markMessagesAsRead(window);
            setTimeout(() => { this.markMessagesAsRead(window) }, 100);
            // this.onMessagesSeen.emit(unreadMessages);
        }
    }

    // [Localized] Returns the status descriptive title
    getStatusTitle(status: string): any {
        return this.localization.statusDescription[status];
    }

    displayDate(curr: Message, prev: Message): boolean {
        if (!prev) { return true; }

        // use moment constructor as it is 13 digit timestamp (not unix 10 digit)
        return moment(curr.created * 1000).format('l') !== moment(prev.created * 1000).format('l');
    }

    triggerOpenChatWindow(user: User): void {
        if (user) {
            this.openChatWindow(user);
        }
    }

    triggerCloseChatWindow(userId: any): void {
        const openedWindow = this.windows.find(x => x.chattingTo.userId === userId);

        if (openedWindow) {
            this.onCloseChatWindow(openedWindow);
        }
    }

    triggerToggleChatWindowVisibility(userId: any): void {
        const openedWindow = this.windows.find(x => x.chattingTo.userId === userId);

        if (openedWindow) {
            this.onChatWindowClicked(openedWindow);
        }
    }

    triggerToggleMainWindowVisibility(collapse?: boolean | undefined): void {
        if (collapse === undefined) {
            this.onChatTitleClicked(null);
        } else {
            this.isCollapsed = collapse;
        }
    }
}
