
import { throwError as observableThrowError, Observable, Observer, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as google from '@google/maps';
import { map, catchError } from 'rxjs/operators';
import { has } from 'lodash-es';

import { BusinessNames, IMetaData, IResponse } from '../../../interfaces';
import { ConfigService } from '../utility/config.service';
import { StorageService } from '../functional/storage.service';
import { UtilityService } from '../../utilities';
import { IPreviewResponse } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {

  // private placesConfig: any = this.configService.get('places_api');
  // private client: any = google.createClient({ key: this.placesConfig.KEY });

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private localStorage: StorageService,
    private utils: UtilityService,
  ) { }

  /**
   * getSchools
   */
  public get(name: BusinessNames, refresh: boolean = false): Observable<Array<IMetaData>> {

    if (!refresh && this.localStorage.has('metadata')) {
      const metaData: Array<Array<IMetaData>> = this.localStorage.get('metadata');
      if (has(metaData, name) && metaData[name]) {
        return of(metaData[name]);
      }
    }

    return this.http
      .get(`${this.configService.getApiUrl()}/metadata?bn=${BusinessNames[name]}`)
      .pipe(
        map((res: IResponse) => {
          let metaData: Array<Array<IMetaData>> = [];
          if (this.localStorage.has('metadata')) {
            metaData = this.localStorage.get('metadata');
          }
          metaData[name] = <IMetaData[]>res.data;
          this.localStorage.save('metadata', metaData);
          return metaData[name];
        }),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve data.'))
      );
  }

  public save(name: BusinessNames, data: IMetaData): Observable<IResponse> {
    return this.http
      .post(`${this.configService.getApiUrl()}/metadata?bn=${BusinessNames[name]}`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to save metadata.'))
      );
  }

  public delete(name: BusinessNames, data: Array<number>): Observable<IResponse> {
    return this.http
      .post(`${this.configService.getApiUrl()}/metadata/deleteSelected?bn=${BusinessNames[name]}`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to save metadata.'))
      );
  }

  public findLocations(location: string): Observable<IResponse> {

    return this.http
      .get(`${this.configService.getApiUrl()}/metadata/locations?q=${location}`)
      .pipe(
        map((res: IResponse) => res),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve location data.'))
      );
  }

  public findCompanies(company: string, recordCount: number = 4): Observable<IResponse> {

    return this.http
      .get(`${this.configService.getApiUrl()}/metadata/companies?q=${company}&c=${recordCount}`)
      .pipe(
        map((res: IResponse) => res),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve company data.'))
      );
  }

  public generatePreview(url: string): Observable<IPreviewResponse> {
    return this.http
      .get(`${this.configService.getApiUrl()}/metadata/preview?u=${url}`)
      .pipe(
        map((response: IPreviewResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Preview data.'))
      );
  }  

}
