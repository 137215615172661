import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { LocaleModel } from '../../../../translation';
import { AccountStatus, StorageService, UserService } from '../../../../dependencies';
import { IHeaderComponentResolve } from './interfaces';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})

export class HeaderComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = new Array<Subscription>();

  public user: any;
  public locales: Array<LocaleModel>;

  /**
   * Constructor of the class.
   *
   * @param {ActivatedRoute}      activatedRoute
   * @param {LocalStorageService} localStorage
   * @param {UserService}         userService
   * @param {TranslateService}    translateService
   * @param {SidenavService}      sidenavService
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private localStorage: StorageService,
    private userService: UserService,
    private translateService: TranslateService,
    private router: Router,
    ) { }

  /**
   * On component init we need to store current user and make a subscription for token changes so that we
   * get user value to update within login / logout states.
   */
  public ngOnInit(): void {
    this.user = this.userService.profile;

    const self = this;
    this.subscriptions.push(
      this.userService.profileAsObserable()
        .subscribe((profile) => {
          self.user = profile;
        })
    );

    // Store locales from route resolve
    this.subscriptions.push(
      this.activatedRoute.data
        .subscribe((data: IHeaderComponentResolve) => {
          self.locales = data.locales;
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Method to change current language.
   *
   * @param {LocaleModel} locale
   */
  public changeLocale(locale: LocaleModel): void {
    this.translateService.use(locale.code);
  }

  public showLogout(): boolean {
    return this.user;
  }

  get isSimulating(): boolean {
    return this.userService.isSimulating;
  }

  dismiss(): void {
    if (this.userService.endSimulation()) {
      this.router.navigate(['/admin']);
    }
  }

}
