
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { IResponse, IPreference } from '../../../interfaces';
import { ConfigService } from '../utility/config.service';

@Injectable({
  providedIn: 'root',
})
export class PreferenceService {

  // private placesConfig: any = this.configService.get('places_api');
  // private client: any = google.createClient({ key: this.placesConfig.KEY });

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  /**
   * getSchools
   */
  public get(name: string, item?: string): Observable<IResponse> {

    let subRes = '';
    if (item) {
      subRes = `/${ item }`;
    }

    return this.http
      .get(`${ this.configService.getApiUrl() }/admin/${ name.toLowerCase() }${ subRes }`)
      .pipe(
        map((res: IResponse) => res),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve data.'))
      );
  }

  public save(name: string, data: IPreference): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/admin/${ name.toLowerCase() }`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to save setting.'))
      );
  }

}
