<div class="filters-component" fxLayout="column">

  <form [formGroup]="filterForm" novalidate autocomplete="off">
    <!-- Filters -->
    <div class="filters" [fxLayout]="layoutDirection" fxLayoutAlign="space-between" fxLayoutGap="18px">

      <!-- School Starts -->
      <div class="school" appearance="outline" fxFlex *ngIf="isVisible('school')">
        <mat-form-field fxFill appearance="outline">
          <input #schoolControl name="school" placeholder="{{ 'PLACEHOLDER_FILTER_SCHOOL' | translate }}" type="text"
            matInput [matAutocomplete]="autoSchool" formControlName="schoolControl" [(ngModel)]="school" />
        </mat-form-field>

        <mat-autocomplete #autoSchool="matAutocomplete" [displayWith]="setToNull">
          <div class="school-autocomplete">
            <mat-option *ngFor="let school of filteredSchools | async" [value]="school"
              (click)="select(data, school, 'school')">
              <span class="name">{{ school.name }}</span>
            </mat-option>
          </div>
        </mat-autocomplete>
      </div>
      <!-- School Ends -->

      <!-- Company Starts -->
      <div class="company" appearance="outline" fxFlex *ngIf="!recruiter && isVisible('company')">
        <mat-form-field fxFill appearance="outline">
          <!-- <mat-icon matPrefix>business</mat-icon> -->
          <input #companyControl name="company" placeholder="{{ 'PLACEHOLDER_COMPANY' | translate }}" type="text"
            matInput [matAutocomplete]="autoCompany" formControlName="companyControl" [(ngModel)]="company" />
        </mat-form-field>

        <mat-autocomplete #autoCompany="matAutocomplete" [displayWith]="setToNull">
          <div class="company-autocomplete">
            <mat-option *ngFor="let company of filteredCompanies | async" [value]="company"
              (click)="select(data, company, 'company')">
              <div class="item">
                <span class="logo"><img src="{{ company.logo }}"></span>
                <span class="name">{{ company.name }}</span>
                <span class="domain">{{ company.domain }}</span>
              </div>
            </mat-option>
          </div>
        </mat-autocomplete>
      </div>
      <!-- Company Ends -->

      <!-- Industries Starts -->
      <div class="industry" appearance="outline" *ngIf="isVisible('industry')" fxFlex>
        <mat-form-field fxFill appearance="outline">
          <!-- <mat-icon matPrefix>build</mat-icon> -->
          <input #industryControl name="industry" placeholder="{{ 'PLACEHOLDER_INDUSTRY' | translate }}" type="text"
            matInput [matAutocomplete]="autoIndustry" formControlName="industryControl" [(ngModel)]="industry" />
        </mat-form-field>

        <mat-autocomplete #autoIndustry="matAutocomplete" [displayWith]="setToNull">
          <div class="options-list">
            <mat-option *ngFor="let tag of filteredIndustries | async" [value]="tag.name"
              (click)="select(data, tag, 'industry')">
              {{ tag.name }}
            </mat-option>
          </div>
        </mat-autocomplete>
      </div>
      <!-- Industries Ends -->

      <!-- Functions Starts -->
      <div class="function" appearance="outline" fxFlex *ngIf="isVisible('function')">
        <mat-form-field fxFill appearance="outline">
          <!-- <mat-icon matPrefix>work</mat-icon> -->
          <input #functionControl name="function" placeholder="{{ 'PLACEHOLDER_FUNCTION' | translate }}" type="text"
            matInput [matAutocomplete]="autoFunction" formControlName="functionControl" [(ngModel)]="function" />
        </mat-form-field>

        <mat-autocomplete #autoFunction="matAutocomplete" [displayWith]="setToNull">
          <mat-option *ngFor="let tag of filteredFunctions | async" [value]="tag.name"
            (click)="select(data, tag, 'function')">
            {{ tag.name }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <!-- Functions Ends -->

      <!-- Location Starts -->
      <div class="location" appearance="outline" fxFlex *ngIf="isVisible('location')">
        <mat-form-field fxFill appearance="outline">
          <!-- <mat-icon matPrefix>location_on</mat-icon> -->
          <input #locationControl name="location" placeholder="{{ 'PLACEHOLDER_LOCATION' | translate }}" type="text"
            matInput [matAutocomplete]="autoLocation" [(ngModel)]="location" formControlName="locationControl" />
        </mat-form-field>

        <mat-autocomplete #autoLocation="matAutocomplete" [displayWith]="setToNull">
          <mat-option *ngFor="let location of filteredLocations | async" [value]="location"
            (click)="select(data, {name:location}, 'location')">
            {{ location }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <!-- Location Ends -->

      <!-- Member Type Starts -->
      <div class="memberType" appearance="outline" fxFlex *ngIf="isVisible('memberType')">
        <mat-form-field fxFill appearance="outline">
          <input #memberTypeControl name="memberType" placeholder="{{ 'PLACEHOLDER_FILTER_MEMBER_TYPE' | translate }}"
            type="text" matInput [matAutocomplete]="autoType" formControlName="memberTypeControl"
            [(ngModel)]="memberType" />
        </mat-form-field>

        <mat-autocomplete #autoType="matAutocomplete" [displayWith]="setToNull">
          <div class="memberType-autocomplete">
            <mat-option *ngFor="let memberType of filteredMemberTypes | async" [value]="memberType"
              (click)="select(data, memberType, 'memberType')">
              <span class="name">{{ memberType.name }}</span>
            </mat-option>
          </div>
        </mat-autocomplete>
      </div>
      <!-- Member Type Ends -->

      <!-- Open To Work Starts -->
      <div class="open-to-work mat-form-field mat-form-field-wrapper" appearance="outline" fxFlex
        *ngIf="isVisible('openToWork')">
        <span class="display" fxFlex="grow"> {{ 'PLACEHOLDER_FILTER_OPENTOWORK' | translate }} </span>
        <mat-slide-toggle #openToWorkControl name="openToWork" fxFlex="nogrow" color="primary"
          [checked]="openToWork=='1'" [disabled]="loading || openToWork=='1'" formControlName="openToWorkControl" [(ngModel)]="openToWork"
          (change)="select(data, {name:'Open to Work',value:openToWork=='1', type: 'openToWork'})"
          matTooltip="{{ openToWork=='1' ? 'Enabled' : 'Disabled' }}">
        </mat-slide-toggle>
      </div>
      <!-- Open To Work Ends -->

    </div>
  </form>

  <div class="selected-filters" fxLayout="column" fxLayoutAlign="start center" *ngIf="data.length > 0">

    <mat-divider></mat-divider>

    <div class="selected-container">
      <mat-chip-list>
        <mat-chip *ngFor="let tag of data" [removable]="removable">
          {{tag.name}}
          <mat-icon matChipRemove (click)="removeSelected(data, tag)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>

  </div>

</div>