import { AnonymousLayoutModule } from './anonymous';
import { AuthenticatedLayoutModule } from './authenticated';
import { FixedLayoutModule } from './fixed';

export const LayoutModules = [
    AnonymousLayoutModule,
    AuthenticatedLayoutModule,
    FixedLayoutModule,
];

export * from './fixed';

export * from './anonymous/anonymous-layout-routing.module';
export * from './authenticated/authenticated-layout-routing.module';

