import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MaintenanceGuard, AuthenticationGuard, ActivationGuard, RolesGuard, SimulationGuard } from './shared';

// import { HomeComponent } from './home';
// import { DefaultComponent } from './default';
// import { HubComponent } from './hub';

const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  // {
  //   path: 'app',
  //   loadChildren: () => import('./default').then(mod => mod.DefaultModule),
  // },
  //
  // Load it as eager loading
  //
  {
    path: 'home',
    loadChildren: () => import('./features/home').then(mod => mod.HomeModule),
    canActivate: [
      MaintenanceGuard,
    ],
  },
  {
    path: 'hub',
    loadChildren: () => import('./features/hub').then(mod => mod.HubModule),
    canLoad: [
      AuthenticationGuard,
      ActivationGuard,
    ],
    canActivate: [
      MaintenanceGuard,
    ],
    data: { preload: true },
  },
  {
    path: 'users',
    loadChildren: () => import('./features/users').then(mod => mod.UsersModule),
    canLoad: [
      AuthenticationGuard,
      ActivationGuard,
    ],
    canActivate: [
      MaintenanceGuard,
    ],
  },
  {
    path: 'my',
    loadChildren: () => import('./features/users').then(mod => mod.UsersModule),
    canLoad: [
      AuthenticationGuard,
      ActivationGuard,
    ],
    canActivate: [
      MaintenanceGuard,
    ],
  },
  {
    path: 'messages',
    loadChildren: () => import('./features/messages').then(mod => mod.MessagesModule),
    canLoad: [
      AuthenticationGuard,
      ActivationGuard,
    ],
    canActivate: [
      SimulationGuard,
      MaintenanceGuard,
    ],
  },
  {
    path: 'admin',
    loadChildren: () => import('./features/admin').then(mod => mod.AdminModule),
    canLoad: [
      AuthenticationGuard,
      RolesGuard,
    ],
    data: {
      allowedRoles: ['administrator']
    },
    canActivate: [
      MaintenanceGuard,
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth').then(mod => mod.AuthModule),
    canActivate: [
      MaintenanceGuard,
    ],
  },
  // {
  //   path: 'register',
  //   loadChildren: () => import('./features/register').then(mod => mod.RegisterModule),
  //   canActivate: [
  //     MaintenanceGuard,
  //   ],
  // },
  {
    path: 'account',
    loadChildren: () => import('./features/account').then(mod => mod.AccountModule),
    canActivate: [
      MaintenanceGuard,
    ],
    data: { preload: true },
  },
  {
    path: 'legal',
    loadChildren: () => import('./features/legal').then(mod => mod.LegalModule),
    canActivate: [
      MaintenanceGuard,
    ],
  },
  //
  // Load it as eager loading
  //
  {
    path: 'error',
    loadChildren: () => import('./error').then(mod => mod.ErrorModule),
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      AppRoutes,
      {
        // enableTracing: true
        preloadingStrategy: PreloadAllModules,
        relativeLinkResolution: 'legacy',
      },
    ),
  ],
  exports: [
    // RouterModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
  ],
})

export class AppRoutingModule { }
