<!-- <mat-sidenav-container fxFlex fxFill>
  <mat-sidenav #sidenav>
    <router-outlet name="sidenav"></router-outlet>
  </mat-sidenav>

  <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFill>
    <header>
      <router-outlet name="header"></router-outlet>
    </header>

    <article fxFlex fxFill>
      <router-outlet></router-outlet>
    </article>

    <footer>
      <router-outlet name="footer"></router-outlet>
    </footer>
  </div>
</mat-sidenav-container> -->

<span appUserAgentInfo (info)="clientInit($event)"></span>
<app-chat #messaging *ngIf="showFloatingChat"></app-chat>
<div fxFlex fxFill>
  <router-outlet></router-outlet>
</div>