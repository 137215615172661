import { Injectable } from '@angular/core';

import { ConfigService } from '../services/utility/config.service';

@Injectable({
  providedIn: 'root',
})
export class EmailService {

  private debug: boolean;

  constructor(
    private configService: ConfigService,
  ) { }

  public construct(email: string): string {
    email = email.trim();
    return email; // + (this.configService.isDebugMode || email.endsWith('.edu') ? '' : '.edu');
  }

  public getEmailPattern(endsWith?: string): RegExp {
    return this.configService.isDebugMode || !endsWith
      ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      : new RegExp(`^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*\\.${ endsWith }$`);
  }

  public getEmailDomain(email: string): string {
    return email ? email.toLowerCase().replace(/.*@/, '') : '';
  }

}
