export enum UserStatus {
    Online,
    Busy,
    Away,
    Offline
}

export enum ConnectionStatus {
    Disconnected = 0,
    Connecting = -1,
    Connected = 1,
    Reconnecting = -2,
    Reconnected = 2,
}
