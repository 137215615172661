
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { IResponse } from '../../../interfaces';
import { ConfigService } from '../utility/config.service';
import { IPostsResponse, ICommentsResponse, ILikesResponse } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class PostService {

  // private placesConfig: any = this.configService.get('places_api');
  // private client: any = google.createClient({ key: this.placesConfig.KEY });

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  public get(postId: number): Observable<IPostsResponse> {

    return this.http
      .get(`${ this.configService.getApiUrl() }/posts/${ postId }`)
      .pipe(
        map((response: IPostsResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Post data.'))
      );
  }

  /**
   * getPreview
   */
  public getAll(tag?: number, pageToken?: number, recordCount?: number): Observable<IPostsResponse> {

    let filters = '';

    // if (tag > 0) {
    //   filters = `?t=${tag}`;
    // }

    // if (pageToken > 0) {
    //   filters += filters.length === 0 ? '?' : '&';
    //   filters += `p=${pageToken}`;
    // }

    filters += tag ? `t=${ tag }&` : '';
    filters += pageToken ? `p=${ pageToken }&` : '';
    filters += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/posts?${ filters }`)
      .pipe(
        map((response: IPostsResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Posts data.'))
      );
  }


  public add(data): Observable<IPostsResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/posts`, data)
      .pipe(
        map((response: IPostsResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to add a new post.'))
      );
  }

  public edit(postId, data): Observable<IPostsResponse> {
    return this.http
      .put(`${ this.configService.getApiUrl() }/posts/${ postId }`, data)
      .pipe(
        map((response: IPostsResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to add a new post.'))
      );
  }


  public delete(postId): Observable<IResponse> {
    return this.http
      .delete(`${ this.configService.getApiUrl() }/posts/${ postId }`)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to delete the post.'))
      );
  }

  public deletePosts(data: Array<number>): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/posts/deleteSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to delete the posts.'))
      );
  }

  public toggle(data: Array<number>): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/posts/toggleSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to toggle status of the posts.'))
      );
  }

  public getLikes(postId: number, pageToken?: number, recordCount?: number): Observable<ILikesResponse> {
    let filters = '';

    filters += pageToken ? `p=${ pageToken }&` : '';
    filters += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/posts/${ postId }/likes?${ filters }`)
      .pipe(
        map((response: ILikesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Post data.'))
      );
  }

  public setLike(postId: number, data: { liked: boolean }): Observable<IResponse> {
    return this.http
      .put(`${ this.configService.getApiUrl() }/posts/${ postId }/like`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to process like.'))
      );
  }

  public addComment(data): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/posts/${ data.postId }/comments`, data.comment)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to save comment.'))
      );
  }

  public getComments(postId: number, pageToken?: number, recordCount?: number): Observable<ICommentsResponse> {

    let filters = '';

    filters += pageToken ? `p=${ pageToken }&` : '';
    filters += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/posts/${ postId }/comments?${ filters }`)
      .pipe(
        map((response: ICommentsResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Post data.'))
      );
  }

  public deleteComments(postId: number, data: Array<number>): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/posts/${ postId }/comments/deleteSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to delete the post comments.'))
      );
  }

  public toggleComments(postId: number, data: Array<number>): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/posts/${ postId }/comments/toggleSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to toggle status of the post comments.'))
      );
  }

}
