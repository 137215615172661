import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { map, catchError, first, finalize } from 'rxjs/operators';
import { merge } from 'lodash-es';

import { WindowService } from './window.service';
import { IConfig, IDebug, IDefaults, IResponse } from '../../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  // private global: any;
  private _debug = false;
  private _debugToken: string;
  private _debugTokenStatus: string;
  /**
   * Construct of the class
   *
   * @param {Window} window
   */
  constructor(
    @Inject('Window')
    private global: any,
    private window: WindowService,
    private http: HttpClient,
  ) {
    this.global = this.global || window.nativeWindow;
  }

  private loadOverrides(): Observable<boolean> {
    const self = this;
    const overrideConfigFile = this.get().overrides.source || `./assets/data/overrides.json`;

    return this.http
      .get(overrideConfigFile)
      .pipe(
        map((res: any) => {
          self.global.__env = merge(self.global.__env, res);
          return true;
        }),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve overrides.')),
      )
      ;
  }

  public refresh(subscribe?: boolean): Observable<boolean> {
    if (subscribe) {
      return this.loadOverrides();
    }
    this.loadOverrides().pipe(first()).subscribe();
  }

  /**
   * Getter method for used env variables.
   *
   * @param {string}  section
   * @returns {any}
   */
  public get(section?: string): IConfig {
    if (section) {
      return this.global.__env[section];
    } else {
      return <IConfig>this.global.__env;
    }
  }

  public get version(): string {
    return this.get().version;
  }

  /**
   * Short hand method to get current API URL.
   *
   * @returns {string}
   */
  public getApiUrl(): string {
    return this.get().urls.api;
  }

  public getSocketUrl(): string {
    return this.get().urls.socket;
  }

  /*
  */
  public get defaults(): IDefaults {
    return this.get().defaults;
  }

  // public get overrides(): IConfig {
  //   return this.get().overrides;
  // }

  private validateDebugMode(secret: string): void {
    const self = this;
    if (!secret || (this._debug && this.debugToken) || this._debugTokenStatus === 'loading') return;
    self._debugTokenStatus = 'loading';
    this.http
      .get(`${ this.getApiUrl() }/config/debug?s=${ secret }`)
      .pipe(
        map((res: IResponse) => {
          self._debug = res?.data?.mode;
          self._debugToken = res?.data?.token;
        }),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve data.')),
        finalize(() => self._debugTokenStatus = 'done'),
        first()
      ).subscribe();
  }

  public get debug(): IDebug {
    return this.get().debug;
  }

  public get debugToken(): string {
    return this._debugToken;
  }

  public get isDebugMode(): boolean {
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    this.validateDebugMode(params.get('debug'));
    return (this.debug?.enabled) || this._debug || (this._debugToken && this._debugToken === this.debug?.token);
  }

  private async createHash(data: string, algo: string = 'SHA-256', encoding: string = 'hex'): Promise<string> {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(data);

    // hash the message
    const hashBuffer = await crypto.subtle.digest(algo, msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

}
