import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IUser } from '../interfaces';
import { AccountStatus } from '../interfaces';
import { UserService } from './user.service';
import { ConfigService } from '../utility/config.service';
import { LogService } from '../utility/log.service';

@Injectable({
  providedIn: 'root',
})
export class RouterService {

  constructor(
    private configService: ConfigService,
    private logService: LogService,
    private userService: UserService,
    private router: Router,
  ) { }

  public navigate(userProfile: IUser = this.userService.profile): void {

    (async (profile: IUser) => {
      let route = ['home'];

      if (profile) {
        try {

          if (profile.status === 1 && profile.approvalStatus === false) {
            route = ['account', 'activation'];
          } else if (profile.status === AccountStatus.Active) {
            route = ['hub'];
            if (profile.roles.includes('recruiter')) {
              route = [`hub/sociates`];
            }
          } else {
            let page = AccountStatus[profile.status % 10].toLowerCase();
            if ([AccountStatus.Activation, AccountStatus.RecruiterActivation].includes(profile.status)) {
              page = 'activation';
            }
            route = ['account', page];
          }

        } catch (e) {
          this.logService.error(e);
        }
      }

      await this.router.navigate(route);
    })(userProfile);

  }

}
