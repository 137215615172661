
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ConfigService } from '../utility/config.service';
import { ISociatesResponse, IPostsResponse, IOpportunitiesResponse } from '../interfaces';
import { IResponse, ISociate } from '../../../interfaces';


@Injectable({
  providedIn: 'root',
})
export class SociateService {

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  public find(criteria: string, recordCount?: number): Observable<ISociatesResponse> {

    let filters = `s=${ criteria }`;
    filters += recordCount ? `&c=${ recordCount }` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/users?${ filters }`)
      .pipe(
        map((response: ISociatesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to find users.'))
      );
  }

  /**
   * getPreview
   */
  public getAll(
    filters: {
      industry?: string,
      company?: string,
      func?: string,
      location?: string,
      resume?: number,
      school?: string,
      graduating?: number,
      openToWork?: boolean,
    } = {},
    pageToken?: number,
    recordCount?: number,
  ): Observable<ISociatesResponse> {

    let query = '';

    query += filters.industry ? `i=${ filters.industry }&` : '';
    query += filters.func ? `f=${ filters.func }&` : '';
    query += filters.company ? `cp=${ filters.company }&` : '';
    query += filters.location ? `l=${ filters.location }&` : '';
    query += filters.resume ? `r=${ filters.resume }&` : '';
    query += filters.school ? `sc=${ filters.school }&` : '';
    query += filters.graduating ? `g=${ filters.graduating }&` : '';
    query += filters.openToWork ? `ow=${ +filters.openToWork }&` : '';
    query += pageToken ? `p=${ pageToken }&` : '';
    query += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/users?${ query }`)
      .pipe(
        map((response: ISociatesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Sociates data.'))
      );
  }

  public getUsers(
    userType: string = 'sociate',
    userStatus: number = null,
    pageToken?: number,
    recordCount?: number,
  ): Observable<ISociatesResponse> {

    let filters = `u=${ userType }&`;

    filters += userStatus ? `st=${ userStatus }&` : '';
    filters += pageToken ? `p=${ pageToken }&` : '';
    filters += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/users?${ filters }`)
      .pipe(
        map((response: ISociatesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Sociates data.'))
      );
  }

  public get(sociateId: string): Observable<IResponse> {

    return this.http
      .get(`${ this.configService.getApiUrl() }/users/${ sociateId }`)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Sociate data.'))
      );
  }

  public needApproval(): Observable<IResponse> {
    return this.http
      .get(`${ this.configService.getApiUrl() }/users/status/approval`)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Sociate data.'))
      );
  }

  public getUserPosts(sociateId: string, pageToken?: number, recordCount?: number): Observable<IPostsResponse> {

    let filters = ``;
    filters += pageToken ? `p=${ pageToken }&` : '';
    filters += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/users/${ sociateId }/posts?${ filters }`)
      .pipe(
        map((response: IPostsResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Posts data.'))
      );
  }

  public getUserOpportunities(sociateId: string, pageToken?: number, recordCount?: number): Observable<IOpportunitiesResponse> {

    let filters = ``;
    filters += pageToken ? `p=${ pageToken }&` : '';
    filters += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/users/${ sociateId }/opportunities?${ filters }`)
      .pipe(
        map((response: IOpportunitiesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve Opportunities data.'))
      );
  }

  public getBookmarks(recordCount?: number, pageToken?: number): Observable<ISociatesResponse> {

    let filters = ``;
    filters += pageToken ? `p=${ pageToken }&` : '';
    filters += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/bookmarks?${ filters }`)
      .pipe(
        map((response: ISociatesResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to bookmark sociate.'))
      );

  }

  public setBookmark(sociateId: string, bookmark: boolean): Observable<IResponse> {

    return this.http
      .put(`${ this.configService.getApiUrl() }/users/${ sociateId }/bookmark`, { bookmark: bookmark })
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to bookmark sociate.'))
      );

  }

    /**
  * Method to disable a list of users
  *
  * @returns null
  */
    public approveUsers(data: string[]): Observable<any> {

      return this.http
        .post(`${ this.configService.getApiUrl() }/users/approveSelected`, data)
        .pipe(
          map((response: boolean) => response),
          catchError((error: any) => observableThrowError(error || 'Failed to approve the user.'))
        );
    }


  /**
  * Method to disable a user
  *
  * @returns null
  */
  public toggleStatus(userId: string): Observable<any> {

    return this.http
      .put(`${ this.configService.getApiUrl() }/users/${ userId }/toggle`, null)
      .pipe(
        map((response: boolean) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to disable the user.'))
      );
  }

  /**
  * Method to disable a list of users
  *
  * @returns null
  */
  public toggleUsers(data: string[]): Observable<any> {

    return this.http
      .post(`${ this.configService.getApiUrl() }/users/toggleSelected`, data)
      .pipe(
        map((response: boolean) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to disable the user.'))
      );
  }

  /**
  * Method to delete a user
  *
  * @returns null
  */
  public delete(userId: string): Observable<any> {

    return this.http
      .delete(`${ this.configService.getApiUrl() }/users/${ userId }`)
      .pipe(
        map((response: boolean) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to delete the user.'))
      );
  }

  /**
  * Method to delete a list of users
  *
  * @returns null
  */
  public deleteUsers(data: string[]): Observable<any> {

    return this.http
      .post(`${ this.configService.getApiUrl() }/users/deleteSelected`, data)
      .pipe(
        map((response: boolean) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to delete the user.'))
      );
  }

  /**
  * Method to map a school to a list of users
  *
  * @returns null
  */
  public mapSchools(data: any): Observable<any> {

    return this.http
      .post(`${ this.configService.getApiUrl() }/users/mapschools`, data)
      .pipe(
        map((response: boolean) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to map the user.'))
      );
  }

}
