
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConfigService } from '../utility/config.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { StorageService } from '../functional/storage.service';
import { IResponse, IInvitation } from '../../../interfaces';
import { IInvitationResponse } from '../interfaces';

@Injectable()
export class InvitationService {

  /**
  * Constructor of the class.
  *
  * @param {Http}            http
  * @param {ConfigService}   configService
  */
  public constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private localStorage: StorageService,
  ) { }

  public getAll(type?: string, pageToken?: number, recordCount?: number): Observable<IInvitationResponse> {

    let filters = '';

    filters += type ? `t=${ type }&` : '';
    filters += pageToken ? `p=${ pageToken }&` : '';
    filters += recordCount ? `c=${ recordCount }&` : '';

    return this.http
      .get(`${ this.configService.getApiUrl() }/invitations?${ filters }`)
      .pipe(
        map((res: IInvitationResponse) => res),
        catchError((error: any) => observableThrowError(error || 'Failed to retrieve data.'))
      );
  }

  public delete(data: Array<string>): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/invitations/deleteSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to save invitationdata.'))
      );
  }

  public approve(data: any): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/invitations/approveSelected`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to save invitationdata.'))
      );
  }

  public save(data): Observable<IResponse> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/invitations`, data)
      .pipe(
        map((response: IResponse) => response),
        catchError((error: any) => observableThrowError(error || 'Failed to save invitationdata.'))
      );
  }

  public validateToken(data): Observable<any> {
    return this.http
      .post(`${ this.configService.getApiUrl() }/invitations/validateToken`, data)
      .pipe(
        map((res: any) => { return res }),
        catchError((error: any) => observableThrowError(error || 'Failed to validate token.')),
      )
  }

}
