<div *ngIf="!close" class="container {{size}}" (click)="openWindow()" fxLayout="{{size=='large'?'column':'row'}}" fxFill>
  <div *ngIf="!hideImage && data.image" class="thumb" fxFlex="{{size=='large'?'75':'22'}}">
    <img class="image" src="{{data.image}}">
  </div>
  <div class="attributes">
    <div *ngIf="!hideTitle && data.title" class="title" contenteditable="false">{{ data.title.length < 75? data.title : data.title.substring(0,75)+'...' }}</div>
    <div *ngIf="!hideDescription && data.description" class="description" contenteditable="false">{{ data.description }}</div>
    <div *ngIf="!hidePublisher && (data.publisher || data.date)" class="publish-container">
      <span *ngIf="data.publisher" class="publisher">{{ data.publisher.length > 25? data.publisher.substring(0,25)+'...' : data.publisher }} - </span>
      <span class="date">{{ data.date * 1000 | date:'mediumDate' }}</span>
    </div>
  </div>
  <div *ngIf="showActions" class="action">
    <span class="close app-clickable" (click)="close = !close">✕</span>
  </div>
</div>
